.gPScrlWrp.wCBasic {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}
.gPScrlWrp.wCBasic > .gPWrp {
  flex: 1;
  overflow-y: scroll;
}
.gPScrlWrp.wCBasic > .gPWrp::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #ffffff;
}
.gPScrlWrp.wCBasic > .gPWrp::-webkit-scrollbar
{
  height: 6px;
	width: 5px;
	background-color: #ffffff;
}
.gPScrlWrp.wCBasic > .gPWrp::-webkit-scrollbar-thumb
{
  o1utline: 1px solid slategrey;
	border-radius: 10px;
	background-color: #BDBEC1;
}


.themeSelector {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}
.themeSelector > .themeOption {
  padding: 0px;
  margin-right: 40px;
  border-radius: 20px;
  border: 1px solid;
  margin-right: 20px;
  padding: 7px;
}
.themeSelector > .themeOption.selected {
    border-radius: 20px;
    border: 1px solid;
    margin-right: 20px;
    padding: 7px;
}
.themeSelector > .themeOption > .inner {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
.themeSelector > .themeOption > .inner.theme_1 {
  background-color: #e15700;
}
.themeSelector > .themeOption > .inner.theme_2 {
  background-color: #003ae1;
}
.themeSelector > .themeOption > .inner.theme_3 {
  background-color: #eb0c73;
}
.themeSelector > .themeOption > .inner.theme_4 {
  background-color: #262f3b;
}
.themeSelector > .themeOption > .inner.theme_5 {
  background-color: #CF6A7D;
}

.gBCtRw.astntNm > .lt,
.gBCtRw.prmptMsg > .lt,
.gBCtRw.wlcmMsg > .lt,
.gBCtRw.lnchrPos > .lt   {
  width: 100%;
}
.gPScrlWrp.wCBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .maxLen {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: var(--font-size-sub-primary);
}
.gPScrlWrp.wCBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .gIpt,
.gPScrlWrp.wCBasic > .gPWrp > .gBCtnt > .gBCtRw > .rt > .gIpt {
  margin-top: 4px;
}
.gBCtRw.lnchrPos.title {
  margin-bottom: 0!important;
}
.gBCtRw.lnchrPos > .lt > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gBCtRw.lnchrPos > .lt > .title > .reset {
  cursor: default;
}
.gBCtRw.lnchrPos > .lt > .selctOpts {
  width: 50%;
  margin-top: 16px;
}
.gBCtRw.lnchrPos.values {
  gap: 10px;
}
.gBCtRw.lnchrPos.values > .lt,
.gBCtRw.lnchrPos.values > .rt {
  flex: 1;
}
.gBCtRw.lnchrPos.values > * > .gIpt > input {
  width: 80px;
  margin-right: 5px;
}


/* Checkbox */

.gBCtRw > .lt > .chkBxRw {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.gBCtRw > .lt > .chkBxRw.nested {
  margin-left: 26px;
}
.gBCtRw > .lt > .chkBxRw > input.checkbox {
  margin-right: 10px;
  font-size: 20px;
  height: 18px;
  width: 18px;
}
.gBCtRw > .lt > .chkBxRw > .gLabel {
  color: var(--primary-text-color-light);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.gBCtRw > .lt > .chkBxRw > .gLabel > .fa-info-circle {
  margin-left: 5px;
}
.gBCtRw > .lt > .chkBxRw > .gLabel > .gIpt > .inputValue {
  width: 50px;
  font-size: 14px;
  border: var(--gt-input-border);
  border-radius: var(--generic-border-radius);
  padding: 5px 2px 6px 6px;
  margin-bottom: 1px;
  color: var(--primary-text-color-light);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-left: 5px;
  margin-top: -5px;
}
.gBCtRw > .lt > .chkBxRw.selected > .gLabel > input.inputValue {
  color: var(--primary-text-color);
}
.gBCtRw.dsplyPref > .lt > .desc {
  margin-bottom: 16px;
}
.gBCtRw.dsplyPref.hasTrgr {
  margin-bottom: 0!important;
  padding-bottom: 0!important;
}
.gBCtRw.dsplyPref.trgrVals {
  padding-top: 0!important;
  padding-left: 48px!important;
}


/* Webchat Help Guide */
.gPScrlWrp.helpGuide {
  background-color: #FFFFFF;
  overflow-y: hidden;
}
.gPScrlWrp.helpGuide > .gPWrp {
  max-width: 100%;
  padding: 0;
  height: 100%;
}
.gPScrlWrp.helpGuide > .gPWrp > .gPTle {
  padding: 20px;
  height: 59px;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt {
  flex-direction: row;
  border-top: 1px solid var(--generic-border-color);
  height: calc(100% - 59px);
}

.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox {
  width: 200px;
  border-right: 1px solid #e6e6e7;
  overflow-y: scroll;
  height: 100%;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox {
  flex: 1;
  overflow-y: scroll;
  height: 100%;
}


.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .header {
  font-size: 14px;
  padding: 0 10px;
  height: 48px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e7;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .header > span {
  cursor: default;
  padding: 3px;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .header > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .list {
  margin-top: 0px;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .add {
  margin-top: 20px;
  padding: 10px;
  margin-bottom: 20px;
}

.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .list > .entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 10px;
  cursor: pointer;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .list > .entry.selected {
  background-color: #f6f7fb;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .list > .entry.hidden {
  opacity: 0.5;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .list > .entry:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .list > .entry > .left {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .list > .entry > .right {
  width: 40px;
  text-align: right;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .list > .entry > .left > img {
  height: 16px;
  margin-right: 5px;
}

.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .add > p {
  font-size: 14px;
  color: var(--gte-primary-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .add > p > span {
  padding: 4px;
  cursor: default;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .topicsBox > .add > p > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
  border-radius: var(--generic-border-radius);
}


.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox.chooseTopic > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .header {
  font-size: 14px;
  padding: 0 10px;
  height: 48px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e7;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .list {
  margin-top: 10px;
}

.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .list > .addQuestion {
  margin: 10px 0 0px 0;
  padding: 10px 10px 24px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #e6e6e7;
  font-size: 14px;
  color: var(--gte-primary-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .list > .addQuestion > span {
  cursor: default;
  padding: 4px 6px;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .list > .addQuestion > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
  border-radius: var(--generic-border-radius);
}

.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .header > .left {
  display: flex;
  flex-direction: column;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .header > .left > .title {
  font-size: 14px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .header > .left > .info {
  font-size: 14px;
  color: var(--primary-text-color-light);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 5px;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .header > .right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPScrlWrp.helpGuide > .gPWrp > .gBCtnt > .queriesBox > .header > .right > span {
  margin-right: 10px;
  margin-top: 5px;
}

/* Channels */
.gPTle.allChnls {
  justify-content: flex-start;
  gap: 10px;
}
.gPTle.allChnls > a {
  text-decoration: none;
}


/* Deploy */
.gBCtRw.dplyScrpt > .lt {
  width: 100%;
}
.gBCtRw.dplyScrpt > .lt > .copyScript {
  margin: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: var(--font-size-sub-primary);
}
.gBCtRw.dplyScrpt > .lt > .copyScript > .inner {
  cursor: default;
  padding: 3px 6px;
}
.gBCtRw.dplyScrpt > .lt > .copyScript > .inner:hover {
  background-color: var(--sidebar-main-menu-active-bg);
  border-radius: var(--generic-border-radius);
}
.gBCtRw.dplyScrpt > .lt > .copyScript > .inner > img {
  margin-right: 10px;
}


/* General dnd */

.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox {
  padding: 20px;
  border: 1px dashed #d3d3d6;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}
.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .profileImgBox {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .profileImgBox > img {
  height: 60px;
  min-width: 40px;
  border-radius: 40px;
  padding: 10px 11px;
  background-color: #f8f8f9;
}
.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info {
  flex: 1;
}
.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > p {
  font-size: 14px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > div.top {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > div.top > .gButton {
  margin-left: 10px;
}
.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > div.top > .gButton > span {
  color: var(--gte-primary-color);
}

.gPScrlWrp.wCGen > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > p.supportInfo {
  color: var(--primary-text-color-light);
  font-weight: 400;
}
