.sdMdl.mngGrpMdl > .ctnt {
  padding: 0;
}

.manageGroupModal > .content {
  overflow-y: hidden!important;
  max-width: 800px!important;
}

.manageGroupModal > .content > .manageGroup {
  box-shadow: none;
  padding: 0;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
  position: relative;
}

.manageGroup > .innerBox {
  display: flex;
  flex-direction: column;
}

.manageGroup > .innerBox > .headerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 20px;
  height: 72px;
  border-bottom: solid 1px rgba(79, 89, 105, 0.2);
}
.manageGroup.shareGroup > .innerBox > .headerBox > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 3;
  font-size: 20px;
  font-weight: 600;
}
.manageGroup > .innerBox > .headerBox > .right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.manageGroup > .innerBox > .headerBox > .right > span {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.manageGroup > .innerBox > .headerBox > .right > span > img {
  margin-right: 5px;
}
.manageGroup > .innerBox > .headerBox > .right > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.manageGroup > .innerBox > .headerBox > .right > span {
  cursor: default;
}

.manageGroup > .innerBox > .bodyBox {
  padding: 20px;
  overflow-y: scroll;
}
.manageGroup > .innerBox > .bodyBox::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.manageGroup > .innerBox > .bodyBox::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.manageGroup > .innerBox > .bodyBox::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.manageGroup > .innerBox > .bodyBox > .searchBox {

}
.manageGroup > .innerBox > .bodyBox > .searchBox > .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}
.manageGroup > .innerBox > .bodyBox > .searchBox > .top > span.title {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
.manageGroup > .innerBox > .bodyBox > .searchBox > .top > .actions {
  display: flex;
  flex-direction: row;
}
.manageGroup > .innerBox > .bodyBox > .searchBox > .top > .actions > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.manageGroup > .innerBox > .bodyBox > .searchBox > .top > .actions > span.add {
  color: var(--gte-primary-color);
}
.manageGroup > .innerBox > .bodyBox > .searchBox > .top > .actions > span.delete {
  color: var(--generic-error-color);
  margin-left: 20px;
}
.manageGroup > .innerBox > .bodyBox > .searchBox > .inputBox {
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(79, 89, 105, 0.2);
  background-color: rgba(242, 242, 245, 0.4);
  padding: 10px;
}
.manageGroup > .innerBox > .bodyBox > .searchBox > .inputBox > img {
  height: 20px;
  margin-right: 5px;
}
.manageGroup > .innerBox > .bodyBox > .searchBox > .inputBox > input {
  width: calc(100% - 26px);
  background-color: rgb(250 250 251);
}

.manageGroup > .innerBox > .bodyBox > .selAllBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
.manageGroup > .innerBox > .bodyBox > .selAllBox > .gLbl {
  margin-bottom: 0;
}

.manageGroup > .innerBox > .bodyBox > .usersListBox {
  padding: 10px 0;
}
.manageGroup.shareGroup > .innerBox > .bodyBox > .usersListBox > .lRow {
  padding: 16px 12px;
  border-bottom: 1px solid #e6e6e7;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.manageGroup > .innerBox > .bodyBox > .usersListBox > .lRow:hover {
  background-color: #EEE;
}

.manageGroup > .innerBox > .bodyBox > .usersListBox > .lRow > .checkBox {
  width: 50px;
}
.manageGroup > .innerBox > .bodyBox > .usersListBox > .lRow > .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.manageGroup > .innerBox > .bodyBox > .usersListBox > .lRow > .left > img {
  width: 100%;
}
.manageGroup > .innerBox > .bodyBox > .usersListBox > .lRow > .right > .name {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0 0 5px 0;
}
.manageGroup > .innerBox > .bodyBox > .usersListBox > .lRow > .right > .address {
  font-size: var(--font-size-primary);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0 0 5px 0;
}
.manageGroup > .innerBox > .bodyBox > .usersListBox > .lRow > .right > .alreadyInList {
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-primary);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.manageGroup > .innerBox > .bodyBox > .usersListBox > .lRow > .right > .alreadyInList > span {
  margin-left: 10px;
  font-size: var(--font-size-sub-primary);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  padding: 3px 4px;
  background-color: #0d43aa1A;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 120px;
  border-radius: 3px;
}

.manageGroup > .innerBox > .bottomBox {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}
.manageGroup > .innerBox > .bottomBox > .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.manageGroup > .innerBox > .bottomBox > .left > span {
  color: var(--generic-error-color);
  font-weight: bold;
}
.manageGroup > .innerBox > .bottomBox > .right > .cta {
  padding: 8px 32px;
  border-radius: 5px;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
  cursor: pointer;
}
.manageGroup > .innerBox > .bottomBox > .right > .cta > .inProgress {
  margin-left: 10px;
}
.manageGroup > .innerBox > .bottomBox > .right > .cta.cancel {
  margin-right: 20px;
  background-color: var(--primary-text-color-light);
}
.manageGroup > .innerBox > .bottomBox > .right > .cta.inactive {
  background-color: var(--primary-text-color-light);
  cursor: not-allowed;
}
