.rte-bx {
  background-color: var(--generic-bg-color);
  background-color: #FFFFFF;
  padding: 20px 20px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
}
.rte-bx > .lt {
  flex: 1;
}
.rte-bx > .rt {
  margin-left: 20px;
}
.rte-bx > .delCtrl {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.rte-edit-area {
  border: var(--gt-input-border);
  padding: 10px;
  margin-bottom: 10px;
}

.rte-controls {
  margin-bottom: 10px;
}
.rte-controls > button {
  margin-right: 10px;
}

.rte-controls-link {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.rte-controls-link > .ipt {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.rte-controls-link > .ipt > input {
  color: var(--primary-text-color);
  border: var(--gt-input-border);
  border-radius: var(--gt-form-input-border-radius);
  width: 300px;
}
