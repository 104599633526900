.addStfBx {
  margin-left: 5px;
}
.addStfBx > span > i {
  color: var(--gte-primary-color);
}
.nwMsg {
  color: var(--gte-primary-color);
  cursor: default;
}
.noGst {
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
