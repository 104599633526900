.mb > .ssb {
  p1osition: fixed;
  z-index: 50;
  transition: all 100ms linear;
  width: 220px;
  height: 100%;
  background-color: var(--sidebar-main-menu-bg);
  border-right: 1px solid var(--generic-border-color);
}
.ssb > .fxd.top {
  height: 60px;
  margin-left: 10px;
  border-bottom: 1px solid var(--generic-border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ssb.sbs > .fxd.top {
  height: 80px;
}
.ssb > .fxd.top > .prdRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 40px;
  font-size: var(--font-size-top-bar);
  text-transform: capitalize;
}

.ssb > .mn {
  height: calc(100% - 61px - 50px);
  overflow-y: scroll;
}
.ssb.sbs > .mn {
  height: calc(100% - 81px - 50px);
}
.ssb > .mn::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #ffffff;
}
.ssb > .mn::-webkit-scrollbar
{
  height: 6px;
	width: 5px;
	background-color: #ffffff;
}
.ssb > .mn::-webkit-scrollbar-thumb
{
  o1utline: 1px solid slategrey;
	border-radius: 10px;
	background-color: #BDBEC1;
}

.ssb > .mn > ul.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #e6e6e7;
}
.ssb > .mn > .menu > li {
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color-light);
}
.ssb > .mn > .menu > li.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
.ssb > .mn > .menu > li.sh {
  padding: 30px 10px 10px 10px;
}
.ssb > .mn > .menu > li.sh > p {
  font-size: var(--font-size-top-bar);
  text-transform: capitalize;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  opacity: 0.7;
}
.ssb > .mn > .menu > li.ss {
  b1order-bottom: 1px solid #e6e6e7;
}
.ssb > .mn > .menu > li > a {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: var(--sidebar-text-color);
}
.ssb > .mn > .menu > li.active > a {
  color: var(--gte-primary-color);
}
.ssb > .mn > .menu > li > a > img {
  margin: 10px;
  height: 18px;
}
.ssb > .mn > .menu > li.sh > a {
  font-size: var(--font-size-top-bar);
  text-transform: capitalize;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  opacity: 0.7;
  text-decoration: none;
  padding: 0px;
}
.ssb > .mn > .menu > li:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
