.repeatVisitsWrap > .gPWrp > .gPTle.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.repeatVisitsWrap > .gPWrp > .gPTle.title > .lt {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}

.repeatVisitsWrap > .innerBox {
  display: flex;
  flex-direction: column;
}
.repeatVisitsWrap > .innerBox > .closeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  1border-bottom: 1px solid #e6e6e7;
}
.repeatVisitsWrap > .innerBox > .closeRow > .close {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.repeatVisitsWrap > .innerBox > .closeRow > .close > img {
  margin-right: 10px;
}

.repeatVisitsWrap > .innerBox > .listBox > .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}
.repeatVisitsWrap > .innerBox > .listBox > .title > span {
  color: var(--primary-text-color-light);
}
.repeatVisitsWrap > .innerBox > .listBox > .title > i {
  margin-left: 10px;
}
