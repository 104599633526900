.ansRatChrt {
  padding: 10px;
  margin-bottom: 20px;
  flex: 1;
  align-self: flex-end;
}
.ansRatChrt > .qns {
  margin-bottom: 10px;
}
.ansRatChrt > .labelsWrap {
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.ansRatChrt > .labelsWrap > .itemBoxWrap {
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
  background-color: #f7f8fb;
  height: 40px;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ansRatChrt > .labelsWrap > .itemBoxWrap > .dot {
  width: 10px;
  height: 10px;
  margin: 0px 10px 0 0;
  border-radius: 10px;
  background-color: #FFFFFF;
}
.ansRatChrt > .labelsWrap > .itemBoxWrap > .ota {
  width: 100px;
}
.ansRatChrt > .labelsWrap > .itemBoxWrap > .count {
  width: 40px;
  font-size: 16px;
}
.ansRatChrt > .labelsWrap > .itemBoxWrap > .percent {
  width: 60px;
  font-size: 20px;
}
