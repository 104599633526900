.gPg > .gPageWrapOuterTitle > span.add {
  margin-left: 5px;
}

.gPageWrap.allBroadcasts {
  max-width: 100%;
  background-color: transparent;
}
.gPageWrap.allBroadcasts > .gPageTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  1padding: 0 20px 38px 20px;
}
.gPageWrap.allBroadcasts > .gPageTitle > span.add {
  margin-left: 10px;
  cursor: pointer;
}
.gPageWrap.allBroadcasts > .gPageTitle > span > .fa {
  font-size: 18px;
}

.gPageWrap.allBroadcasts > .allHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.gPageWrap.allBroadcasts > .allHeader > .display > span {
  margin-right: 10px;
  cursor: pointer;
}
.gPageWrap.allBroadcasts > .allHeader > .display > span > i {
  font-size: 20px;
  color: #a59e9e;
}
.gPageWrap.allBroadcasts > .allHeader > .display > span.active > i {
  color: var(--gte-primary-color);
}

.gPageWrap.allBroadcasts > .allWrap.grid {
  1padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  1justify-content: space-between;
  1margin: -20px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.gPageWrap.allBroadcasts > .allWrap.list {
  1padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  border: solid 1px #e6e6e7;
  margin-bottom: 20px;
  width: calc(100% - 40px);
  1margin: 10px;
  1margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  1box-shadow: inset 0 0 2px #666;
}
.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  1margin-bottom: 20px;
  1width: 200px;
  1width: calc(25%);
  1margin: 10px;
  1margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  1box-shadow: inset 0 0 2px #333;
  1flex: 0 32%;
  width: calc(32% - 5px);
  box-sizing: border-box;
}
@media (min-width: 768px) {
  .gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox {
    width: calc(49% - 10px);
  }
}
@media (min-width: 1024px) {
  .gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox {
    width: calc(32% - 5px);
  }
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox:hover {
  1box-shadow: inset 0 0 3px #000;
}

.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .typeBox {
  width: 130px;
  padding-right: 10px;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .whenBox {
  width: 160px;
}

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .typeBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .typeBox > .type > span {
  background-color: #ecf0f8;
  padding: 5px 12px;
  font-weight: var(--font-weight-bold);
  border-radius: var(--generic-border-radius);
  color: var(--gte-primary-color);
  font-size: 14px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .typeBox > .type > span > img {
  margin-right: 2px;
}


.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .whenBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .whenBox > .title {
  width: 100px;
  font-size: var(--font-size-primary);
  f1ont-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .whenBox > .when {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .whenBox > .when {
  margin-left: 0px;
  margin-right: 20px;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .whenBox > .when {
  margin-top: 5px;
  align-items: flex-start;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .whenBox > .when > .day,
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .whenBox > .when > .time {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .whenBox > .when > .day {
  margin-bottom: 3px;
}


.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 200px;
  margin-right: 20px;
}
@media (max-width: 1024px) {
  .gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .top {
    width: 120px;
  }
}
.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .top > .type > span {
  background-color: #ecf0f8;
  padding: 5px 12px;
  font-weight: var(--font-weight-bold);
  border-radius: var(--generic-border-radius);
  color: var(--gte-primary-color);
  font-size: 14px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .top > .type > span > img {
  margin-right: 2px;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .top > .when {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .top > .when {
  margin-top: 10px;
  align-items: flex-start;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .top > .when > .day,
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .top > .when > .time {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .top > .when > .day {
  margin-bottom: 3px;
}

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content {
  width: 100%;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content {
  f1lex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 290px);
}

.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .nameBox {
  width: 100%;
  1flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 1366px) {
  .gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .nameBox > .name {
  1flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  1max-width: 40%;
  width: 30%;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .name {
  width: 100%;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .nameBox > .name {
    width: 49%;
  }
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .nameBox > .right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 160px;
  1flex: 1;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right {
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 1366px) {
  .gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right {
    justify-content: flex-end;
    width: 160px;
  }
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .nameBox > .right > .gToggleWrap {
  display: flex;
  flex-direction: row;
  align-items: center;

}
.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content > .nameBox > .right > .gToggleWrap {
  justify-content: flex-end;
  margin-right: 0px;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right > .gToggleWrap {
  justify-content: flex-end;
  margin-right: 20px;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right > .gToggleWrap > .gToggleBig {
  margin-top: -19px;
}

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content > .lineBreak {
  width: 100%;
  height: 1px;
  background-color: var(--gt-form-line-sep-color);
  margin: 20px 0;
}

.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .targetBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .targetBox {
  margin-bottom: 5px;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .targetBox > .target {
  display: flex;
  flex-direction: column;
  f1ont-size: 12px;
  f1ont-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  1text-transform: uppercase;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .targetBox > .target > p.daysAhead {
  color: var(--primary-text-color-light);
}

.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .targetBox > .cMenu,
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .nameBox > .right > .cMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content .cMenu > .edit,
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content .cMenu > .delete {
  cursor: default;
  padding: 3px 4px;
  border-radius: var(--generic-border-radius);
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content .cMenu > .edit:hover,
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content .cMenu > .delete:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content .cMenu > .delete {
  margin-right: 0;
}

.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .message {
  1width: auto;
  margin-bottom: 5px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  1width: calc(100% - 180px);
  1max-width: 100%;
  width: 100%;
  color: var(--primary-text-color-light);
}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .message {
  1width: calc(100% - 180px);
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .message > .far,
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .when > .far {
  margin-right: 10px;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .nextRun {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 10px;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .nextRun > span {
  font-weight: normal;
}

.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .oMenu {
  flex: 1;
  display: flex;
  justify-content: center;
}

/* ************** dropdown menu ********************** */

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content > .targetBox > .cMenu > .oMenu > .dropdown > .dropbtn,
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right > .cMenu > .oMenu > .dropdown > .dropbtn {
  background-color: var(--message-sent-bg-color);
}

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content > .targetBox > .cMenu > .oMenu > .dropdown,
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right > .cMenu > .oMenu > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: -3px;
}

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content > .targetBox > .cMenu > .oMenu > .dropdown > .dropdown-content,
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right > .cMenu > .oMenu > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  -webkit-backdrop-filter: blur(19.6px);
  backdrop-filter: blur(19.6px);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.gPageWrap.allBroadcasts > .allWrap > .broadcastBox > .content > .targetBox > .cMenu > .oMenu > .dropdown > .dropdown-content {
  left: -140px;
  top: 25px;
}

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content > .targetBox > .cMenu > .oMenu > .dropdown > .dropdown-content p,
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right > .cMenu > .oMenu > .dropdown > .dropdown-content p {
  color: var(--primary-text-color);
  padding: 14px 20px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid var(--generic-border-color);
}

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content > .targetBox > .cMenu > .oMenu > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right > .cMenu > .oMenu > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.gPageWrap.allBroadcasts > .allWrap.grid > .broadcastBox > .content > .targetBox > .cMenu > .oMenu > .dropdown .dropdown-content.open {display: block;}
.gPageWrap.allBroadcasts > .allWrap.list > .broadcastBox > .content > .nameBox > .right > .cMenu > .oMenu > .dropdown .dropdown-content.open {display: block;}


/* ************************************ */

.gToggleWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gToggleWrap > span {
  margin-right: 10px;
}
