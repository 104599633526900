.gBCtRw.tglAndData.withValue {
  margin-bottom: 0px!important;
}
.gBCtRw.tglAndDataValue {
  margin-bottom: 0px!important;
}
.gBCtRw.tglAndDataValue > .lt {
  flex: 1;
}
.gBCtRw.tglAndDataValue > .lt > .title {
  margin-bottom: 10px!important;
}
