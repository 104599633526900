.cModal.redirectToOtaModal {
  z-index: 9999;
}
.selfResponseReviewModal > .content {
  max-width: 90%!important;
  width: 1100px!important;
  max-height: 95%!important;
}
.rtoBoxWrap {
  overflow-y: hidden;
  padding: 0!important;
}

.rtoBoxWrap > .innerBox {
  1max-height: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.rtoBoxWrap > .innerBox {
  margin-bottom: 20px;
}
.rtoBoxWrap > .innerBox > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
.rtoBoxWrap > .innerBox > .header > .title {
  font-size: var(--font-size-large);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rtoBoxWrap > .innerBox > .header > .title > i {
  font-size: 24px;
  margin-right: 10px;
  color: var(--gte-primary-color);
}
.rtoBoxWrap > .innerBox > .header > .close {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  cursor: default;
}
.rtoBoxWrap > .innerBox > .header > .close:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.rtoBoxWrap > .innerBox > .header > .close > img {
  margin-right: 10px;
}

.rtoBoxWrap > .innerBox > .header > .right {
  width: 160px;
  text-align: right;
}

.rtoBoxWrap > .innerBox > .body {
  margin-bottom: 20px;
}
.rtoBoxWrap > .innerBox > .body > .infoMsg {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}
.rtoBoxWrap > .innerBox > .body > .note {
  margin-bottom: 20px;
  background-color: var(--note-bg-color);
  padding: 12px;
  border-radius: var(--generic-border-radius);
}
.rtoBoxWrap > .innerBox > .body > .note > p {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--note-text-color);
}


.rtoBoxWrap > .innerBox > .submitBox {
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  padding-bottom: 0px;
}
.rtoBoxWrap > .innerBox > .submitBox > .buttonWrap {
  margin-left: 0px;
  display: flex;
  flex-direction: row;
}
.rtoBoxWrap > .innerBox > .submitBox > .buttonWrap > .gBtn:first-child {
  margin-right: 10px;
}
