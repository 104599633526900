.gPWrp.allLocsPg > .gBCtnt > a > .lRow {
  padding: 16px 12px;
  border-bottom: 1px solid #e6e6e7;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.gPWrp.allLocsPg > .gBCtnt > a > .lRow:hover {
  background-color: #EEE;
}

.gPWrp.allLocsPg > .gBCtnt > a > .lRow > .left {
  width: 200px;
}
.gPWrp.allLocsPg > .gBCtnt > a > .lRow > .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.gPWrp.allLocsPg > .gBCtnt > a > .lRow > .left > img {
  width: 100%;
}
.gPWrp.allLocsPg > .gBCtnt > a > .lRow > .right > .name {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0 0 5px 0;
}
.gPWrp.allLocsPg > .gBCtnt > a > .lRow > .right > .address {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}


.gPWrp.allLocsPg > .gBCtnt > .gBCtRw > .gRwBxLt > .gIpt {
  position: relative;
}
.gPWrp.allLocsPg > .gBCtnt > .gBCtRw > .gRwBxLt > .gIpt > img.clear {
  right: 7px;
  position: absolute;
  top: 11px;
}
