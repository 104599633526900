.gPg.revsPg > .dtFltBx > .dateFilter {
  width: auto;
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  padding: 0 10px;
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv:hover {
  background-color: var(--generic-hover-bg-color);
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv > img {
  height: 20px;
  margin-right: 5px;
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv > p {
  margin-bottom: 0;
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv > span {
  margin-left: 10px;
}
.gPg.revsPg > .rpOptions {
  margin-top: 20px;
  1height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
}
.gPg.revsPg > .rpOptions > .rpTabs {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.gPg.revsPg > .rpOptions > .rpTabs > .reviewTabButton {
  font-size: var(--font-size-primary);
  padding: 6px 12px;
  color: var(--primary-text-color-light);
  margin-right: 20px;
  margin-bottom: 0px;
  cursor: pointer;
}
.gPg.revsPg > .rpOptions > .rpTabs > .reviewTabButton.active {
  border-radius: var(--generic-border-radius);
  color: var(--gte-primary-color);
  background-color: #ecf0f8;
}
.gPg.revsPg > .rpOptions > .rpSort {
  width: 200px;
}




.gPg.revsPg > .dtFltBx {
  margin-top: 0;
}
.gPg.revsPg > .dtFltBx > .left > .title > a > span {
  margin-right: 10px;
}



.gPg.trendPg > .dtFltBx > .left > .title {
  font-size: 16px;;
}


.trendAnalysis {
  margin-bottom: 60px;
}
.trendAnalysis.curRtngs {
  margin-bottom: 60px;
}

.trendAnalysis > .title {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.trendAnalysis > .title > .right.byPrd {
  display: flex;
  gap: 10px;
}
.trendAnalysis > .title > .right.byPrd > .byPrd {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 4px 8px;
  border-radius: var(--generic-border-radius);
  font-size: 14px;
}
.trendAnalysis > .title > .right.byPrd > .byPrd.active {
  background-color: var(--sidebar-main-menu-active-bg);
  font-weight: bold;
}
.trendAnalysis > .compTbl {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2px;
}
.trendAnalysis > .compTbl > .trndTblCol {
  display: flex;
  flex-direction: column;
  /* min-width: 140px;
  max-width: 140px; */
  flex: 1;
}
.trendAnalysis > .compTbl > .trndTblCol.lgnd {
  min-width: 180px;
  max-width: 180px;
}
.trendAnalysis > .compTbl > .trndTblCol > div {
  padding: 10px;
  margin: 2px;
  text-align: left;
  word-break: break-all;
  box-shadow: var(--generic-card-shadow);
}
.trendAnalysis > .compTbl > .trndTblCol > div {
  height: 80px;
}
.trendAnalysis > .compTbl > .trndTblCol > div.locName {
  max-height: 80px;
  min-height: 80px;
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  /* display: block; */
  overflow: hidden;
}
.trendAnalysis > .compTbl > .trndTblCol.prmy > div {
  color: var(--gte-primary-color);
  font-weight: var(--font-weight-bold);
}
.trendAnalysis > .compTbl > .trndTblCol.lgnd > div.yLbl {
  font-size: 12px;
}
.trendAnalysis > .compTbl > .trndTblCol.lgnd > div.yLbl > .rating > .stars > img {
  height: 20px;
}

.trendAnalysis > .compTbl > .trndTblCol > div.grtst {
  background-color: #0EF6A3;
}
.trendAnalysis > .compTbl > .trndTblCol > div.ota {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.trendAnalysis > .compTbl > .trndTblCol > div.ota > img {
  height: 20px;
}

.compReviewStars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: 18px;
  font-family: Times;
  line-height: 1;
}
.compReviewStars:before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


  .gPg.revsPg > .prntTtlBx {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .gPg.revsPg > .prntTtlBx > .title {
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--primary-text-color);
  }
  .gPg.revsPg > .prntTtlBx > .rptDts {
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--primary-text-color);
  }


  @media print {
    .trendAnalysis > .compTbl > .trndTblCol.lgnd {
      min-width: 240px;
      max-width: 240px;
    }
    .trendAnalysis > .compTbl > .trndTblCol > div {
      box-shadow: none;
      border: 1px solid grey;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .trendAnalysis > .compTbl > .trndTblCol > div.ota > img {
      height: 32px;
    }
  }