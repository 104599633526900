.mb.rg > .mc > .gPScrlWrp {
  background-color: var(--generic-bg-color);
}
.mb.rg > .mc.hasNtce > .gPScrlWrp {
  height: calc(100% - 40px);
}

.gPg.rgGnrt > .dtFltBx {
  margin-top: 0;
  margin-bottom: 20px;
}
.gPg.rgGnrt > .dtFltBx > .left > .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.gPg.rgGnrt > .rgBx > .lt > .strSel {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .otaSelect {
  margin-top: -20px;
  min-width: 200px;
}
.gPg.rgGnrt > .rgBx.hasResp > .lt > .strSel > .otaSelect {
  min-width: auto;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars {
  display: flex;
  justify-content: center;
  transform: scale(0.4);
  margin-top: -40px;
  margin-bottom: -10px;
  margin-left: -50px;
  max-width: 300px;
}
@media (max-width: 767px) {
  .gPg.rgGnrt > .rgBx > .lt > .strSel > .stars {
    transform: scale(0.4);
    max-width: 300px;
  }
}
.gPg.rgGnrt > .rgBx.hasResp > .lt > .strSel > .stars {
  transform: scale(0.3);
  margin-left: -75px;
}

.gPg.rgGnrt > .rgBx {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.gPg.rgGnrt > .rgBx > .lt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  gap: 30px;
  background-color: #FFFFFF;
  padding: 20px;
}
.gPg.rgGnrt > .rgBx.hasResp > .lt {
  width: 40%;
}
.gPg.rgGnrt > .rgBx > .rt {
  width: 50%;
  background-color: #FFFFFF;
  padding: 20px;
}
.gPg.rgGnrt > .rgBx.hasResp > .rt {
  width: 60%;
}

.gPg.rgGnrt > .rgBx > .lt > .locSel {
  width: 300px;
}
.gPg.rgGnrt > .rgBx > .lt > .locSel > .gLbl > i {
  margin-left: 5px;
  color: grey;
}
.gPg.rgGnrt > .rgBx > .lt > .locSel > .gErr {
  margin-top: 5px;
}

.gPg.rgGnrt > .rgBx > .lt > .locSel > .lSel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.gPg.rgGnrt > .rgBx > .lt > .locSel > .lSel > .locPrfSelect {
  min-width: 300px;
}
.gPg.rgGnrt > .rgBx > .lt > .locSel > .lSel > a {
  cursor: default;
}

.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star {
  flex: 25%;
  margin: 50px 0;
  position: relative;
  display: inline-block;
  color: red;
  width: 0px;
  height: 0px;
  border-right: 50px solid transparent;
  border-bottom: 33px solid gold;
  border-left: 50px solid transparent;
  transform: rotate(35deg);
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star:before {
  border-bottom: 40px solid gold;
  border-left: 15px solid transparent;
  border-right: 25px solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -25px;
  left: -30px;
  display: block;
  content: '';
  transform: rotate(-32deg);
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star:after {
  position: absolute;
  display: block;
  color: red;
  top: 0;
  left: -50px;
  width: 0;
  height: 0;
  border-right: 50px solid transparent;
  border-bottom: 35px solid gold;
  border-left: 50px solid transparent;
  transform: rotate(-70deg);
  content: '';
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star.active {
  border-bottom-color: green;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star.active:before {
  border-bottom-color: green;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star.active:after {
  border-bottom-color: green;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star.selected {
  border-bottom-color: green;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star.selected:before {
  border-bottom-color: green;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star.selected:after {
  border-bottom-color: green;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star:hover {
  border-bottom-color: green;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star:hover::before {
  border-bottom-color: green;
}
.gPg.rgGnrt > .rgBx > .lt > .strSel > .stars > .star:hover::after {
  border-bottom-color: green;
}

.gPg.rgGnrt > .rgBx > .lt > .rvrNm {
  width: 100%;
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.gPg.rgGnrt > .rgBx > .lt > .rvrNm > .lt {
  width: 200px;
}
.gPg.rgGnrt > .rgBx > .lt > .rvrNm > .rt {
  flex: 1;
}
.gPg.rgGnrt > .rgBx > .lt > .rvrNm > .rt > input {
  width: 100%;
}

.gPg.rgGnrt > .rgBx > .lt > .rvTxt {
  width: 100%;
  margin-top: -20px;
  position: relative;
}
.gPg.rgGnrt > .rgBx > .lt > .rvTxt > .lt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.gPg.rgGnrt > .rgBx > .lt > .rvTxt.hasTrnsl > .lt {
  margin-bottom: 0px;
}
.gPg.rgGnrt > .rgBx > .lt > .rvTxt > .lt > .rt > span {
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPg.rgGnrt > .rgBx > .lt > .rvTxt > .lt > .rt > span > i {
  margin-right: 5px;
}
.gPg.rgGnrt > .rgBx > .lt > .rvTxt.gIpt > textarea {
  height: 200px;
}
.rvTxt > .lt > .rt {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.gPg.rgGnrt > .rgBx > .lt > .rvTxt > .tbs {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.gPg.rgGnrt > .rgBx > .lt > .rvTxt > .tbs > span {
  cursor: default;
  padding: 4px 6px;
  background-color: var(--sidebar-main-menu-active-bg);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.gPg.rgGnrt > .rgBx > .lt > .rvTxt > .tbs > span.selected {
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
}


.gPg.rgGnrt > .rgBx > .lt > .prmptSel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.gPg.rgGnrt > .rgBx > .lt > .prmptSel > .tp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg.rgGnrt > .rgBx > .lt > .prmptSel > .tp > .promptSelect {
  min-width: 200px;
}

.gPg.rgGnrt > .rgBx > .rt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gPg.rgGnrt > .rgBx > .rt.hasResp {
  justify-content: flex-start;
  align-items: flex-start;
}
.gPg.rgGnrt > .rgBx > .rt > .tbCtrl {
  height: 40px;
  width: 100%;
  m1ax-width: 420px;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-top: 1px solid #929BB4;
  padding-top: 2px;
  margin-bottom: 20px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbCtrl::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPg.rgGnrt > .rgBx > .rt > .tbCtrl::-webkit-scrollbar
{
  height: 5px;
	width: 5px;
	background-color: #ffffff;
}
.gPg.rgGnrt > .rgBx > .rt > .tbCtrl::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.gPg.rgGnrt > .rgBx > .rt > .tbCtrl > button {
  background-color: #DBDEE6;
  color: #000000;
  border-radius: 0;
  padding: 8px 16px 8px 16px;
  min-width: 120px;
  margin-right: 2px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbCtrl > button.selected {
  background-color: #929BB4;
}

.gPg.rgGnrt > .rgBx > .rt > .tbBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  flex: 1;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .ttlBx {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  font-weight: var(--font-weight-bold);
  margin-bottom: 20px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .ttlBx > span {
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .ttlBx > span > i {
  margin-right: 5px;
}

.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .tbs {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .tbs > span {
  cursor: default;
  padding: 4px 6px;
  background-color: var(--sidebar-main-menu-active-bg);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .tbs > span.selected {
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
}

.gPg.rgGnrt > .rgBx > .rt > .tbBox.current > .genRBx > .respBx {
  o1verflow-y:  scroll;
  max-height: 160px;
  margin-bottom: 20px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .respBx > textarea {
  width: 100%;
  height: 260px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox.current > .genRBx > .respBx > textarea {
  width: 100%;
  height: 160px;
}

.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .respBx > textarea::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .respBx > textarea::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .respBx > textarea::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .usdPrmptBx {
  margin-bottom: 20px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .genRBx > .usdPrmptBx > p > b {
  font-weight: var(--font-weight-bold);
}

.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--generic-bg-color);
  padding: 20px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .folupSep {
  width: 100%;
  height: 1px;
  background-color: #C2C7D5;
  display: none;
}

.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .furthrInstrBtnBx {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .furthrInstrBtnBx > .gLbl {
  width: 300px;
  margin-bottom: 0;
}

.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .furthrInstrBtnBx > .prmptBtns {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 0px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .furthrInstrBtnBx > .prmptBtns > .promptSelect {
  width: 300px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .furthrInstrBtnBx > .prmptBtns > button {
  background-color: var(--sidebar-main-menu-active-bg);
  color: #000000;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .furthrInstrBtnBx > .prmptBtns > button.selected {
  background-color: gold;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .prmptSel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .prmptSel > .tp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg.rgGnrt > .rgBx > .rt > .tbBox > .folupBx > .prmptSel > .tp > .promptSelect {
  min-width: 280px;
}
