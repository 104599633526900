/* *************************************************** */

.gPg > .curRtnBx {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
}
.curRtnBx > .inrCrd {
  margin-right: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  max-width: calc(100% - 10px);
}
.curRtnBx > .inrCrd > .tp {
  margin-bottom: 5px;
}
.curRtnBx > .inrCrd > .tp > p {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.curRtnBx > .inrCrd > .tp > p > i {
  margin-left: 10px;
}
.curRtnBx > .inrCrd > .middle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.curRtnBx > .inrCrd > .middle > .lt {
  width: 200px;
}
@media (max-width: 1023px) {
  .curRtnBx > .inrCrd > .middle > .lt {
    width: 100px;
  }
}
.curRtnBx > .inrCrd > .middle > .rt {
  1flex: 1;
  max-width: calc(100% - 100px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 10px;

}
@media (max-width: 1023px) {
  .curRtnBx > .inrCrd > .middle > .rt {
    overflow-x: scroll;
  }
}
.curRtnBx > .inrCrd > .middle > .lt > p {
  font-size: 40px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.curRtnBx > .inrCrd > .middle > .rt > .otaEntry {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 30px;
}
.curRtnBx > .inrCrd > .middle > .rt > .otaEntry > img {
  height: 40px;
  margin-bottom: 10px;
}
.curRtnBx > .inrCrd > .middle > .rt > .otaEntry > p {
  margin: 0;
}
@media (max-width: 1023px) {
  .curRtnBx > .inrCrd > .middle > .rt > .otaEntry {
    margin-right: 10px;
  }
}

.curRtnBx > .inrCrd > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.curRtnBx > .inrCrd > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}

/* ************************************************* */

.gPg > .dshStatBx {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.gPg > .dshStatBx > .gNumberCard {
  1width: 200px;
  margin-right: 0px;
  flex: 1;
  1max-width: 300px;
}
.gPg > .dshStatBx > .gNumberCard.max-50 {
  max-width: 50%;
}

.gPg > .dshStatSecBx {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.gPg > .dshStatSecBx > .gMixedCard {
  flex: 1;
}
.gPg > .dshStatSecBx > .gMixedCard.max-50 {
  max-width: 50%;
}


.gPg > .engUsgLgMsgBx {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.gPg > .engUsgLgMsgBx > .ttl {
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-bottom: 10px;
}
.gPg > .engUsgLgMsgBx > .subTle {
  margin-bottom: 30px;
}
.gPg > .engUsgLgMsgBx > .ttl > i {
  font-size: var(--font-size-primary);
  margin-left: 10px;
}
.gPg > .engUsgLgMsgBx > .entry {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.gPg > .engUsgLgMsgBx > .entry > p.numSegs {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 5px;
}

/* ************************************************* */

.gPg > .rcntRevBx {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: var(--generic-card-shadow);
}
@media (max-width: 1023px) {
  .gPg > .rcntRevBx {
    padding: 15px;
  }
}
.gPg > .rcntRevBx > .hdr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.gPg > .rcntRevBx > .hdr  > .left {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary-text-color);
}
.gPg > .rcntRevBx > .hdr  > .left > i {
  margin-left: 5px;
  color: var(--primary-text-color-light);
  font-size: var(--font-size-primary);
}
.gPg > .rcntRevBx > .hdr  > .left > span {
  margin-left: 10px;
  color: var(--primary-text-color-light);
}
.gPg > .rcntRevBx > .hdr  > .right > a {
  font-size: 16px;
  f1ont-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  text-decoration: none;
}
.gPg > .rcntRevBx > .body {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.gPg > .rcntRevBx > .body > .recentReviewCard {
  flex: 1;
}

/************************************/

.gPg > .doMoreBx {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: var(--generic-card-shadow);
}
@media (max-width: 1023px) {
  .gPg > .doMoreBx {
    padding: 15px;
  }
}
.gPg > .doMoreBx > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.gPg > .doMoreBx > .header > .left {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary-text-color);
}
.gPg > .doMoreBx > .header > .right > a {
  font-size: 16px;
  f1ont-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  text-decoration: none;
}
.gPg > .doMoreBx > .body {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.gPg > .doMoreBx > .body > .gCrd {
  w1idth: 50%;
  flex: 1;
  max-width: calc(50% - 10px);
  border: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
}
.gPg > .doMoreBx > .body > .gCrd > .innerBox {
  display: flex;
  flex-direction: column;
}
.gPg > .doMoreBx > .body > .gCrd > .innerBox > .headerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin-bottom: 20px;
}
.gPg > .doMoreBx > .body > .gCrd > .innerBox > .bodyBox {
  color: var(--primary-text-color-light);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-bottom: 20px;
}
.gPg > .doMoreBx > .body > .gCrd > .innerBox > .footerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.gPg > .doMoreBx > .body > .gCrd > .innerBox > .footerBox > .left {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.gPg > .doMoreBx > .body > .gCrd > .innerBox > .footerBox > .right {
  width: 160px;
}
.gPg > .doMoreBx> .body > .gCrd > .innerBox > .footerBox > .left > img {
  height: 180px;
}
.gPg > .doMoreBx > .body > .gCrd > .innerBox > .footerBox > .right > span {
  padding: 8px 24px;
  color: var(--gte-primary-color);
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--gte-primary-color);
  cursor: pointer;
}

/***********************************/

.sdMdl.knowMoreModal > .ctnt {
  animation: slideInRight 0.5s;
  max-width: 1200px;
  padding: 0;
}
