.sbi > .fxd.top {
  height: 60px;
  border-bottom: 1px solid var(--generic-border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.sbi > .fxd.top {
  height: 80px;
}
.sbi > .fxd.top > .ttl {
  padding-bottom: 5px;
  font-size: var(--font-size-top-bar);
  text-transform: inherit;
}

.sbi > .mn {
  height: calc(100% - 61px);
  overflow-y: scroll;
}
.sbi > .mn::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #ffffff;
}
.sbi > .mn::-webkit-scrollbar
{
  height: 6px;
	width: 5px;
	background-color: #ffffff;
}
.sbi > .mn::-webkit-scrollbar-thumb
{
  o1utline: 1px solid slategrey;
	border-radius: 10px;
	background-color: #BDBEC1;
}

.sbi > .mn > ul.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sbi > .mn > .menu > li:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.sbi > .mn > .menu > li.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
.sbi > .mn > .menu > li.active > a {
  color: var(--gte-primary-color);
}
.sbi > .mn > .menu > li > a {
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: var(--primary-text-color);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.sbi > .mn > .menu > li > a > .lt {
  width: 20px;
  margin-right: 10px;
}
.sbi > .mn > .menu > li > a > .lt > img {
  height: 16px;
}
.sbi > .mn > .menu > li > a > .rt {
  display: flex;
  flex-direction: column;
}
.sbi > .mn > .menu > li > a > .rt > p {
  font-size: var(---font-size-primary);
  font-weight: var(--font-weight-bold);
}
.sbi > .mn > .menu > li > a > .rt > span {
  font-size: var(--font-size-sub-mid-primary);
  font-weight: normal;
  color: var(--primary-text-color-light);
}
