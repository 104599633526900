#modal-email-template {
  position: relative;
  z-index: 9999;
}
.emailTemplateBox {
  background-color: rgba(0,0,0,0.5);
  background-color: #FFFFFF;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0!important;

}
.emailTemplateBox > .content {
  m1ax-width: 900px;
  width: 90%;
  width: 100%;
  height: calc(100% - 30px);
  height: 100%;
  background-color: #FFFFFF;
  transition: all 0.5s;
  animation: slideInRight 0.5s;
  overflow-y: scroll;
  padding: 10px 20px 20px 20px;
}
.emailTemplateBox > .content > .closeBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.emailTemplateBox > .content > .closeBar > .title {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.emailTemplateBox > .content > .closeBar > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emailTemplateBox > .content > .closeBar > .close > img {
  margin-right: 5px;
}

.emailTemplateBox > .content .templateTypeBox {
  position: fixed;
  left: calc(50% - 100px);
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.emailTemplateBox > .content .templateTypeBox {
  padding: 6px 20px;
  background-color: #f7f7f7;
  font-size: 16px;
}

.emailTemplateBox > .content > .templateLoading {
  position: fixed;
  left: calc(50% - 50px);
  top: 100px;
}
.emailTemplateBox > .content > .templateLoading > span > i {
  font-size: 20px;
}

.emailTemplateBox > .content > .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0 0 0;
  border-bottom: 0px solid #e6e6e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.emailTemplateBox > .content > .top > .left {
  display: flex;
  flex-direction: column;
}
.emailTemplateBox > .content > .top > .left > .nameSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}
.emailTemplateBox > .content > .top > .left > .nameSection > .nameBox > .top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.emailTemplateBox > .content > .top > .left > .nameSection > .nameBox > .top > input {
  border: 1px solid var(--generic-border-color);
  border-radius: var(--generic-border-radius);
  padding: 10px;
  padding-right: 36px;
  width: 200px;
  font-size: 16px;
}
.emailTemplateBox > .content > .top > .left > .nameSection.desc > .nameBox > .top > input {
  width: 300px;
  font-size: 14px;
}
.emailTemplateBox > .content > .top > .left > .nameSection > .nameBox > .top > input:read-only {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.emailTemplateBox > .content > .top > .left > .nameSection > .nameBox > .top > input.inputError {
  border-color: var(--generic-error-color);
}
.emailTemplateBox > .content > .top > .left > .nameSection > .nameBox > .top > span.enableEdit {
  margin-left: -24px;
  cursor: pointer;
}
.emailTemplateBox > .content > .top > .left > .nameSection > .nameBox > .error {
  height: 20px;
}
.emailTemplateBox > .content > .top > .left > .nameSection > span.changeTemplate {
  margin-left: 16px;
  margin-top: -6px;
  cursor: pointer;
  color: var(--gte-primary-color);
}

.emailTemplateBox > .content > .top > .right > .top > .saveSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section >  span {
  padding: 8px 32px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  cursor: default;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section > span.save {
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
  width: 100px;
  text-align: center;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section > span.save.disabled {
  cursor: not-allowed;
  background-color: grey;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section > span.sendTest {
  background-color: #FFFFFF;
  border: 1px solid grey;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section > span.save > i {
  margin-left: 5px;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section > .error {
  height: 9px;
}

.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section.close {
  margin-bottom: 10px;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section.close > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 6px;
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section.close > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.emailTemplateBox > .content > .top > .right > .top > .saveSection > .section.close > span > img {
  margin-right: 5px;
}

.emailTemplateBox > .content > .builderInstructions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e7;
}
.emailTemplateBox > .content > .builderInstructions > p {
  cursor: pointer;
}

.emailTemplateBox > .content > .etBdy > .emailBuilder {
}


.emailTemplateBox > .content > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.emailTemplateBox > .content > .title > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emailTemplateBox > .content > .title > .close > img {
  margin-right: 5px;
}
.emailTemplateBox.knowMoreModal > .content {
  overflow-y: hidden;
}

.emailTemplateBox {
  display: flex;
  f1lex-direction: column!important;
}



.emailTemplateBox.loading > .content > .etBdy {
  position: fixed;
  left: 50%;
  top: 50%;
}
