.gMixedCard {
  display: flex;
  flex-direction: column;
}
.gMixedCard > .top {
  margin-bottom: 5px;
}
.gMixedCard > .top > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gMixedCard > .top > p > i {
  margin-left: 10px;
}
.gMixedCard > .middle {
  display: flex;
  flex-direction: row;
}
.gMixedCard > .middle > .left,
.gMixedCard > .middle > .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.gMixedCard > .middle > .left > p,
.gMixedCard > .middle > .left > span {
  font-size: 40px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.gMixedCard > .middle > .left > span {
  color: var(--primary-text-color-light);
}

.gMixedCard > .middle > .left > p.cmpVal {
  font-size: 18px;
}
.gMixedCard > .middle > .left > p.cmpChng {
  font-size: 14px;
}

.gMixedCard > .middle > .left > i.fa-triangle-exclamation {
  font-size: 30px;
}

.gMixedCard > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gMixedCard > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
.gMixedCard > .footer > a {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
