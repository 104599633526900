
.etyW {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.etyW > .inrB {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: visible;
  transition: all 2s;
  margin-top: -200px;
}
.etyW > .inrB > img {
  height: 200px;
}
.etyW > .inrB > .title {
  font-size: 16px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin: 40px 0 20px 0;
}
.etyW > .inrB > .info {
  color: var(--primary-text-color-light);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-bottom: 40px;
}
.etyW > .inrB > .actions {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.etyW > .inrB > .actions > .entry {
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
  border-radius: var(--generic-border-radius);
}
.etyW > .inrB > .actions > .entry > a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #FFFFFF;
  text-decoration: none;
  padding: 10px 30px;
}
.etyW > .inrB > .actions > .entry > a > img {
  margin-right: 10px;
}
