.gPageWrap.broadcastsHistory {
  max-width: 100%;
  margin-bottom: 0;
}
.gPageWrap.allBroadcasts > .gPageTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  1padding: 0 20px 38px 20px;
}
.gPageWrap.broadcastsHistory > .gPageTitle > span.add {
  margin-left: 10px;
  cursor: pointer;
}
.gPageWrap.broadcastsHistory > .gPageTitle > span > .fa {
  font-size: 18px;
}

.gPageWrap.broadcastsHistory > .allHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.gPageWrap.broadcastsHistory > .allHeader > .display > span {
  margin-right: 10px;
  cursor: pointer;
}
.gPageWrap.broadcastsHistory > .allHeader > .display > span > i {
  font-size: 20px;
  color: #a59e9e;
}
.gPageWrap.broadcastsHistory > .allHeader > .display > span.active > i {
  color: var(--gte-primary-color);
}

.gPageWrap.broadcastsHistory > .searchBox {
  margin-bottom: 20px;
}
.gPageWrap.broadcastsHistory > .searchBox > input {
  width: calc(100% - 24px);
  border: var(--gt-form-input-border);
  border-radius: var(--gt-form-input-border-radius);
  padding: 9px 12px 8px 12px;
  margin-bottom: 1px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
}

.gPageWrap.broadcastsHistory > .allWrap {
  1padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  margin-bottom: 20px;
  width: calc(100% - 40px);
  1margin: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  b1ox-shadow: inset 0 0 2px #666;
  border: 1px solid #e6e6e7;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .gPageWrap.broadcastsHistory > .allWrap > .broadcastBox {
    flex-direction: column;
  }
}
.break {
  flex-basis: 100%;
  height: 0;
  display: none;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox.header {
  background-color: #f6f6f6;
  box-shadow: none;
  padding: 10px 20px;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox.header:hover {
  box-shadow: none;
}

@media (max-width: 1024px) {
  .gPageWrap.broadcastsHistory > .allWrap > .broadcastBox {
    padding: 10px;
  }
}

.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox:hover {
  b1ox-shadow: inset 0 0 3px #000;
  border: 1px solid #AAA;
}

.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partOne {
  width: 40%;
  display: flex;
  flex-direction: row;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partTwo {
  width: 30%;
  display: flex;
  flex-direction: row;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree {
  width: 30%;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1024px) {
  .gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partOne,
  .gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partTwo,
  .gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree {
    width: 100%;
    margin-bottom: 10px;
  }
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partOne > .type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 2.5;
  min-width: 100px;
}

.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partOne > .type > span {
  background-color: #ecf0f8;
  padding: 5px 12px;
  font-weight: var(--font-weight-bold);
  border-radius: var(--generic-border-radius);
  color: var(--gte-primary-color);
  font-size: 14px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partOne > .type > span > img {
  margin-right: 2px;
}

.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partOne > .target {
  flex: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin-right: 10px;
  f1ont-size: 12px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  t1ext-transform: uppercase;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox.header > .partOne > .target {
  font-size: inherit;
  text-transform: none;
  font-weight: 400;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partOne > .name {
  flex: 2;
  margin-right: 16px;
  1font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin-right: 10px;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partTwo > .message {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  flex: 4;
  margin-right: 16px;
  color: var(--primary-text-color-light);
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox.header > .partTwo > .message {
  color: var(--primary-text-color);
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partTwo > .message > .far,
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .when > .far {
  margin-right: 10px;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .when {
  flex: 3;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .sentStats {
  flex: 2;
  text-align: center;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-right: -10px;
  margin-top: 5px;
}

/* ************** dropdown menu ********************** */

.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .dropdown > .dropbtn,
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .dropdown > .dropbtn {
  1background-color: #4CAF50;
  1background-color: #FFFFFF;
  color: #AAA;
  1padding: 2px;
  font-size: 16px;
  border: none;
}
.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .oMenu > .dropdown > .dropbtn,
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu > .dropdown > .dropbtn {
  padding: 8px 10px;
  background-color: #FFFFFF;
  border-radius: var(--generic-border-radius);
}
.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .oMenu > .dropdown > .dropbtn:hover,
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu > .dropdown > .dropbtn:hover {
  background-color: #f7f7f9;
}

.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .oMenu > .dropdown,
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: -5px;
}

.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .oMenu > .dropdown > .dropdown-content,
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  -webkit-backdrop-filter: blur(19.6px);
  backdrop-filter: blur(19.6px);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .oMenu > .dropdown > .dropdown-content {
  left: -60px;
  top: 25px;
}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu > .dropdown > .dropdown-content {
  left: -140px;
  top: 25px;
}

.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .oMenu > .dropdown > .dropdown-content p,
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu > .dropdown > .dropdown-content p {
  color: var(--primary-text-color);
  padding: 14px 20px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid var(--generic-border-color);
  cursor: pointer;
}

.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .oMenu > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: pointer;}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: pointer;}

.gPageWrap.broadcastsHistory > .allWrap.grid > .broadcastBox > .top > .oMenu > .dropdown .dropdown-content.open {display: block;}
.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .partThree > .oMenu > .dropdown .dropdown-content.open {display: block;}

.gPageWrap.broadcastsHistory > .allWrap > .broadcastBox > .top > .dropdo1wn:hover .dropdown-content {display: block;}


/* ************************************ */
