.tb {
  height: 50px;
  padding: 0px;
  background-color: var(--gte-primary-color);
  position: relative;
  width: calc(100% - 13px);
  width: 100%;
  top: 0;
  font-size: var(--font-size-top-bar);
  font-weight: var(--font-weight-bold);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.tb > .l {
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tb > .l > .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.tb > .l > .logo > img {
  margin: 0 10px;
  height: 24px;
}
.tb > .l > .logo > a {
  text-decoration: none;
  color: #FFFFFF;
}
.tb > .m  {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.tb > .r  {
  width: 360px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.tb > .r > .opt {
  min-width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.tb > .r > .opt > a {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tb > .r > .lOpt {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.tb > .r > .lOpt > .sep {
  height: 30px;
  width: 1px;
  background-color: #FFFFFF;
}
.tb > .r > .lOpt > .ls {
  w1idth: 230px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
}
.tb > .r > .lOpt > .ls > .hn {
  width: 180px;
  font-size: var(--font-size-primary);
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  cursor: default;
}
.tb > .r > .lOpt > .ls > .hn > span.engCnt {
  font-size: 12px;
  margin-right: 5px;
}
.tb > .r > .lOpt > .ls > .lsIcon {
  width: 40px;
}
.tb > .r > .lOpt > a.setngs {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tb > .r > .lOpt > a.setngs:hover {
  background-color: var(--gte-primary-color-700);
}
.tb > .r > .lOpt > a.setngs > img {
  width: 22px;
}

.tb > .r > .prf {
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tb > .m > .prd {
  padding: 0 10px;
}
.prd > a {
  color: #FFFFFF;
  text-decoration: none;
}

/* ************** product - dropdown menu ********************** */

.prd > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
  color: #FFFFFF;
}
.prd > .dropdown > .dropbtn {
  height: 50px;
}
.prd > .dropdown.mActive > .dropbtn {
  background-color: var(--tb-bg-hover-color);
  background-color: var(--gte-primary-color-700);
}
.prd > .dropdown:hover {
  background-color: var(--tb-bg-hover-color);
  background-color: var(--gte-primary-color-700);
}
.prd > .dropdown.open > .dropbtn {
  background-color: var(--tb-bg-hover-color);
  background-color: var(--gte-primary-color-700);
}
.prd > .dropdown > .dropbtn > a {
  color: #FFFFFF;
  padding-top: 2px;
}

.prd > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  b1ackground-color: var(--sidebar-main-menu-bg);
  b1ackground-color: var(--gte-primary-color);
  background-color: var(--tb-bg-hover-color);
  background-color: var(--gte-primary-color-700);
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99;
}
.prd > .dropdown > .dropdown-content {
  left: 0px;
}

.prd > .dropdown > .dropdown-content a {
  color: black;
  color: var(--gte-neutral-50-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: var(--font-size-primary);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prd > .dropdown > .dropdown-content a:hover {
  background-color: var(--generic-hover-bg-color);
  background-color: var(--tb-bg-hover-color-dark);
  background-color: var(--gte-primary-color-900);
}
.prd > .dropdown > .dropdown-content > a > span > i.fa-bookmark {
  font-size: 12px;
}

.prd > .dropdown.open .dropdown-content {display: block;}

.prd > .dropdown > .dropbtn {
  padding: 10px 20px;
  color: var(--gte-neutral-50-color);
  background-color: var(--gte-primary-color);
  cursor: pointer;
  1border-radius: 20px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

}
.prd > .dropdown > .dropbtn:hover {
  background-color: var(--tb-bg-hover-color);
  background-color: var(--gte-primary-color-700);
}
.prd > .dropdown.menuBox > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);

}

.prd > .dropdown > .dropbtn > span.unrCnt {
  background-color: var(--gte-primary-color);
  border-radius: 10px;
  position: absolute;
  top: 2px;
  right: 6px;
  height: 18px;
  min-width: 18px;
  background-color: #ba73f1;
  color: #FFFFFF;
  border-radius: 10px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prd > .dropdown > .dropbtn > i.fa-bookmark {
  font-size: 14px;
  margin-top: 4px;
}

/* ************************************ */


/* ************** location selector - dropdown menu ********************** */

.lsIcon > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
  color: #FFFFFF;
}
.lsIcon > .dropdown > .dropbtn {
  flex-direction: column;
  display: flex;
  justify-content: center;
  cursor: default;
}
.lsIcon > .dropdown > .dropbtn > img {
  height: 20px;
}
.lsIcon > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--sidebar-main-menu-bg);
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99;
}
.lsIcon > .dropdown > .dropdown-content {
  left: -180px;
  overflow-y: scroll;
  max-height: 500px;
}
.lsIcon > .dropdown > .dropdown-content::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.lsIcon > .dropdown > .dropdown-content::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.lsIcon > .dropdown > .dropdown-content::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.lsIcon > .dropdown > .dropdown-content > .filter {
  color: var(--primary-text-color);
  font-size: var(--font-size-primary);
}
.lsIcon > .dropdown > .dropdown-content > .filter > input {
  padding: 10px;
  width: calc(100% - 20px);
}

.lsIcon > .dropdown > .dropdown-content .tbLcRw {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: var(--font-size-sub-mid-primary);
  cursor: pointer;
  border-bottom: 1px solid var(--generic-border-color);
}
.lsIcon > .dropdown > .dropdown-content .tbLcRw > .rwIt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.lsIcon > .dropdown > .dropdown-content .tbLcRw > .rwIt > .engageUnread {
  min-width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: orange;
}

.tbLcRw > .rwIt.prds {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
  border-bottom: 0px solid var(--generic-border-color);
}
.tbLcRw > .rwIt.prds > span {
  padding: 2px 4px;
  border-radius: var(--generic-border-radius);
  background-color: #b0efae;
}

.lsIcon > .dropdown > .dropdown-content .tbLcRw:hover {background-color: var(--generic-hover-bg-color);}

.lsIcon > .dropdown.open .dropdown-content {display: block;}

.lsIcon > .dropdown > .dropbtn {
  padding: 10px;
  background-color: var(--gte-primary-color);
}
.lsIcon > .dropdown > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);
  background-color: var(--gte-primary-color-700);
}

/* ************************************ */



/* ************** profile - dropdown menu ********************** */

.prfMenu > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
  color: #FFFFFF;
}
.prfMenu > .dropdown > .dropbtn {
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.prfMenu > .dropdown > .dropbtn > img {
  height: 20px;
}
.prfMenu > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--sidebar-main-menu-bg);
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 99;
}
.prfMenu > .dropdown > .dropdown-content {
  left: -180px;
}

.prfMenu > .dropdown > .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.prfMenu > .dropdown > .dropdown-content a:hover {background-color: var(--generic-hover-bg-color);}

.prfMenu > .dropdown.open .dropdown-content {display: block;}

.prfMenu > .dropdown > .dropbtn {
  padding: 10px;
  background-color: var(--gte-primary-color);
  cursor: pointer;
  1border-radius: 20px 16px;
}
.prfMenu > .dropdown > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);
  background-color: var(--gte-primary-color-700);
}
.prfMenu > .dropdown > .dropbtn > img {
  padding: 3px;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
}

/* ************************************ */
