.gPWrp.lblSingle > .gBCtnt > .gBCtRw.titleRow > .gRwBxLt > .gLbl > span.addOn {
  color: var(--primary-text-color-light);
  margin-left: 5px;
}
.gBCtRw.lblRw {
  flex-direction: row!important;
  justify-content: space-between;
  padding: 10px;
}
.gBCtRw.lblRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.lblRw > .lt {
  display: flex;
  flex-direction: row!important;
  gap: 5px;
}
.gBCtRw.lblRw > .lt > .clrBx {
  margin-right: 10px;
  height: 18px;
  width: 18px;
  border-radius: 2px;
}
.gBCtRw.lblRw > .rt {
  display: flex;
  flex-direction: row!important;
  gap: 5px;
}
.gBCtRw.lblRw > .rt > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: var(--generic-border-radius);
}
.gBCtRw.lblRw > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.gBCtRw.clrRw > .gRwBxLt > .labelColors {
  display: flex;
  flex-direction: row;
}
.gBCtRw.clrRw > .gRwBxLt > .labelColors > .colorDot {
  border-radius: 20px;
  border: 1px solid;
  margin-right: 20px;
  padding: 7px;
}
.gBCtRw.clrRw > .gRwBxLt > .labelColors > .colorDot.selected {
  border-radius: 20px;
  border: 1px solid;
  margin-right: 20px;
  padding: 7px;
}
.gBCtRw.clrRw > .gRwBxLt > .labelColors > .colorDot > .inner {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
