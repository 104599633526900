.gBoxWrap.sampleEmailBoxWrap > .innerBox > .topBox {
  display: block;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .topBox > .titleBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .topBox > .titleBox > .close {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
  padding: 3px 6px;
  border-radius: var(--generic-border-radius);
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .topBox > .titleBox > .close:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .topBox > .titleBox > .close > img {
  margin-right: 5px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap {
  display: flex;
  flex-direction: column;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox {
  display: flex;
  flex-direction: column;
  border-top: 0px solid grey;
  margin-top: 20px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .headerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .headerBox > img {
  width: 300px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .headerBox > p {
  font-size: 18px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .primaryMessage {
  margin-bottom: 20px;
  font-size: 16px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .primaryMessage.highlight {
  background-color: yellow;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p {
  font-size: 12px;
  margin-bottom: 4px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.hotelMessage {
  margin-bottom: 20px;
  font-size: 14px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.hotelMessage.highlight {
  background-color: yellow;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.poweredBy {
  margin-top: 20px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.gBoxWrap.sampleEmailBoxWrap > .innerBox > .contentBox > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .footerBox > p > span.link {
  color: blue;
  font-size: 12px;
  cursor: pointer;
}

/* smiley */

.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .moodBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* stars */

.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars {
  display: flex;
  gap: 10px;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars > .starIcon {
  height: 30px;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars > .star {
  flex: 25%;
  margin: 10px 0;
  position: relative;
  display: inline-block;
  color: red;
  width: 0px;
  height: 0px;
  border-right: 50px solid transparent;
  border-bottom: 33px solid gold;
  border-left: 50px solid transparent;
  transform: rotate(35deg) scale(1);
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars > .star:before {
  border-bottom: 40px solid gold;
  border-left: 15px solid transparent;
  border-right: 25px solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -25px;
  left: -30px;
  display: block;
  content: '';
  transform: rotate(-32deg);
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .starBox > .stars > .star:after {
  position: absolute;
  display: block;
  color: red;
  top: 0;
  left: -50px;
  width: 0;
  height: 0;
  border-right: 50px solid transparent;
  border-bottom: 35px solid gold;
  border-left: 50px solid transparent;
  transform: rotate(-70deg);
  content: '';
}

/* nps */

.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .npsBox {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .npsBox > .top {
  display: flex;
  flex-direction: row;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .npsBox > .top > .npsNumber {
  margin: 0 4px;
  text-decoration: none;
  padding: 6px 8px;
  border: 1px solid orange;
  border-radius: 5px;
  cursor: pointer;
}
.emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .npsBox > .bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
