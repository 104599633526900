.gPWrp.checkInNow {
  border-radius: 5px;
}
.gPWrp.checkInNow > .gBCtnt > .gBCtRw.rmNumRw > .gRwBxLt {
  width: 46%;
}
.gPWrp.checkInNow > .gBCtnt > .gBCtRw.chkOutDtRw {
  justify-content: space-between;
  gap: 20px;
}
.gPWrp.checkInNow > .gBCtnt > .gBCtRw.chkOutDtRw > .gRwBxLt {
  width: 46%;
}
.gPWrp.checkInNow > .gBCtnt > .gBCtRw.chkOutDtRw > .gRwBxRt {
  width: 46%;
}

/* ******************* Modal ******************* */

/* .cModal.mtModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cModal.mtModal > .content {
  max-width: 800px;
  width: 60%;
  max-height: 800px;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
  1overflow-y: scroll;
  1overflow-x: hidden;
}
@media (max-width: 1366px) {
  .cModal.mtModal > .content {
    width: 80%;
  }
}
.cModal.mtModal > .content > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
}
.cModal.mtModal > .content > .close > .fas {
  font-size: 20px;
} */


.gModal.checkInNow {
  padding: 20px;
}
.gModal.checkInNow > .title {
  font-size: 20px;
  margin-bottom: 20px;
}
.gModal.checkInNow > .content {
}
.gModal.checkInNow > .content > .titleRow {
  margin-bottom: 10px;
}
.gModal.checkInNow > .content > .titleRow > .itemBox > .gLabel {
  font-size: 16px;
}
.gModal.checkInNow > .content > .dateRow {
  margin-bottom: 20px;
}
.gModal.checkInNow > .content > .dateRow > .itemBox > .gLabel {
  font-size: 16px;
}
.gModal.checkInNow > .content > .roomNumberRow {
  margin-bottom: 10px;
}
.gModal.checkInNow > .content > .roomNumberRow > .itemBox > .gInput {
  width: 40%;
}
.gModal.checkInNow > .content > .roomNumberRow > .itemBox > .gInput > input {
  1font-size: 16px;
}
.gModal.checkInNow > .content .itemBox {
  display: flex;
  flex-direction: column;
}
.gModal.checkInNow > .content .itemBox textarea {
  1width: 100%;
  resize: none;
  margin-bottom: 30px;
}

.gModal.checkInNow > .content > .contentRow label {
  margin-bottom: 5px;
  font-size: 14px;
}

.gModal.checkInNow > .content  > .byTwoRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 0px;
}
.gModal.checkInNow > .content  > .byTwoRow > .itemBox {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.gModal.checkInNow > .content > .byOneRow.dateRangeErrorRow {
  margin-top: 0;
  margin-bottom: 30px;
  color: var(--form-error-color);
  font-weight: var(--font-weight-bold);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gModal.checkInNow > .content > .byOneRow.roomNumberErrorRow {
  margin-top: 0;
  margin-bottom: 30px;
  color: var(--form-error-color);
  font-weight: var(--font-weight-bold);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


.gModal.checkInNow > .content > .helpText {
  margin-top: 10px;
  margin-bottom: 10px;
}
.gModal.checkInNow > .content > .currentStatus {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.gModal.checkInNow > .content > .currentStatus > p.error {
  color: var(--form-error-color);
}
.gModal.checkInNow > .content > .currentStatus > p.success {
  color: green;
}


.gModal.checkInNow > .content > .saveRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: var(--font-size-primary);
}
.gModal.checkInNow > .content > .saveRow > button, .gModal.addTemplate > .content > .saveRow > span.cancel {
  background-color: #FFFFFF;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid grey;
}
.gModal.checkInNow > .content > .saveRow > button.cancel:focus {
  background-color: var(--btn-cancel-focus-color);
}
.gModal.checkInNow > .content > .saveRow > span.cancel {
  background-color: #FFFFFF;
  border: 1px solid grey;
  width: 120px;
  text-align: center;
}
.gModal.checkInNow > .content > .saveRow > button.ok {
  background-color: var(--btn-color);
  color: #FFFFFF;
  width: 160px;
  margin-left: 10px;
}
.gModal.checkInNow > .content > .saveRow > button.ok:focus {
  b1ackground-color: var(--btn-focus-color);
}
.gModal.checkInNow > .content > .saveRow > button.ok > .fa {
  margin-left: 10px;
}
.gModal.checkInNow > .content > .saveRow  > button.ok.disabled {
  background-color: var(--btn-disabled-color);
  cursor: not-allowed;
}
