.gPg.grpDashPg > .dtFltBx {
  margin-top: 0;
  margin-bottom: 20px;
}
.gPg > .dtFltBx > .left > .edit {
  margin-left: 10px;
}
.gPg > .dtFltBx > .left > .edit {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.gPg > .dtFltBx > .left > .edit:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.gPg.grpDashPg > .dtFltBx > .left.shrGrp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.gPg.grpDashPg > .dtFltBx > .left.shrGrp > .shrBy > span {
  margin-left: 10px;
}

.gPg > .pendingActionsBox {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.gPg > .pendingActionsBox > .gActionCard {
  margin-right: 0px;
  flex: 1;
}
.gPg > .pendingActionsBox.numberOfCards_1 > .gActionCard {
  flex: none;
  width: 50%;
}

.gPg > .dshStatBx {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.gPg > .dshStatBx > .gNumberCard {
  1width: 200px;
  margin-right: 0px;
  flex: 1;
  1max-width: 300px;
}

.gPg > .dshStatSecBx {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.gPg > .dshStatSecBx > .gMixedCard {
  flex: 1;
}


/* ************************************************* */

.gPg > .rcntRevBx {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: var(--generic-card-shadow);
}
@media (max-width: 1023px) {
  .gPg > .rcntRevBx {
    padding: 15px;
  }
}
.gPg > .rcntRevBx > .hdr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.gPg > .rcntRevBx > .hdr  > .left {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary-text-color);
}
.gPg > .rcntRevBx > .hdr  > .left > i {
  margin-left: 5px;
  color: var(--primary-text-color-light);
}
.gPg > .rcntRevBx > .hdr  > .left > span {
  margin-left: 10px;
  color: var(--primary-text-color-light);
}
.gPg > .rcntRevBx > .hdr  > .right > a {
  font-size: 16px;
  f1ont-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  text-decoration: none;
}
.gPg > .rcntRevBx > .body {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.gPg > .rcntRevBx > .body > .recentReviewCard {
  flex: 1;
}

/************************************/

.gPg > .statBox {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.gPg > .statBox > .gNumberCard {
  1width: 200px;
  margin-right: 0px;
  flex: 1;
  1max-width: 300px;
}
.gPg > .statBox > .gOtaSplitCard {
  margin-right: 0px;
  flex: 1;
}
.gPg > .statBox > .gLocationListCard {
  margin-right: 0px;
  flex: 1;
}

/**********************************/

.gPg > .trendBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
.gPg > .trendBox > .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
  margin-bottom: 20px;
}
.gPg > .trendBox > .title > i {
  margin-left: 5px;
}
.gPg > .trendBox > .trendOtaSelect {
  width: 240px;
}
