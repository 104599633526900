/* ******************* Modal ******************* */

.cModal.alModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cModal.alModal > .content {
  width: 480px;
  max-height: 800px;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
  1overflow-y: scroll;
  1overflow-x: hidden;
}
@media (max-width: 1366px) {
  .cModal.alModal > .content {
    width: 80%;
  }
}
.cModal.alModal > .content > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
}
.cModal.alModal > .content > .close > .fas {
  font-size: 20px;
}

.gPWrp.addNewLabel {
  border-radius: 5px;
}

.gPWrp.addNewLabel > .gBCtnt > .gBCtRw.colorRow {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e7;
  margin-bottom: 30px;
}
.gPWrp.addNewLabel > .gBCtnt > .gBCtRw.colorRow .itemBox > .gLabel {
  margin-bottom: 10px;
}
.gPWrp.addNewLabel > .gBCtnt > .gBCtRw.colorRow .itemBox > .labelColors {
  display: flex;
  flex-direction: row;
}
.gPWrp.addNewLabel > .gBCtnt > .gBCtRw.colorRow .itemBox > .labelColors > .colorDot {
  border-radius: 20px;
  border: 1px solid;
  margin-right: 20px;
  padding: 7px;
}
.gPWrp.addNewLabel > .gBCtnt > .gBCtRw.colorRow .itemBox > .labelColors > .colorDot.selected {
  border-radius: 20px;
  border: 1px solid;
  margin-right: 20px;
  padding: 7px;
}
.gPWrp.addNewLabel > .gBCtnt > .gBCtRw.colorRow .itemBox > .labelColors > .colorDot > .inner {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
