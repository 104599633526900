.trendAnalysis > .listBox > .singleEntry {
    padding: 20px;
    border-radius: var(--generic-border-radius);
    box-shadow: var(--generic-card-shadow);
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .trendAnalysis > .listBox > .singleEntry > .left {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .left > .smlRslt {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .left > .smlRslt > img {
    margin-right: 10px;
    height: 30px;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .left > .starRslt {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  .trendAnalysis > .listBox > .singleEntry > .left > .starRslt > img {
    margin-top: -5px;
    height: 20px;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .right {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .name {
    font-size: var(--font-size-primary);
    font-weight: var(--font-weight-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--primary-text-color);
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .extra {
    display: flex;
    flex-direction: row;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .extra > .date {
    font-size: var(--font-size-primary);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--primary-text-color-light);
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .extra > .source {
    margin-left: 5px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-primary);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--gte-primary-color);
  }
  
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .directedTo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .directedTo > span {
    margin-left: 5px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .directedTo > span > img {
    height: 24px;
    margin-left: 5px;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .roomNum {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    b1ackground-color: #f1f2f4;
    p1adding: 6px 12px;
    border-radius: var(--generic-border-radius);
    margin-top: 2px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .checkInDate {
    margin: 0;    
}
  .trendAnalysis > .listBox > .singleEntry > .right > .guestInfo > .left > .collectionSource {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    b1ackground-color: #f1f2f4;
    p1adding: 6px 12px;
    border-radius: var(--generic-border-radius);
    margin-top: 10px;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .right > .answs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: var(--generic-border-radius);
    box-shadow: var(--generic-card-shadow);
  }
  
  
  .trendAnalysis > .listBox > .singleEntry > .right > .feedbackText {
    word-break: break-all;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .submitRow > .buttonWrap > .gButton {
    border-radius: var(--generic-border-radius);
    border: 1px solid var(--primary-text-color-light);
    color: var(--primary-text-color-light);
    padding: 5px 10px;
    font-weight: var(--font-weight-bold);
    cursor: default;
  }
  
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox> .replyTextBox {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .vBar {
    width: 8px;
    height: 40px;
    margin: 0px 20px 20px 0px;
    background-color: #e6e6e7;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > textarea {
    width: calc(100% - 0px);
    padding: 12px;
    border-radius: var(--generic-border-radius);
    border: solid 1px rgba(37, 39, 52, 0.1);
    resize: vertical;
    margin-bottom: 10px;
    min-height: 120px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .gErr {
    margin-bottom: 10px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .submitRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .submitRow > .lt {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .submitRow .gButton:first-child {
    
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .submitRow .gButton > span {
    border-radius: var(--generic-border-radius);
    border: 1px solid var(--primary-text-color-light);
    color: var(--primary-text-color-light);
    padding: 5px 10px;
    font-weight: var(--font-weight-bold);
    cursor: default;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 5px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .replyTextBox > .content > .rInf {
    margin-top: 5px;
    color: var(--primary-text-color-light);
  }
  
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .vBar {
    width: 8px;
    height: 40px;
    margin: 0px 20px 20px 0px;
    background-color: #e6e6e7;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .content > .topRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .content > .topRow > .yourReply {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-primary);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--primary-text-color);
  }
  .trendAnalysis > .listBox > .singleEntry > .right > .replyBox > .repliedTextBox > .content > .topRow > .showReply {
    cursor: pointer;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-primary);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--gte-primary-color);
    margin-bottom: 10px;
  }