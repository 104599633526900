
.gBCtRw.usrRw {
  flex-direction: column!important;
  padding: 10px;
}
.gBCtRw.usrRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.usrRw > .tp {
  display: flex;
  flex-direction: row!important;
  justify-content: space-between;
  align-items: flex-start;
}
.gBCtRw.usrRw > .tp > .lt {
  display: flex;
  flex-direction: column!important;
  gap: 5px;
  flex: 1;
}
.gBCtRw.usrRw > .tp > .rt {
  display: flex;
  flex-direction: row!important;
  justify-content: flex-end;
  gap: 15px;
  width: 100px;
}
.gBCtRw.usrRw > .tp > .rt > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: var(--generic-border-radius);
}
.gBCtRw.usrRw > .tp > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.usrRw > .tp > .lt > p {
  margin-bottom: 0;
}
.gBCtRw.usrRw > .tp > .lt > p > span {
  margin-right: 5px;
}
.gBCtRw.usrRw > .tp > .lt > p.dsbleUsr {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-title);
  margin-bottom: 10px;
}
.gBCtRw.usrRw > .tp > .lt > p.usrNm {
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: 0;
}
.gBCtRw.usrRw > .tp > .lt > p.lstLgn {
  font-size: var(--font-size-sub-mid-primary);
  margin-top: 0px;
  margin-bottom: 20px;
}

.gBCtRw.usrRw > .bt {
  display: flex;
  flex-direction: row!important;
  justify-content: flex-start;
  align-items: flex-start;
}
.gBCtRw.usrRw > .bt > .lt {
  flex: 1;
}
.gBCtRw.usrRw > .bt > .lt > p.usrLocTtl {
  font-weight: var(--font-weight-bold);
  margin-top: 10px;
  margin-bottom: 5px;
}
.gBCtRw.usrRw > .bt > .lt > .usrLocNm {
  font-size: var(--font-size-sub-primary);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 6px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--generic-border-color);
}
.gBCtRw.usrRw > .bt > .lt > .usrLocNm:last-child {
  border-bottom: 0;
}
.gBCtRw.usrRw > .bt > .lt > .usrLocNm > span.name {
  max-width: 300px;
  width: 240px;
}
.gBCtRw.usrRw > .bt > .lt > .usrLocNm > span {
  margin-right: 10px;
}
.gBCtRw.usrRw > .bt > .lt > .usrLocNm > .usrLocPrds {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gBCtRw.usrRw > .bt > .lt > .usrLocNm > .usrLocPrds > span {
  margin-right: 10px;
}
.gBCtRw.usrRw.usrDsbled > .tp > .rt > span.enblDisbl {
  color: grey;
}


.gPWrp.usrSingle > .gBCtnt > .gBCtRw.byTwRw {
  gap: 10px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.byTwRw > .gRwBxLt,
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.byTwRw > .gRwBxRt {
  flex: 1;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locLbl {
  margin-bottom: 0!important;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw {
  background-color: var(--generic-bg-color);
  padding: 20px 20px 20px 20px;
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--generic-border-color);
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw.hasErr {
  margin-bottom: 5px!important;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt {
  display: flex;
  flex-direction: column;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .gLbl {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .gLbl > span {
  padding: 3px 6px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .gLbl > span:hover {

}

.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .locLvlAcBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .locLvlAcBox > .lt {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .locLvlAcBox > .lt > span {
  font-weight: var(--font-weight-bold);
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .locLvlAcBox > .lt > span.add {
  color: var(--gte-primary-color);
  cursor: default;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .locLvlAcBox > .lt > .selectDropDown {
  width: 200px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .locLvlAcBox > .rt > .remove {
  padding: 3px 6px;
  border-radius: var(--generic-border-radius);
  font-weight: var(--font-weight-bold);
  cursor: default;
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .locLvlAcBox > .rt > .remove:hover {

}
.locLvlAcBoxHelp {
  color: var(--generic-help-msg-color);
  margin-bottom: 30px;
}


.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox {

}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox.noChgAlw {
  opacity: 0.2;
  pointer-events: none;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .gLbl.add {
  color: var(--gte-primary-color);
  cursor: default;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .gLbl {
  margin-bottom: 5px!important;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .gHelpMsg {
  margin-bottom: 10px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 10px;
  border-bottom: 1px solid var(--generic-border-color);
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo:last-of-type {
  border-bottom: 0;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo.hdr {
  background-color: #EEE;
  padding: 10px;
  border-radius: var(--generic-border-radius);
  border-bottom: 0;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .lt {
  width: 120px;
  font-weight: var(--font-weight-bold);
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .md {
  width: 200px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .md > .aclvlBx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .md > .aclvlBx > span {
  margin-top: 10px;
  padding: 0px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  color: var(--gte-primary-color);
  font-weight: var(--font-weight-bold);
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .rt {
  flex: 1;
  margin-left: 20px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .rt {
  display: flex;
  flex-direction: column;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .rt > .curFetrBx {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .rt > .curFetrBx > .fetrEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #EEE;
  padding: 10px;
  gap: 10px;
  border-radius: var(--generic-border-radius);
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .rt > .addFetrBx {
  max-width: 300px;
}

.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locRw > .gRwBxLt > .prdLvlAcBox > .prdInfo > .ntActv {
  font-weight: var(--font-weight-bold);
}

.gPWrp.usrSingle > .gBCtnt > .gBCtRw.locErrRw {
  color: var(--generic-error-color);
  font-weight: var(--font-weight-bold);
}

.gPWrp.usrSingle > .gBCtnt > .gBCtRw.curFetrBx {
  flex-direction: column;
  align-items: flex-start;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.curFetrBx > .fetrEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #EEE;
  padding: 10px;
  gap: 10px;
  border-radius: var(--generic-border-radius);
  margin-bottom: 5px;
}

.gPWrp.usrSingle > .gBCtnt > .gBCtRw > .gRwBxLt > .selectDropDown.halfW {
  width: 50%;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw > .gRwBxLt > .gHelpMsg {
  margin-top: 5px;
}

.gPWrp.usrSingle > .gBCtnt > .gBCtRw.chkBxRw {
  margin-left: 30px;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.chkBxRw > .gRwBxLt {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPWrp.usrSingle > .gBCtnt > .gBCtRw.chkBxRw > .gRwBxLt > .gLbl {
  margin-bottom: 0;
  margin-left: 5px;
}
