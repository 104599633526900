.gPWrp.prfInf > .gBCtnt > .gBCtRw.btnRw.prfEdtRw {
  padding: 0;
  margin-top: 0;
}
.gPWrp.prfInf > .gBCtnt > .gBCtRw.btnRw.prfEdtSvRw {
  padding: 0;
  margin-top: 0;
}
.gPWrp.hDGen > .gBCtnt > .gBCtRw.prfEdtRw {
  gap: 20px;
}
.gPWrp.hDGen > .gBCtnt > .gBCtRw.prfEdtRw > .lt,
.gPWrp.hDGen > .gBCtnt > .gBCtRw.prfEdtRw > .rt {
  flex: 1;
}
