.gPg.revsPg > .dtFltBx > .dateFilter {
  width: auto;
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  padding: 0 10px;
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv:hover {
  background-color: var(--generic-hover-bg-color);
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv > img {
  height: 20px;
  margin-right: 5px;
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv > p {
  margin-bottom: 0;
}
.gPg > .dtFltBx > .dateFilter > .dwnldCsv > span {
  margin-left: 10px;
}
.gPg.revsPg > .rpOptions {
  margin-top: 20px;
  1height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
}
.gPg.revsPg > .rpOptions > .rpTabs {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.gPg.revsPg > .rpOptions > .rpTabs > .reviewTabButton {
  font-size: var(--font-size-primary);
  padding: 6px 12px;
  color: var(--primary-text-color-light);
  margin-right: 20px;
  margin-bottom: 0px;
  cursor: pointer;
}
.gPg.revsPg > .rpOptions > .rpTabs > .reviewTabButton.active {
  border-radius: var(--generic-border-radius);
  color: var(--gte-primary-color);
  background-color: #ecf0f8;
}
.gPg.revsPg > .rpOptions > .rpSort {
  width: 200px;
}


.gPg.revsPg > .revsBd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.gPg.revsPg > .revsBd > .lt {
  flex: 1;
  margin-right: 20px;
}
.gPg.revsPg > .revsBd > .rt {
  width: 245px;
}


.gPg.revsPg > .revsBd > .lt > .revsTrndBx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  1padding: 5px;
  /* border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow); */
  margin-bottom: 10px;
}
.gPg.revsPg > .revsBd > .lt > .revsTrndBx > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.gPg.revsPg > .revsBd > .lt > .revsTrndBx > .title > .lt > span {
  margin-right: 10px;
}
.gPg.revsPg > .revsBd > .lt > .revsTrndBx > .title > .lt > i {
  font-size: 20px;
  color: var(--gte-primary-color);
}
.gPg.revsPg > .revsBd > .lt > .revsTrndBx > .chartBox {
  margin-top: 10px;
}


.gPg.revsPg > .revsBd > .rt > .fltrPnl {
  width: 245px;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
}
@media (max-width: 1023px) {
  .gPg.revsPg > .revsBd > .rt > .fltrPnl {
    padding: 15px;
  }
}

.gPg.revsPg > .revsBd > .rt > .fltrPnl > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl> .header > p {
  color: var(--primary-text-color);
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .header > p.reset {
  color: var(--gte-primary-color);
  cursor: pointer;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .header > p.reset > span {
  margin-right: 5px;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .search {
  margin-bottom: 20px;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .search > img.label {
  position: absolute;
  margin: 12px 0 0 10px;
  cursor: pointer;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .search > input {
  width: calc(100% - 34px);
  padding: 12px 32px 12px 36px;
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(37, 39, 52, 0.1);
  resize: vertical;
  background-color: #e6e6e7;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .search > img.clear {
  position: absolute;
  margin: -29px 0px -10px 185px;
  margin: 16px 0px -10px -24px;
  cursor: pointer;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .search > span.submit {
  position: absolute;
  1margin: -43px 0px -10px 211px;
  cursor: pointer;
  background-color: var(--gte-primary-color);
  padding: 12px;
  border-top-right-radius: var(--generic-border-radius);
  border-bottom-right-radius: var(--generic-border-radius);
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .search > span.submit > i {
  color: #FFFFFF;
}

.gPg.revsPg > .revsBd > .rt > .fltrPnl > .filterBox {
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .filterBox > .title {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .filterBox > .dRows {
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl> .filterBox > .dRows > .dRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .filterBox > .dRows > .dRow > .left {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .filterBox > .dRows > .dRow > .right {
  width: 50px;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .filterBox > .dRows > .dRow > .left > input {
  margin-right: 10px;
  font-size: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .filterBox > .dRows > .dRow > .left > .label {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  cursor: pointer;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .filterBox > .dRows > .dRow > .right {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  text-align: right;
}
.gPg.revsPg > .revsBd > .rt > .fltrPnl > .lineBreak {
  width: 245px;
  height: 1px;
  margin: 20px 0;
  background-color: #e6e6e7;
}

/* main review */

.reviewCard > .innerBox > .translateRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 50px;
  margin-bottom: 20px;
}
.reviewCard > .innerBox > .translateRow > .translateIconDiv {
  margin-right: 5px;
  margin-top: -5px;
}
.reviewCard > .innerBox > .translateRow > .translateToLang {
  margin-right: 10px;
}
.reviewCard > .innerBox > .translateRow > .translateToLang > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.reviewCard > .innerBox > .translateRow > .translateIcon {
  margin-right: 10px;
  margin-left: 0px;
}
.reviewCard > .innerBox > .translateRow > .viewOriginal {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin-top: 2px;
}
/* ************** dropdown menu - language Selector ********************** */

.reviewCard > .innerBox > .translateRow > .translateIcon .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 2px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.reviewCard > .innerBox > .translateRow > .translateIcon .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.reviewCard > .innerBox > .translateRow > .translateIcon .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.reviewCard > .innerBox > .translateRow > .translateIcon .dropdown-content {
  left: 30px;
  top: 1px;
}

.reviewCard > .innerBox > .translateRow > .translateIcon .dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.reviewCard > .innerBox > .translateRow > .translateIcon .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.reviewCard > .innerBox > .translateRow > .translateIcon .dropdown.open .dropdown-content {display: block;}

.reviewCard > .innerBox > .translateRow > .translateIcon .dropbtn > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.reviewCard > .innerBox > .translateRow > .translateIcon .dropbtn > span.translatedToLang {
  text-decoration: underline;
}

/* ************************************ */

/* management response */

.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 0px;
  margin-bottom: 20px;
}
.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIconDiv {
  margin-right: 5px;
  margin-top: -5px;
}
.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateToLang {
  margin-right: 10px;
}
.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateToLang > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon {
  margin-right: 10px;
  margin-left: 0px;
}
.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .viewOriginal {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
/* ************** dropdown menu - language Selector ********************** */

.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 2px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropdown-content {
  left: 30px;
  top: 1px;
}

.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropdown.open .dropdown-content {display: block;}

.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropbtn > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.reviewCard > .innerBox > .responseBox > .responseBody > .translateRow > .translateIcon .dropbtn > span.translatedToLang {
  text-decoration: underline;
}

/* ************************************ */


/* prepared response */

.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon {
  margin-right: 10px;
  margin-left: 10px;
}

/* ************** prepared response - dropdown menu - language Selector ********************** */

.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 2px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropdown-content {
  left: 30px;
  top: 1px;
}

.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropdown.open .dropdown-content {display: block;}

.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropbtn > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.reviewCard > .innerBox > .responseBox > .responseRequiredBody > .contentTextarea > .actionIcons > .translateIcon .dropbtn > span.translatedToLang {
  text-decoration: underline;
}

/* ************ end - prepared response ************************ */

.gPg.revsPg > .dtFltBx {
  margin-top: 0;
}
.gPg.revsPg > .dtFltBx > .left > .title > a > span {
  margin-right: 10px;
}
