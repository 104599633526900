.teStgLst {
  width: 350px;
  height: 100%!important;
  border-right: 1px solid var(--generic-border-color);
  display: flex!important;
  flex-direction: column;
  position: relative!important;
  transform: none!important;
}
.teStgLst > .rszIndc {
  position: absolute;
  right: 0;
  top: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: var(--resize-icon-color);
}
.teStgLst > .rszIndc > span {
  line-height: 1;
}
.teStgLst > .hdr {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--generic-border-color);
}
.teStgLst > .stgLst {
  display: flex;
  flex-direction: column;
}
.teStgLst > .stgLst > a {
  padding: 15px 20px;
  color: var(--primary-text-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 0px solid var(--generic-border-color);
}
.teStgLst > .stgLst > a.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
.teStgLst > .stgLst > a:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.teStgLst > .stgLst > a > .lt {
  width: 30px;
}
.teStgLst > .stgLst > a > .rt > p.title {
  margin-bottom: 2px;
}
.teStgLst > .stgLst > a > .rt > p.desc {
  color: var(--primary-text-color-light);
}

.gPScrlWrp.teCnfg {
  background-color: var(--generic-bg-color);
}
.gPScrlWrp.teCnfg > .gPWrp {
  background-color: var(--generic-bg-color);
}
.gPScrlWrp.teCnfg > .gPWrp > .gBCtnt > .gBCtRw.addNewRw {
  background-color: transparent;
  font-weight: var(--font-weight-bold);
  color: var(--gte-primary-color);
  cursor: default;
}
.gPScrlWrp.teCnfg > .gPWrp > .gBCtnt > .gBCtRw.addNewRw > p {
  padding: 3px 6px;
  background-color: var(--sidebar-main-menu-active-bg);
  border-radius: var(--generic-border-radius);
}
