.gBCtRw.defChnl > .lt > p.defChnl {
  display: flex;
  justify-content: row;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
}
.gPWrp.msgChnls > .gBCtnt > .gBCtRw.hasTgl > .lt > .desc {
  font-weight: var(--font-weight-bold);
}
.gPWrp.msgChnls > .gBCtnt > .gBCtRw.hasTgl > .lt > .default {
  color: var(--gte-primary-color);
  font-weight: var(--font-weight-bold);
  margin-top: 5px;
  cursor: default;
}
.gPWrp.msgChnls > .gBCtnt > .gBCtRw.hasTgl > .lt > .default.active {
  color: var(--primary-text-color-light);
}

.gPWrp.hDGen > .gBCtnt > .gBCtRw.hasTgl.sms.noMrgBtm {
  margin-bottom: 0;
}
.gPWrp.hDGen > .gBCtnt > .gBCtRw.emailSmsOpts {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.gPWrp > .gBCtnt > .gBCtRw.emailSmsOpts > .gRwBxLt > .gLbl > label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPWrp > .gBCtnt > .gBCtRw.emailSmsOpts > .gRwBxLt > .gLbl > label > input {
  margin-right: 5px;
}

.gPWrp.msgChnls > .gBCtnt > .gBCtRw.actv {
  justify-content: space-between;
}
.gPWrp.msgChnls > .gBCtnt > .gBCtRw.actv > .lt > .actv,
.gPWrp.msgChnls > .gBCtnt > .gBCtRw.actv > .rt > .actv {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  color: var(--gte-primary-color);
  font-weight: var(--font-weight-bold);
  margin-top: 5px;
  cursor: default;
}

.gPWrp > .gBCtnt > .gBCtRw > .lt > .title > span.whatsappKnwMor {
  margin-left: 10px;
  color: var(--gte-primary-color);
  cursor: default;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sub-primary);
}