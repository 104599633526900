


/* start - translate row */
.sentMentBx > .gBCtnt > .innerBox > .headerRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 0 0px;
  margin-bottom: 20px;
}
.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIconDiv {
  margin-right: 5px;
  margin-top: -5px;
}
.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateToLang {
  margin-right: 10px;
}
.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateToLang > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon {
  margin-right: 10px;
  margin-left: 0px;
}
.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .viewOriginal {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
/* ************** dropdown menu - language Selector ********************** */

.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 2px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropdown-content {
  left: -130px;
  top: 30px;
}

.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropdown.open .dropdown-content {display: block;}

.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropbtn > span {
  cursor: pointer;
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.sentMentBx > .gBCtnt > .innerBox > .headerRow > .translateRow > .translateIcon .dropbtn > span.translatedToLang {
  text-decoration: underline;
}

/* *************** start - translate row ********************* */

.gPg.sentPg > .dtFltBx {
  margin-top: 0;
}
.gPg.sentPg > .dtFltBx > .left > .title > a > span {
  margin-right: 10px;
}
