.compChartWrap {
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.compChartWrap.noShadow {
  box-shadow: none;
}
.compChartWrap > .topRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px;
}

.compChartWrap > .topRow > .chartInfoBox {
  display: flex;
  flex-direction: column;
  color: var(--primary-text-color);
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: var(--font-weight-bold);
  letter-spacing: normal;
  line-height: 1.43;
}
.compChartWrap > .topRow > .chartInfoBox > .rtng {
  margin-bottom: 10px;
}
.compChartWrap > .topRow > .chartInfoBox > .locNm {
  color: var(--gte-primary-color);
  cursor: default;
}

.compChartWrap > .topRow > .chartTypeBox,
.compChartWrap > .topRow > .timePeriodBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.compChartWrap > .topRow > .chartTypeBox > .entry {
  padding: 10px;
  cursor: pointer;
}
.compChartWrap > .topRow > .timePeriodBox > .entry {
  padding: 6px;
  cursor: pointer;
  background-color: rgba(245, 246, 250, 0.4);
  border: solid 1px rgba(245, 246, 250, 0.4);
  border-radius: 5px;
}
.compChartWrap > .topRow > .timePeriodBox > .entry.active {
  background-color: #FFFFFF;
  border: solid 1px #0d43aa;
}
.compChartWrap > .topRow > .chartTypeBox > .entry > span {
  color: #838e9f;
  font-weight: bold;
}
.compChartWrap > .topRow > .chartTypeBox > .entry.active > span {
  color: #0d43aa;
  text-decoration: underline;
}
.compChartWrap > .topRow > .locBox > .entry {
  padding: 0px;
  cursor: default;
  font-weight: 600;
}
.compChartWrap > .topRow > .locBox > .entry.active > span {
  text-decoration: underline;
}
.compChartWrap > .topRow > .locBox > .entry.list1 {
  color: var(--comp-color-0);
}
.compChartWrap > .topRow > .locBox > .entry.list2 {
  color: var(--comp-color-1);
}
.compChartWrap > .topRow > .locBox > .entry.list3 {
  color: var(--comp-color-2);
}
.compChartWrap > .topRow > .locBox > .entry.list4 {
  color: var(--comp-color-3);
}
.compChartWrap > .topRow > .locBox > .entry.list5 {
  color: var(--comp-color-4);
}



.compChartWrap > .bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 10px;
}
.compChartWrap > .bottomRow > .timePeriodBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.compChartWrap > .bottomRow > .timePeriodBox > .entry {
  padding: 6px;
  cursor: pointer;
  background-color: rgba(245, 246, 250, 0.4);
  border: solid 1px rgba(245, 246, 250, 0.4);
  border-radius: 5px;
  margin-right: 2px;
}
.compChartWrap > .bottomRow > .timePeriodBox > .entry.active {
  background-color: #FFFFFF;
  border: solid 1px #0d43aa;
  cursor: default;
}
.compChartWrap > .bottomRow > .timePeriodBox > .entry > span {
  font-size: 12px;
}


.compChartWrap #barChart > rect {
  fill: #52ced5;
}
.compChartWrap #lineChart > circle {
  fill: #0d43aa;
}
.compChartWrap #lineChart > .dotText {
  font-weight: bold;
  fill: #0d43aa;
}

div.lineChartCompTooltip {
    position: absolute;
    text-align: left;
    padding: 5px 10px;
    font: 14px;
    background: lightsteelblue;
    background-color: #FFFFFF;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
    box-shadow: var(--generic-card-shadow);
    font-weight: bold;
}
.lineChartCompTooltip > .rw {
  display: flex;
  flex-direction: row;
}
.lineChartCompTooltip > .rw.prmy {
  color: var(--gte-primary-color);
}
.lineChartCompTooltip > .rw.list0 {
  color: var(--comp-color-0);
}
.lineChartCompTooltip > .rw.list1 {
  color: var(--comp-color-1);
}
.lineChartCompTooltip > .rw.list2 {
  color: var(--comp-color-2);
}
.lineChartCompTooltip > .rw.list3 {
  color: var(--comp-color-3);
}
.lineChartCompTooltip > .rw.list4 {
  color: var(--comp-color-4);
}
.lineChartCompTooltip > .rw > span.rtng {
  min-width: 40px;
  
}

.compChartWrap .line {
  fill: none;
  stroke: #0d43aa;
  stroke-width: 2px;
}
.compChartWrap .line.prmry {
  stroke: #0d43aa;
}
.compChartWrap .line.hglt {
  /* stroke: orange; */
  stroke-width: 5px!important;
} 

.compChartWrap .line.list0 {
  stroke: var(--comp-color-0);
}
.compChartWrap .line.list1 {
  stroke: var(--comp-color-1);
}
.compChartWrap .line.list2 {
  stroke: var(--comp-color-2);
}
.compChartWrap .line.list3 {
  stroke: var(--comp-color-3);
}
.compChartWrap .line.list4 {
  stroke: var(--comp-color-4);
}


.compChartWrap .y.axis_right > path {
    color: #0d43aa;
    stroke-width: 2px;
}

.compChartWrap #lineChart > path {
    color: #0d43aa;
    stroke-width: 2px;
}

#loader_container {
  position: absolute;
  background-color: #d4cdcd2e;
}
#loader_container.hide {
  display: none;
}

.chartLoader {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: #0000003b;
  background-color: #0000001a;
}
