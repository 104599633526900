/* ******************* Modal ******************* */
#modal-send-test-email {
  position: relative;
  z-index: 9999;
}

.sendTestEmailModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sendTestEmailModal > .contentWrap {
  max-width: 500px;
  width: 80%;
  max-height: 400px;;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
  1overflow-y: scroll;
  1overflow-x: hidden;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}


.sendTestEmailModal > .contentWrap > .content {
  padding: 30px;
}
.sendTestEmailModal > .contentWrap > .content > .title {
  font-size: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sendTestEmailModal > .contentWrap > .content > .title > span.close {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  font-size: 14px;
  padding: 6px 6px;
  border-radius: var(--generic-border-radius);
}
.sendTestEmailModal > .contentWrap > .content > .title > span.close > img {
  margin-right: 5px;
}
.sendTestEmailModal > .contentWrap > .content > .title > span.close:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.sendTestEmailModal > .contentWrap > .content > .body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sendTestEmailModal > .contentWrap > .content > .body > .rowOne {
  font-size: 16px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.sendTestEmailModal > .contentWrap > .content > .body > .rowNote {
  font-size: 16px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  background-color: var(--alert-note-bg-color);
  padding: 12px;
  border-radius: var(--generic-border-radius);
  color: var(--alert-note-text-color);
}
.sendTestEmailModal > .contentWrap > .content > .body > .emailInput {
  width: 100%;
}
.sendTestEmailModal > .contentWrap > .content > .body > .emailInput > input {
  border: 1px solid var(--generic-border-color);
  border-radius: var(--generic-border-radius);
  padding: 10px;
  width: calc(100% - 20px);
}
.sendTestEmailModal > .contentWrap > .content > .body > .emailInputError {
  color: var(--generic-error-color);
}
.sendTestEmailModal > .contentWrap > .content > .body > .currentStatus {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 30px;
  text-align: center;
}
.sendTestEmailModal > .contentWrap > .content > .body > .actions {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
  width: 100%;
}
.sendTestEmailModal > .contentWrap > .content > .body > .actions > button {
  padding: 8px 32px;
  border-radius: var(--generic-border-radius);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
}
.sendTestEmailModal > .contentWrap > .content > .body > .actions > button.cancel {
  border: 1px solid #DDD;
  background-color: #FFFFFF;
  margin-right: 16px;
}
.sendTestEmailModal > .contentWrap > .content > .body > .actions > button.ok {
  border: 1px solid var(--form-error-color);
  background-color: var(--form-error-color);
  color: #FFFFFF;
}
.sendTestEmailModal > .contentWrap > .content > .body > .actions > button.ok > .fa {
  margin-left: 10px;
}
.sendTestEmailModal > .contentWrap > .content > .body > .actions > button.ok.secondary {
  margin-left: 16px;
}
