.sdMdl.newMessage > .ctnt > .gPWrp > .bc > .bcRow.contactRow > .bxLt {
  width: 100%;
  margin-bottom: 13px;
}
.bc > .bcRow.contactRow > .bxLt > .sendToNewNumber {
  position: absolute;
  right: 70px;
  top: 30px;
  background-color: var(--gte-primary-color);
  padding: 6px 12px;
  color: #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bc > .bcRow.contactRow > .bxLt > .sendToNewNumber > i {
  margin-left: 10px;
  font-size: 14px;
}

.sdMdl.newMessage > .ctnt > .gPWrp > .bc > .bcRow.staffRow > .bxLt {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.sdMdl.newMessage > .ctnt > .gPWrp > .bc > .bcRow.staffRow > .bxLt > .entry {
  border: 1px solid var(--generic-border-color);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.sdMdl.newMessage > .ctnt > .gPWrp > .bc > .bcRow.staffRow > .bxLt > .entry:hover {
  background-color: var(--sidebar-main-menu-active-bg);
  cursor: default;
}
.sdMdl.newMessage > .ctnt > .gPWrp > .bc > .bcRow.staffRow > .bxLt > .entry > .name {
  font-weight: var(--font-weight-bold);
}

.sdMdl.newMessage > .ctnt > .gPWrp > .bc > .bcRow.contactRow > .bxLt > .gErr {
  position: absolute;
}
.sdMdl.newMessage > .ctnt > .gPWrp > .bc > .srchRWrp {
  margin-top: -27px;
}
.sdMdl.newMessage > .ctnt > .gPWrp > .bc > .srchRCls {
  margin-top: -38px;
}
