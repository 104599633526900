.teSngl {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--generic-border-color);
}
.teSngl > .teRw {
  margin: 0 0 20px 0;
}
.teSngl > .teRw.hdr {
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-semi-bold);
}
.teSngl > .teRw.hasTgl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.teSngl > .teRw.hasTgl > .lt {
  font-size: var(--font-size-primary);
  color: var(--primary-text-color);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.teSngl > .teRw.hasTgl > .lt > .title {
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
}
.teSngl > .teRw.hasTgl > .lt > .desc {
  color: var(--primary-text-color-light);
  margin-bottom: 20px;
}
.teSngl > .teRw.hasTgl > .lt > .kVGnrc {
  color: var(--primary-text-color-light);
  line-height: 1.53;
  margin-bottom: 2px;
}
.teSngl > .teRw.hasTgl > .lt > .kVGnrc > span {
  color: var(--primary-text-color);
}

.teSngl > .teRw.hasTgl > .rt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.teSngl > .teRw.cstmTypVal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.teSngl > .teRw.cstmTypVal > .lt,
.teSngl > .teRw.cstmTypVal > .rt {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.teSngl > .teRw.cstmTypVal > .rt > .gLbl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.teSngl > .teRw.tplNmRw {
  margin-bottom: 40px;
}
.teSngl > .teRw.tplNmRw > .lt > .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.teSngl > .teRw.tplNmRw > .lt > .title > .etNm {
  color: var(--primary-text-color-light);
}
.teSngl > .teRw.tplNmRw > .lt > .title > .etNm > span {
  color: var(--primary-text-color);
}
.teSngl > .teRw.tplNmRw > .lt > .title > .etEdit {
  background-color: var(--sidebar-main-menu-active-bg);
  border-radius: var(--generic-border-radius);
  padding: 3px 6px;
  cursor: default;
}

.teSngl > .teRw.emValRw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 50px;
}
.teSngl > .teRw.emValRw > .lt {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.teSngl > .teRw.emValRw > .lt > .gIpt > input {
  padding-right: 40px;
}
.teSngl > .teRw.emValRw > .lt > .emojiIconBox {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: -30px;
  margin-right: 10px;
  height: 0;
}
.teSngl > .teRw.emValRw > .lt > .emojiIconBox.adjTp {
  margin-top: -49px;
}
.teSngl > .teRw.emValRw > .lt > .emojiBox {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.teSngl > .teRw.emPrsnlRw {
  margin-bottom: 40px;
}
.teSngl > .teRw.emPrsnlRw > .lt {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.teSngl > .teRw.emPrsnlRw > .lt > .gSLbl {
  font-weight: normal;
  color: var(--primary-text-color);
  font-size: var(--font-size-sub-mid-primary);
}
.teSngl > .teRw.emPrsnlRw > .lt > .emPrsnlSel {
  width: 300px;
}
.teSngl > .teRw.emPrsnlRw > .lt > .gHelpMsg {
  margin-top: 5px;
  font-size: var(--font-size-sub-mid-primary);
}

.teSngl > .teRw.sndBfrAft > .lt {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.teSngl > .teRw.btnRw {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
