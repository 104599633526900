


.gPWrp.genLocLst > .gBCtnt > .gBCtRw.srchRow > .gRwBxLt {
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(79, 89, 105, 0.2);
  background-color: rgba(242, 242, 245, 0.4);
  padding: 10px;
  width: 100%;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.srchRow > .gRwBxLt > .gIpt > img {
  height: 20px;
  margin-right: 5px;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.srchRow > .gRwBxLt > .gIpt> input {
  width: calc(100% - 26px);
  border: 0;
  background-color: inherit;
}

.gGenericListCard > .middle {

}


.gGenericListCard > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gGenericListCard > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
.gGenericListCard > .footer > a {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}



.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow {
  padding: 20px;
  border-bottom: 1px solid #e6e6e7;
  display: flex;
  flex-direction: row;
  width: 100%;
}


.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .left {
  width: 200px;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .left > img {
  width: 100%;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .right > .name {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0 0 5px 0;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow> .right > .address {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .right > .bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .right > .bottom > .left {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .right > .bottom > .left > span {
  color: var(--primary-text-color);
  font-size: 18px;
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .right > .bottom > .right {
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .right > .bottom > .right > a {
  color: var(--gte-primary-color);
}
.gPWrp.genLocLst > .gBCtnt > .gBCtRw.locRow > .right > .bottom > .right > a > span > i {
  color: var(--gte-primary-color);
  margin-left: 5px;
}
