.gPWrp.lblSingle > .gBCtnt > .gBCtRw.titleRow > .gRwBxLt > .gLbl > span.addOn {
  color: var(--primary-text-color-light);
  margin-left: 5px;
}
.gBCtRw.sndrEmRw {
  flex-direction: row!important;
  justify-content: space-between;
  padding: 10px;
}
.gBCtRw.sndrEmRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.sndrEmRw > .lt {
  display: flex;
  flex-direction: row!important;
  gap: 5px;
}
.gBCtRw.sndrEmRw > .lt > .clrBx {
  margin-right: 10px;
  height: 18px;
  width: 18px;
  border-radius: 2px;
}
.gBCtRw.sndrEmRw > .rt {
  display: flex;
  flex-direction: row!important;
  align-items: center;
  gap: 10px;
}
.gBCtRw.sndrEmRw > .rt > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 4px;
  border-radius: var(--generic-border-radius);
}
.gBCtRw.sndrEmRw > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
  background-color: #FFFFFF;
}
.gBCtRw.sndrEmRw > .rt > span.verify {
  padding: 4px 8px;
}
.gBCtRw.sndrEmRw > .rt > span.refresh {
  padding: 0;
}
