.gPScrlWrp.rtCds > .gPWrp > .gPSubTle {
  padding-bottom: 10px;
}
.gPScrlWrp.rtCds > .gPWrp > .gPSubTle > span {
  font-weight: var(--font-weight-bold);
  margin-left: 5px;
}
.gBCtRw.rateCdRw {
  flex-direction: column!important;
  justify-content: space-between;
  padding: 10px;
}
.gBCtRw.rateCdRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.rateCdRw > .tp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.gBCtRw.rateCdRw > .tp > .lt {
  display: flex;
  flex-direction: row!important;
  gap: 5px;
  font-weight: var(--font-weight-bold);
}
.gBCtRw.rateCdRw > .tp > .rt {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.gBCtRw.rateCdRw > .tp > .rt > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: var(--generic-border-radius);
}
.gBCtRw.rateCdRw > .tp > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
  background-color: #FFFFFF;
}
.gBCtRw.rateCdRw > .bt {
  color: var(--primary-text-color-light);
  font-weight: var(--font-weight-bold);
}
