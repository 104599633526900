.gPnl {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  height: calc(100%);
}
.gPnl > .giB {
  display: flex;
  flex-direction: column;
  height: 61px;
}
.gPnl > .giB.split {
  height: 121px;
}
.gPnl > .giB > .pri {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--generic-border-color);
}
.gPnl > .giB > .pri > .lt {
  display: flex;
  flex-direction: row;
}
.gPnl > .giB > .pri > .lt > .avt {
  margin-right: 10px;
  padding: 6px 12px;
  height: 40px;
  width: 40px;
  border: 1px solid var(--generic-border-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color-light);
}
.gPnl > .giB > .pri > .lt > .nm {
  margin-right: 20px;
  min-width: 125px;
  max-width: 30%;
}
.gPnl > .giB > .pri > .lt > .nm.extraWidth {
  min-width: 240px;
}
.gPnl > .giB > .pri > .lt > .nm > .tp {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 90%;
  min-height: 22px;
}
.gPnl > .giB > .pri > .lt > .nm > .tp > .nwNmBx {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.gPnl > .giB > .pri > .lt > .nm > .tp > .nwNmBx > input {
  border: var(--gt-input-border);
  border-radius: var(--gt-form-input-border-radius);
  padding: 0 5px;
}
.gPnl > .giB > .pri > .lt > .nm > .tp > .adNm {
  cursor: default;
}
.gPnl > .giB > .pri > .lt > .nm > .tp > .adNm.udrln {
  text-decoration: underline;
}
.gPnl > .giB > .pri > .lt > .nm > .tp > i {
  font-size: 12px;
  margin-left: 5px;
  color: var(--primary-text-color-light);
}

.gPnl > .giB > .pri > .lt > .stI > .itm.rm > .tp {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  max-width: 180px;
  overflow: hidden;
}

.gPnl > .giB > .pri > .lt > .stI,
.gPnl > .giB > .sec > .stI {
  display: flex;
  flex-direction: row;
}
.gPnl > .giB > .pri > .lt > .stI > .itm,
.gPnl > .giB > .sec > .stI > .itm {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  font-weight: var(--font-weight-bold);
}
.gPnl > .giB > .pri > .lt > .stI > .itm.dt {
  min-width: 115px;
}
.gPnl > .giB > .sec > .stI {
  flex: 1;
  justify-content: space-between;
}

.giB .tp {
  color: var(--primary-text-color);
}
.giB .bt {
  color: var(--primary-text-color-light);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sub-mid-primary);
}


.gPnl > .giB > .sec {
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--generic-border-color);
}


.gPnl > .giB > .pri > .rt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.gPnl > .giB > .pri > .rt > .enB {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.gPnl > .giB > .pri .ciB {
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2px;
}
.gPnl > .giB > .pri .ciB.wT {
  width: 140px;
}
.gPnl > .giB > .pri .ciB > span {
  background-color: var(--gte-primary-color);
  padding: 6px 8px;
  border-radius: 5px;
  color: #FFFFFF;
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPnl > .giB > .pri .ciB > span:hover {
  opacity: 0.9;
}
.gPnl > .giB > .pri .ciB > span > img {
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
  margin-right: 5px;
  color: #FFFFFF;
}
.gPnl > .giB > .pri .coB {
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px;
}
.gPnl > .giB > .pri .coB > span {
  border-radius: 3px;
  padding: 5px 7px;
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
}
.gPnl > .giB > .pri .coB > span:hover {
  opacity: 0.9;
}
.gPnl > .giB > .pri .infB {
}
.gPnl > .giB > .pri .infB > span {
  border-radius: 3px;
  padding: 5px 7px;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gPnl > .giB > .pri .infB > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPnl > .giB > .pri .infB > span > img {
  height: 16px;
}


.gPnl > .bc {
  display: flex;
  flex-direction: row;
  height: calc(100% - 61px);
}
.gPnl > .bc.split {
  height: calc(100% - 121px);
}
.gPnl > .bc > .conv {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 200px);
}
.gPnl > .bc > .gPr {
  p1osition: absolute;
  r1ight: 300px;
  width: 0px;
  overflow-x: hidden;
  height: 100%;
  border-left: 1px solid var(--generic-border-color);
  transition: all 300ms linear;
  opacity: 0;
}
.gPnl > .bc > .gPr.show {
  width: 200px;
  opacity: 1;
}
.gPnl > .bc > .gPr > .inrB {
  display: none;
}
.gPnl > .bc > .gPr.show > .inrB {
  display: block;
}


.gPnl > .bc > .conv > .lbB {
  padding: 10px;
  height: 50px;
  border-bottom: 1px solid var(--generic-border-color);
}
.gPnl > .bc > .conv > .gcB {
  flex: 1;
}
.gPnl > .bc > .conv > .sugRspBox {
  padding: 0;
  height: 30px;
  border-top: 0px solid var(--generic-border-color);
}
.gPnl > .bc > .conv > .smB {
  border-top: 1px solid var(--generic-border-color);
  height: 200px;
}


.gPnl > .bc > .conv > .sugRspBox {
  display: flex;
  flex-direction: column;
}
.gPnl > .bc > .conv > .sugRspBox.hasResp {
  height: 120px;
}
.gPnl > .bc > .conv > .sugRspBox > .tpRw {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30px;
}
.gPnl > .bc > .conv > .sugRspBox > .tpRw > .lblWr {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-sub-mid-primary);
  font-weight: var(--font-weight-bold);
  background-color: var(--generic-border-color);
  padding: 6px 10px;
  border-top-left-radius: var(--generic-border-radius);
  border-top-right-radius: var(--generic-border-radius);
}
.gPnl > .bc > .conv > .sugRspBox > .tpRw > .lblWr > span.lbl {
  cursor: default;
}
.gPnl > .bc > .conv > .sugRspBox > .tpRw > .lblWr > i {
  margin-left: 5px;
}
.gPnl > .bc > .conv > .sugRspBox > .tpRw > .lblWr > span > img {
  margin-left: 10px;
}
.gPnl > .bc > .conv > .sugRspBox > .tpRw > .clsBx {
  margin-left: 1px;
  background-color: var(--generic-border-color);
  padding: 6px 10px;
  border-top-left-radius: var(--generic-border-radius);
  border-top-right-radius: var(--generic-border-radius);
}
.gPnl > .bc > .conv > .sugRspBox > .respRw {
  max-height: 70px;
  overflow-y: scroll;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--generic-border-color);
}
.gPnl > .bc > .conv > .sugRspBox > .respRw::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .sugRspBox > .respRw::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .sugRspBox > .respRw::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.gPnl > .bc > .conv > .sugRspBox > .respRw > .lt {
  flex: 1;
}
.gPnl > .bc > .conv > .sugRspBox > .respRw > .rt {
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gPnl > .bc > .conv > .sugRspBox > .respRw > .rt > span {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 4px 10px;
  border-radius: var(--generic-border-radius);
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPnl > .bc > .conv > .sugRspBox > .respRw > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

/* ************** guest email / notes - dropdown menu ********************** */

.enB > .dd {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}
.enB > .dd > .dbtn {
  background-color: #FFFFFF;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.enB > .dd > .dbtn > span {
  border-radius: 3px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
}
.enB > .dd > .dbtn > span.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
.enB > .dd > .dbtn > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.enB > .dd > .ddc {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  left: -210px;
  top: 40px;
  padding: 10px;
}
.enB > .dd.eb > .ddc {
  left: -190px;
}
.enB > .dd > .ddc > .rOne,
.enB > .dd > .ddc > .rTwo {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.enB > .dd > .ddc > .rOne {
  margin-bottom: 10px;
}
.enB > .dd > .ddc > .rTwo > input,
.enB > .dd > .ddc > .rTwo > textarea {
  padding: 5px;
}
.enB > .dd > .ddc > .rOne > span,
.enB > .dd > .ddc > .rTwo > span {
  margin-left: 15px;
  padding: 3px 6px;
  border-radius: var(--generic-border-radius);
}
.enB > .dd > .ddc > .rOne > span:hover,
.enB > .dd > .ddc > .rTwo > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.enB > .dd > .ddc > .rTwo {
  align-items: center;
}
.enB > .dd > .ddc > .rTwo > span > i.fa-spin {
  font-size: 12px;
}
.enB > .dd > .ddc > .rTwo > .left.error > input {
  border: 1px solid var(--generic-error-color);
}
.enB > .dd > .ddc > .rTwo > .left > p.error {
  margin-top: 4px;
  font-size: 12px;
}
.enB > .dd > .ddc a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.enB > .dd > .ddc a:hover {background-color: #ddd;}
.enB > .dd.open .ddc {display: block;}


/* ************************************ */

/* ************** guest menu - dropdown menu ********************** */

.dd.mnB {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}
.dd.mnB > .dbtn {
  padding: 0px 0px;
  background-color: #FFFFFF;
  cursor: default;
}
.dd.mnB  > .dbtn > span {
  border-radius: 3px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dd.mnB  > .dbtn > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.dd.mnB > .ddc {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dd.mnB > .ddc {
  left: -175px;
  top: 40px;
}

.dd.mnB > .ddc a,
.dd.mnB > .ddc p {
  color: black;
  padding: 16px 16px;
  text-decoration: none;
  display: block;
  cursor: default;
  margin-bottom: 0;
  border-bottom: 1px solid var(--generic-border-color);
}

.dd.mnB > .ddc a:hover,
.dd.mnB > .ddc p:hover {
  background-color: var(--generic-hover-bg-color);
}

.dd.mnB.open .ddc {display: block;}

.dd.mnB > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);

}

/* ************************************ */

/* ************* guest labels ************** */

.gPnl > .bc > .conv > .lbB {
  height: 50px;
  width: calc(100% - 20px);
  padding: 12px 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--generic-border-color);
}
.gPnl > .bc > .conv > .lbB > .labelsBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 50px;
  margin-top: -8px;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag {
  margin-right: 10px;
  border-bottom: 1px solid var(--generic-border-color);
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag > input {
  width: 110px;
  margin-right: 10px;
}

.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag > .searchResultWrap {
  position: absolute;
  max-height: 320px;
  overflow-y: scroll;
  width: 160px;
  background-color: #F6F7FB;
  z-index: 1;
  1border: 1px solid #DDD;
  margin-top: 30px;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag > .searchResultWrap::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag > .searchResultWrap::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag > .searchResultWrap::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag > .searchResultWrap > .resultRow {
  padding: 10px 10px;
  border-bottom: 1px solid #DDD;
  cursor: pointer;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag > .searchResultWrap > .resultRow:hover {
  background-color: #EEE;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .addTag > .searchResultWrap > .resultRow:last-child {
  border-bottom: 0;
}

.gPnl > .bc > .conv > .lbB > .labelsBox > .tagList {
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  overflow-x: auto; */
  width: 100%;
  height: 36px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 400px;
}
@media (min-width: 768px) {
  .gPnl > .bc > .conv > .lbB > .labelsBox > .tagList {
    max-width: 260px;
  }
}
@media (min-width: 1024px) {
  .gPnl > .bc > .conv > .lbB > .labelsBox > .tagList {
    max-width: 350px;
  }
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .tagList::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .tagList::-webkit-scrollbar
{
  height: 6px;
	width: 5px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .tagList::-webkit-scrollbar-thumb
{
  outline: 2px solid slategrey;
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .tagList > .singleTag {
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  background-color: #E6E6E7;
  color: var(--primary-text-color);
  1border: 1px solid var(--generic-border-color);
  margin-right: 10px;
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .tagList > .singleTag > span {
  margin-left: 10px;
  cursor: pointer;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .tagList > .singleTag > span > .fa {
  color: var(--primary-text-color);
}


.gPnl > .bc > .conv > .lbB > .labelsDisplayBox {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: scroll;
}
.gPnl > .bc > .conv > .lbB > .labelsDisplayBox > .left {
  width: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-text-color-light);
}
.gPnl > .bc > .conv > .lbB > .labelsDisplayBox > .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.gPnl > .bc > .conv > .lbB > .labelsDisplayBox > .right > .entry {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px 4px;
  b1ackground-color: #c8d3fc;
  border-radius: var(--generic-border-radius);
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
}
.gPnl > .bc > .conv > .lbB > .labelsDisplayBox::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .lbB > .labelsDisplayBox::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .lbB > .labelsDisplayBox::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

/* ************* guest labels ************** */

/* ************ Labels Menu dropdown *************** */

.gPnl > .bc > .conv > .lbB > .labelsBox > .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 10px;
  font-size: 16px;
  border: none;
}

.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 4px;
}

.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content {
  left: -1px;
}

.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels {
  max-height: 230px;
  overflow-y: scroll;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels > .entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  cursor: default;
  color: var(--primary-text-color-light);
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels > .entry > .left {
  display: flex;
  flex-direction: row;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels > .entry > .left > .colorBox {
  margin-right: 10px;
  height: 18px;
  width: 18px;
  border-radius: 2px;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels > .entry > input {
  margin-left: 10px;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .applyLabels {
  padding: 12px 16px;
  background-color: #f5f6f7;
  color: var(--primary-text-color-light);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .applyLabels.active {
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
  cursor: default;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .applyLabels > i {
  margin-left: 5px;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .addLabel {
  padding: 12px 16px;
  border-bottom: 1px solid var(--generic-border-color);
  cursor: default;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .addLabel:hover {
  background-color: var(--generic-hover-bg-color);
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .manageLabel {
  padding: 12px 16px;
  cursor: default;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .manageLabel:hover {
  background-color: var(--generic-hover-bg-color);
}

.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown > .dropdown-content > .chooseLabels > .entry:hover {background-color: var(--generic-hover-bg-color);}

.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown.menuBox.open .dropdown-content {display: block;}

.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown.menuBox > .dropbtn {
  padding: 5px 6px;
  background-color: #FFFFFF;
  cursor: default;
  1border-radius: 20px 16px;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown.menuBox > .dropbtn > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-text-color-light);
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown.menuBox > .dropbtn > span > i {
  margin-left: 5px;
}
.gPnl > .bc > .conv > .lbB > .labelsBox > .dropdown.menuBox > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);
  border-radius: var(--generic-border-radius);

}

/* ************ Labels Menu dropdown *************** */
