.gIFrame {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gIFrame > .closeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #e6e6e7;
  height: 60px;
  padding: 10px;
}
.gIFrame > .closeRow > .close {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 3px 6px;
  border-radius: var(--generic-border-radius);
  cursor: default;
}
.gIFrame > .closeRow > .close:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gIFrame > .closeRow > .close > img {
  margin-right: 10px;
}
.gIFrame > .closeRow > .getStarted {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gIFrame > .closeRow > .getStarted > .gBtn {
  font-weight: var(--font-weight-bold);
}

.gIFrame > .top {
  margin-bottom: 5px;
  height: 60px;
}
.gIFrame > .top > p {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gIFrame > .top > p > i {
  margin-left: 10px;
}
.gIFrame > .middle {
  flex: 1;
  margin-left: 20px;
}
.gIFrame > .middle > .pageLoader {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gIFrame > .middle > iframe {
  padding: 0px;
}
.gIFrame > .middle > iframe > body {

}
.gIFrame > .middle > iframe body ::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gIFrame > .middle > iframe body ::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gIFrame > .middle > iframe body ::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.gIFrame > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gIFrame > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
.gIFrame > .footer > a {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
