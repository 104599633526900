.gbmChip {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: solid 1px rgba(37, 39, 52, 0.1);
  padding: 20px;
  border-radius: var(--generic-border-radius);
}
.gbmChip > .dataRow {
  display: flex;
  flex-direction: column;
}
.gbmChip > .dataRow > .top {
  margin-bottom: 5px;
}
.gbmChip > .dataRow > .top > p {
  margin: 0;
  font-size: 14px;
  color: var(--primary-text-color-light);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.gbmChip > .dataRow > .top > p > i {
  margin-left: 5px;
  font-size: 12px;
}
.gbmChip > .dataRow > .bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gbmChip > .dataRow > .bottom > .checkbox {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
.gbmChip > .dataRow > .bottom > .gIpt {
  width: 100%;
}
.gbmChip > .dataRow > .bottom > .gIpt > .input {
  width: 100%;
  b1order: var(--gt-form-input-border);
  b1order-radius: var(--gt-form-input-border-radius);
  padding: 9px 12px 8px 12px;
  margin-bottom: 1px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
}

.gbmChip > .dataRow > .error {
  margin-left: 30px;
}
.gbmChip > .dataRow.shortTitleRow > .error {
  margin-left: 0px;
}

.gbmChip > .dataRow.titleRow {
  margin-bottom: 10px;
}
.gbmChip > .dataRow.shortTitleRow {
  margin-left: 30px;
  margin-bottom: 10px;
}
.gbmChip > .dataRow.messageRow {
  margin-left: 30px;
}

.gbmChip > .dataRow.removeRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
}
.gbmChip > .dataRow.removeRow > .gButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 3px 6px;
}
.gbmChip > .dataRow.removeRow > .gButton:hover {
  background-color: var(--sidebar-main-menu-active-bg);
  border-radius: var(--generic-border-radius);
}
.gbmChip > .dataRow.removeRow > .gButton > img {
  margin-left: 5px;
}
