.barChartWrap {
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.barChartWrap.noShadow {
  box-shadow: none;
}
.barChartWrap > .topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px;
}
.barChartWrap > .topRow > .chartTypeBox,
.barChartWrap > .topRow > .timePeriodBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.barChartWrap > .topRow > .chartTypeBox > .entry {
  padding: 10px;
  cursor: pointer;
}
.barChartWrap > .topRow > .timePeriodBox > .entry {
  padding: 6px;
  cursor: pointer;
  background-color: rgba(245, 246, 250, 0.4);
  border: solid 1px rgba(245, 246, 250, 0.4);
  border-radius: 5px;
}
.barChartWrap > .topRow > .timePeriodBox > .entry.active {
  background-color: #FFFFFF;
  border: solid 1px #0d43aa;
}
.barChartWrap > .topRow > .chartTypeBox > .entry > span {
  color: #838e9f;
  font-weight: bold;
}
.barChartWrap > .topRow > .chartTypeBox > .entry.active > span {
  color: #0d43aa;
  text-decoration: underline;
}


.barChartWrap > .bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-top: 10px;
}
.barChartWrap > .bottomRow > .timePeriodBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.barChartWrap > .bottomRow > .timePeriodBox > .entry {
  padding: 6px;
  cursor: pointer;
  background-color: rgba(245, 246, 250, 0.4);
  border: solid 1px rgba(245, 246, 250, 0.4);
  border-radius: 5px;
  margin-right: 2px;
}
.barChartWrap > .bottomRow > .timePeriodBox > .entry.active {
  background-color: #FFFFFF;
  border: solid 1px #0d43aa;
  cursor: default;
}
.barChartWrap > .bottomRow > .timePeriodBox > .entry > span {
  font-size: 12px;
}


.barChartWrap #barChart > rect {
  fill: #52ced5;
}
.barChartWrap #lineChart > circle {
  fill: #0d43aa;
}
.barChartWrap #lineChart > .dotText {
  font-weight: bold;
  fill: #0d43aa;
}

div.barChartTooltip {
    position: absolute;
    text-align: center;
    width: 120px;
    h1eight: 40px;
    padding: 2px;
    font: 14px;
    background: lightsteelblue;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
}

.barChartWrap .line {
  fill: none;
  stroke: #0d43aa;
  stroke-width: 2px;
}

.barChartWrap .y.axis_right > path {
    color: #0d43aa;
    stroke-width: 2px;
}

.barChartWrap #lineChart > path {
    color: #0d43aa;
    stroke-width: 2px;
}

#loader_container {
  position: absolute;
  background-color: #d4cdcd2e;
}
#loader_container.hide {
  display: none;
}

.chartLoader {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: #0000003b;
  background-color: #0000001a;
}
