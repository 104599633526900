.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw.tglAndDataValue {
  margin-bottom: 20px!important;
}

.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox {
  padding: 20px;
  border: 1px dashed #d3d3d6;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}
.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .profileImgBox {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .profileImgBox > img {
  height: 60px;
  min-width: 40px;
  border-radius: 40px;
  padding: 10px 11px;
  background-color: #f8f8f9;
}
.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info {
  flex: 1;
}
.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > p {
  font-size: 14px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > div.top {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > div.top > .gButton {
  margin-left: 10px;
}
.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > div.top > .gButton > span {
  color: var(--gte-primary-color);
}

.gPScrlWrp.gbmStngs > .gPWrp > .gBCtnt > .gBCtRw> .lt > .dndBox > .info > p.supportInfo {
  color: var(--primary-text-color-light);
  font-weight: 400;
}
