
/* webchat preview */

.gPScrlWrp.wCBasic  > .webChatPreviewWrap {
  width: 360px;
  background-color: #F5F6F7;
}
.gPScrlWrp.wCBasic  > .webChatPreviewWrap > .gPageTitle {
  font-size: 16px;
  padding-bottom: 30px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #e6e6e7;
  padding: 18px 20px 17px 20px;
  background-color: #FFFFFF;
}
.gPScrlWrp.wCBasic  > .webChatPreviewWrap > .gPageTitle > .fas {
  margin-left: 5px;
}

.gPScrlWrp.wCBasic  > .webChatPreviewWrap > .previewBox {
  margin: 20px;
  background-color: #EEE;
  height: 70%;
  max-height: 600px;
  overflow-y: scroll;
  min-height: 500px;
}
.gPScrlWrp.wCBasic  > .webChatPreviewWrap > .previewBox::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gPScrlWrp.wCBasic  > .webChatPreviewWrap > .previewBox::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gPScrlWrp.wCBasic  > .webChatPreviewWrap > .previewBox::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}



.wcPreview {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  background-color: #FFFFFF;
  height: 100%;
}
.wcPreview > .header {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.wcPreview.theme_1 > .header {
  background-color: #e15700;
}
.wcPreview.theme_2 > .header {
  background-color: #003ae1;
}
.wcPreview.theme_3 > .header {
  background-color: #eb0c73;
}
.wcPreview.theme_4 > .header {
  background-color: #262f3b;
}
.wcPreview.theme_5 > .header {
  background-color: #CF6A7D;
}
.wcPreview > .header > .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wcPreview > .header > .top > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.wcPreview > .header > .top > .left > .backIcon {
  margin-right: 10px;
}
.wcPreview > .header > .top > .left > .avataarIcon {
}
.wcPreview > .header > .top > .left > .avataarIcon > img {
  height: 35px;
  border-radius: 20px;
  border: 2px solid #FFFFFF;
}
.wcPreview > .header > .top > .right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}
.wcPreview > .header > .top > .right > .imgBox {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.12);
  padding: 9px;
}
.wcPreview > .header > .top > .right > .imgBox.closeBox {
  padding: 9px 9px 7px 9px;
}
.wcPreview > .header > .top > .right > .imgBox > img {
  height: 12px;
}

.wcPreview > .header > .promptBox {
  margin-left: 5px;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.wcPreview > .header > .promptBox.addMargin {
  margin-bottom: 45px;
}

.wcPreview > .header > .searchBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 5px 12px;
  margin-bottom: 45px;
}
.wcPreview > .header > .searchBox > img {
  margin-right: 5px;
}
.wcPreview > .header > .searchBox > input {
  background-color: transparent;
  opacity: 0.7;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.24px;
  text-align: left;
  color: #ffffff;
}

.wcPreview > .body {
  padding: 15px;
  background-color: #FFFFFF;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.wcPreview > .body > .wcGBox {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  margin-top: -50px;
  margin-bottom: 30px;
}
.wcPreview > .body > .wcGBox > p.title {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #090e1a;
  margin-bottom: 12px;
  padding: 0;
}
.wcPreview > .body > .wcGBox > p.subTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #838e9f;
  margin-bottom: 20px;
}

.wcPreview > .body > .wcGBox.connectBox {
  margin-bottom: 80px;
}

.wcPreview > .body > .wcGBox.connectBox > .connectNowBtn {
  padding: 7px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 130px;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  border-radius: 5px;
}
.wcPreview.theme_1 > .body > .wcGBox.connectBox > .connectNowBtn {
  background-color: #e15700;
}
.wcPreview.theme_2 > .body > .wcGBox.connectBox > .connectNowBtn {
  background-color: #003ae1;
}
.wcPreview.theme_3 > .body > .wcGBox.connectBox > .connectNowBtn {
  background-color: #eb0c73;
}
.wcPreview.theme_4 > .body > .wcGBox.connectBox > .connectNowBtn {
  background-color: #262f3b;
}
.wcPreview.theme_5 > .body > .wcGBox.connectBox > .connectNowBtn {
  background-color: #CF6A7D;
}
.wcPreview > .body > .wcGBox.connectBox > .connectNowBtn > img {
  margin-right: 5px;
}


.wcPreview > .body > .queriesBox {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}
.wcPreview > .body > .queriesBox > p.title {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #090e1a;
  margin-bottom: 12px;
  padding: 0;
}
.wcPreview > .body > .queriesBox > p.subTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #838e9f;
  margin-bottom: 20px;
}

.wcPreview > .body > .queriesBox > .topicsBox {
}
.wcPreview > .body > .queriesBox > .topicsBox > .topicRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  border: solid 1px #e6e6e7;
  background-color: #ffffff;
  margin-bottom: 12px;
}
.wcPreview > .body > .queriesBox > .topicsBox > .topicRow > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.wcPreview > .body > .queriesBox > .topicsBox > .topicRow > .left > .icon {
  margin-right: 10px;
}
.wcPreview > .body > .queriesBox > .topicsBox > .topicRow > .left > .label {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #090e1a;
}



.wcPreview .channelRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  border: solid 1px #e6e6e7;
  background-color: #ffffff;
  margin-bottom: 12px;
}
.wcPreview .channelRow > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.wcPreview .channelRow > .left > .icon {
  margin-right: 10px;
}
.wcPreview .channelRow > .left > .icon > img {
  height: 24px;
}
.wcPreview .channelRow > .left > .label {
  display: flex;
  flex-direction: column;
}
.wcPreview .channelRow > .left > .label > p {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #090e1a;
}
.wcPreview .channelRow > .left > .label > span {
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #838e9f;
}
