.gPg.fdbAdnlQsPg  > .innerBox > .contentBox {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.gPg.fdbAdnlQsPg  > .innerBox > .contentBox > .left {
  flex: 1;
  border-right: 1px solid #e6e6e7;
  padding: 0px;
  padding-right: 20px;
}
.gPg.fdbAdnlQsPg  > .innerBox > .contentBox > .right {
  flex: 1;
  padding: 20px;
}

.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .left > form > .gSettingsRow > .titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 20px);
}

.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > p.title.highlight {
  background-color: yellow;
}
.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .additionalQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .additionalQuestion.highlight > p.question {
  background-color: yellow;
}
.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .additionalQuestion > p.question {
  margin-top: 20px;
  font-weight: bold;
}
.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .additionalQuestion > textarea {
  width: 260px;
  height: 100px;
  padding: 10px;
  resize: vertical;
}

.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .left > form > .enabledRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  margin-bottom: 30px;
}

.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .left > form > .adQuesBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .left > form > .adQuesBtn > span {
  padding: 3px 6px;
  border-radius: var(--generic-border-radius);
  cursor: default;
}
.gPg.fdbAdnlQsPg > .innerBox > .contentBox > .left > form > .adQuesBtn span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
