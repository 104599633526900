.gPScrlWrp.rooms > .gPWrp > .gPSubTle {
  padding-bottom: 10px;
  display: block;
}
.gPScrlWrp.rooms > .gPWrp > .gPSubTle > span {
  font-weight: var(--font-weight-bold);
  margin-left: 5px;
}

.gPWrp.ruleSingle > .gBCtnt > .gBCtRw.attrRw {
  gap: 10px;
}
.gPWrp.ruleSingle > .gBCtnt > .gBCtRw.attrRw > .gRwBxLt,
.gPWrp.ruleSingle > .gBCtnt > .gBCtRw.attrRw > .gRwBxRt {
  flex: 1;
}


.gBCtRw.ruleRw {
  flex-direction: column!important;
  justify-content: space-between;
  padding: 10px;
}
.gBCtRw.ruleRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.ruleRw > .tp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.gBCtRw.ruleRw > .tp > .lt {
  display: flex;
  flex-direction: row!important;
  gap: 5px;
  font-weight: var(--font-weight-bold);
}
.gBCtRw.ruleRw > .tp > .lt > .clrBx {
  margin-right: 10px;
  height: 18px;
  width: 18px;
  border-radius: 2px;
}
.gBCtRw.ruleRw > .tp > .rt {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.gBCtRw.ruleRw > .tp > .rt > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: var(--generic-border-radius);
}
.gBCtRw.ruleRw > .tp > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.ruleRw > .bt {
  color: var(--primary-text-color-light);
  font-weight: var(--font-weight-bold);
}
