.reviewCard {
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
  position: relative;
}
.singleReviewModal > .content > .reviewCard {
  box-shadow: none;
}
.reviewCard > .innerBox {
  display: flex;
  flex-direction: column;
}
.reviewCard > .innerBox > .closeRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 30px;
  1border-bottom: 1px solid #e6e6e7;
}
.reviewCard > .innerBox > .closeRow > .close {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.reviewCard > .innerBox > .closeRow > .close > img {
  margin-right: 10px;
}
.reviewCard > .innerBox > .closeRow > .nextPrevBox {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.reviewCard > .innerBox > .closeRow > .nextPrevBox > .entry {
}
.reviewCard > .innerBox > .closeRow > .nextPrevBox > .entry > i {
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
}

.singleReviewModal > .ctnt > .reviewCard {
  padding: 0!important;
  box-shadow: none;
}
.singleReviewModal > .ctnt > .reviewCard > .innerBox > .closeRow > span.close {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.singleReviewModal > .ctnt > .reviewCard > .innerBox > .closeRow> span.close:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.singleReviewModal > .ctnt > .reviewCard > .innerBox > .closeRow > span.close > img {
  margin-right: 5px;
  height: 14px;
}



.reviewCard > .innerBox > .reviewStateBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  display: none;
}
@media (max-width: 1023px) {
  .reviewCard > .innerBox > .reviewStateBox {
    display: flex;
  }
}
.reviewCard > .innerBox > .reviewStateBox > .reviewState > span {
  padding: 6px 15px 5px 15px;
  border-radius: 20px;
  border: solid 1px #e27b24;
  font-weight: 600;
}

.reviewCard > .innerBox > .headerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.reviewCard > .innerBox > .headerBox > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.reviewCard > .innerBox > .headerBox > .right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.reviewCard > .innerBox > .headerBox > .left > .ota {
  margin-right: 20px;
}
.reviewCard > .innerBox > .headerBox > .left > .ota > img {
  height: 30px;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox {
  display: flex;
  flex-direction: column;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author.locName {
  color: var(--primary-text-color-light);
  margin-bottom: 5px;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch {
  p1adding: 3px 6px;
  margin-right: 10px;
  b1ackground-color: var(--star-background);
  border-radius: var(--generic-border-radius);
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .rating > .stars {
  margin-right: 10px;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .rating > .ratingValue {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .reviewDate {
  margin-top: 5px;
}

.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .reviewDate,
.reviewCard > .innerBox > .headerBox > .right > .reviewDate {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-right: 10px;
}
.reviewCard > .innerBox > .headerBox > .right > .reviewState {
  margin-right: 20px;
}
@media (max-width: 1023px) {
  .reviewCard > .innerBox > .headerBox > .right > .reviewState {
    display: none;
  }
  /* .reviewCard > .innerBox > .headerBox > .right > .reviewDate {
    display: none;
  } */
}
.reviewCard > .innerBox > .headerBox > .right > .reviewState > span {
  padding: 6px 15px 5px 15px;
  border-radius: 20px;
  border: solid 1px #e27b24;
  font-weight: 600;
}
.reviewCard > .innerBox > .headerBox > .right > .reviewState.response_required > span {
  color: #e27b24;
}
.reviewCard > .innerBox > .headerBox > .right > .share {
  margin-right: 10px;
}
.reviewCard > .innerBox > .headerBox > .right > .share > span > i {
  font-size: 18px;
  color: var(--primary-text-color);
}

.reviewCard > .innerBox > .commentBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 70px 0 50px;
}
.reviewCard > .innerBox > .commentBox > .title {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 15px;
}
.reviewCard > .innerBox > .commentBox > .comment {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: pre-line;
}
.reviewCard > .innerBox > .commentBox > .comment  i {
  margin-right: 5px;
  font-size: 16px;
}
.reviewCard > .innerBox > .commentBox > .showEnTranslateCta {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  cursor: pointer;
}
.reviewCard > .innerBox > .commentBox.hasEnTranslation {
  m1argin-top: -20px;
}
.reviewCard > .innerBox > .commentBox.translated {
  padding-bottom: 20px;
}


.reviewCard > .innerBox > .responseBox {
  display: flex;
  flex-direction: row;
  padding: 20px 70px 0 50px;
}
.reviewCard > .innerBox > .responseBox > .vBar {
  width: 8px;
  height: 40px;
  margin: 0px 20px 20px 0px;
  background-color: #e6e6e7;
}
.reviewCard > .innerBox > .responseBox > .body {
  flex: 1;
  margin-bottom: 20px;
}
.reviewCard > .innerBox > .responseBox > .body > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.reviewCard > .innerBox > .responseBox > .body > .header > .left {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.reviewCard > .innerBox > .responseBox > .body > .header > .right {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  cursor: pointer;
}
.reviewCard > .innerBox > .responseBox > .body > .header > .right > .getResp {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.reviewCard > .innerBox > .responseBox > .body > .header > .right > .getResp:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.reviewCard > .innerBox > .responseBox > .body > .header > .right > .getResp > span {
  margin-left: 5px;
}

.reviewCard > .innerBox > .responseBox > .body > .promptBox {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fbf2f2cc;
  border-radius: var(--generic-border-radius);
  b1ackground-color: var(--generic-bg-color);
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .tTl {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: 20px;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .prptDrpDown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .prptDrpDown > .title {
  flex: 1;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .prptDrpDown > .prptSelect {
  w1idth: calc(100% - 200px);
  flex: 1;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .prptTextarea {
  margin-bottom: 10px;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .prptTextarea > textarea {
  width: calc(100% - 0px);
  padding: 12px;
  border: solid 1px rgba(37, 39, 52, 0.1);
  resize: vertical;
  height: 120px;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .prptTextarea > textarea::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .prptTextarea > textarea::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .prptTextarea > textarea::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.reviewCard > .innerBox > .responseBox > .body > .promptBox > .folUpBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .folUpBox > .folUpTextArea {
  width: 100%;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .folUpBox > .folUpTextArea > textarea {
  width: calc(100% - 0px);
  padding: 12px;
  border: solid 1px rgba(37, 39, 52, 0.1);
  resize: vertical;
  height: 80px;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .folUpBox > .addRemFolUpAct {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);

  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .folUpBox > .addRemFolUpAct:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .folUpBox > .addRemFolUpAct > span {
  margin-left: 5px;
}

.reviewCard > .innerBox > .responseBox > .body > .promptBox > .gnrtBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .gnrtBox > .getResp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);

  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .gnrtBox > .getResp:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.reviewCard > .innerBox > .responseBox > .body > .promptBox > .gnrtBox > .getResp > span {
  margin-left: 5px;
}


.reviewCard > .innerBox > .responseBox > .body > .content {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}
.reviewCard > .innerBox > .responseBox > .body > .content > span.sig {
  margin-top: 5px;
}
.reviewCard > .innerBox > .responseBox > .body > .subTitle {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 10px;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .cntTxtArTtl {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 10px;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .contentTextarea {
  margin-bottom: 7px;
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(37, 39, 52, 0.1);
  position: relative;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .contentTextarea > textarea {
  width: calc(100% - 0px);
  padding: 12px;
  b1order-radius: var(--generic-border-radius);
  border-bottom: solid 1px rgba(37, 39, 52, 0.1);
  resize: vertical;
  height: 120px;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .contentTextarea > textarea:read-only {
  background-color: #f4f4f4;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .contentTextarea > textarea::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .contentTextarea > textarea::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .contentTextarea > textarea::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .contentTextarea > span {
  position: absolute;
  left: 48%;
  top: 50px;
}

.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .contentTextarea > .actionIcons {
  padding: 8px 10px 10px 10px;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .templates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .templates > .list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .templates > .all {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .templates > .all:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .templates > .all > span {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .error {
  margin-top: 10px;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox {
  margin: 0;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 0px;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox > .buttonWrap {
  display: flex;
  flex-direction: row;
  margin-left: 0px;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox > .buttonWrap > .gButton {
  display: flex;
  flex-direction: row;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox > .buttonWrap > .gButton:first-child {
  margin-right: 10px;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox > .buttonWrap > .gButton > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox > .buttonWrap > .gButton > span > span {
  margin-left: 10px;
}


.reviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox > .buttonWrap {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0 0px;
  gap: 10px;
}
.r1eviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox > .buttonWrap > button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--gte-primary-color);
  color: var(--gte-white-color);
  border-radius: var(--generic-border-radius);
  font-size: var(--font-size-sub-mid-primary);
  padding: var(--btn-padding);
  cursor: pointer;
}
.r1eviewCard > .innerBox > .responseBox > .body.responseRequiredBody > .submitBox > .buttonWrap > button.inactive {
  background-color: var(--gt-form-button-disabled);
  color: var(--primary-text-color);
  cursor: not-allowed;
  pointer-events: none;
}

.rtButton {
  padding: 6px 12px;
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(131, 142, 159, 0.3);
  background-color: rgba(131, 142, 159, 0.08);
  margin-right: 10px;
  color: var(--primary-text-color-light);
  cursor: pointer;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.reviewStars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
}
.reviewStars:before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }



/* ************** more menu - dropdown menu ********************** */

.reviewCard .cMenu > .dropdown > .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 10px;
  font-size: 16px;
  border: none;
}

.reviewCard .cMenu > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.reviewCard .cMenu > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.reviewCard .cMenu > .dropdown > .dropdown-content {
  left: -155px;
}

.reviewCard .cMenu > .dropdown > .dropdown-content > .dcRow {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.reviewCard .cMenu > .dropdown > .dropdown-content > .dcRow:hover {
  background-color: var(--generic-hover-bg-color);
}
.reviewCard .cMenu > .dropdown > .dropdown-content > .dcRow > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.reviewCard .cMenu > .dropdown > .dropdown-content > .dcRow > span > img {
  height: 30px;
  margin-right: 16px;
}
.reviewCard .cMenu > .dropdown > .dropdown-content > .dcRow.gmail > span {
  padding: 4px 4px;
}
.reviewCard .cMenu > .dropdown > .dropdown-content > .dcRow.gmail > span > img {
  height: 16px;
}
.reviewCard .cMenu > .dropdown.moreMenu > .dropdown-content > .dcRow > span {
  padding: 4px 4px;
}

.reviewCard .cMenu > .dropdown > .dropdown-content a:hover {background-color: var(--generic-hover-bg-color);}

.reviewCard .cMenu > .dropdown.menuBox.open .dropdown-content {display: block;}

.reviewCard .cMenu > .dropdown.menuBox > .dropbtn {
  padding: 0px;
  background-color: #FFFFFF;
  cursor: pointer;
  1border-radius: 20px 16px;
}
.reviewCard .cMenu > .dropdown.menuBox > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);
  border-radius: var(--generic-border-radius);
}
.reviewCard .cMenu > .dropdown.shareMenu > .dropbtn {
  margin-right: 20px;
}
.reviewCard .cMenu > .dropdown.moreMenu > .dropbtn > span > img {
  width: 28px;
  border: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
}

/* ************************************ */


.reviewCard > .innerBox > .translateInProgress {
  position: absolute;
  top:0px;
  right:0px;
  width:100%;
  height:100%;
  background-color:#eceaea;
  background-color: #0000001a;
  /* background-image:url('ajax-loader.gif');
  background-size: 50px;
  background-repeat:no-repeat;
  background-position:center; */
  /* z-index:10000000; */
  /* opacity: 0.4; */
  /* filter: alpha(opacity=40); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviewCard > .innerBox > .translateInProgress > span {
  padding: 20px;
  background: #FFFFFF;
  border-radius: var(--generic-border-radius);
}

/******************** time line box ****************/

.reviewCard > .innerBox > .timeLnBx {
  display: flex;
  flex-direction: column;
  padding: 20px 70px 0 50px;
  border-top: 1px solid var(--generic-border-color);
}
.reviewCard > .innerBox > .timeLnBx > .tmEtry {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.reviewCard > .innerBox > .timeLnBx > .tmEtry > .lbl {
  width: 200px;
}
.reviewCard > .innerBox > .timeLnBx > .tmEtry > .prpBy {
  flex: 1;
}
.reviewCard > .innerBox > .timeLnBx > .tmEtry > .updAt {
  flex: 1;
}


/* ************** guest match - dropdown  ********************** */

.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 10px;
  font-size: var(--font-size-primary);
  border: none;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropbtn > span {
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--primary-text-color-light);
  color: var(--primary-text-color-light);
  padding: 2px 8px;
  font-weight: var(--font-weight-bold);
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch.hasMatch > .dropbtn > span {
  b1ackground-color: var(--gte-primary-color);
  c1olor: #FFFFFF;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch.open > .dropbtn > span {
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--gte-primary-color);
  color: var(--gte-primary-color);
  padding: 2px 8px;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropbtn > span > i {
  margin-left: 0px;
}

.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropdown-content {
  left: 0px;
  top: 26px;
}

.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropdown-content > .dcRow {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropdown-content a:hover {background-color: var(--generic-hover-bg-color);}

.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch.open .dropdown-content {display: block;}

.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropbtn {
  padding: 0px;
  background-color: #FFFFFF;
  cursor: pointer;
  1border-radius: 20px 16px;
}
.reviewCard > .innerBox > .headerBox > .left > .ratingBox > .author > .guestMtch > .dropdown.guestMtch > .dropbtn:hover {
  b1ackground-color: var(--generic-hover-bg-color);

}

/* ************************************ */

.gMInfoBox {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.gMInfoBox > .gPnlLdng {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  z-index: 1;
  background-color: #ffffff85;
}

.gMInfoBox p {
  margin: 0;
}
.gMInfoBox > .closeRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}
.gMInfoBox > .closeRow > span {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
}
.gMInfoBox > .gDBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e7;
}
.gMInfoBox > .gDBox:last-child {
  border-bottom: 0;
}
.gMInfoBox > .gDBox > .nameBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 6px;
}
.gMInfoBox > .gDBox > .nameBox > p.name {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-top: 20px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.gMInfoBox > .gDBox > .nameBox > p.name > span {
  color: var(--gte-neutral-100-color);
  padding: 0 4px;
}
.gMInfoBox > .gDBox.matched > .nameBox > p.name > span {
  color: #FFFFFF;
  background-color: var(--star-background);
}
.gMInfoBox > .gDBox > .nameBox > p.name > span:hover {
  color: #FFFFFF;
  background-color: grey;
}
.gMInfoBox > .gDBox.matched > .nameBox > p.name > span:hover {
  color: #FFFFFF;
  background-color: var(--star-background);
}

.gMInfoBox > .gDBox > .dRow > p {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gMInfoBox > .gDBox > .dRow > p > span {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-right: 20px;
}


.gMInfoBox > .gDBox > .nameBox > p.visits > span {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}

.gMInfoBox > .gDBox > .contactBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0;
  
}
.gMInfoBox > .gDBox > .contactBox > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gMInfoBox > .gDBox > .contactBox > p.email {
  margin-bottom: 16px;
  word-break: break-all;
}

.gMInfoBox > .gDBox > .datesBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0 0 0;
}
.gMInfoBox > .gDBox > .datesBox > p {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gMInfoBox > .gDBox > .datesBox > p.checkInDate {
  margin-bottom: 16px;
}
.gMInfoBox > .gDBox > .datesBox > p > span {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-right: 20px;
}

