.fdbMenuLst {
  width: 350px;
  height: 100%!important;
  border-right: 1px solid var(--generic-border-color);
  display: flex!important;
  flex-direction: column;
  position: relative!important;
  transform: none!important;
}
.fdbMenuLst > .rszIndc {
  position: absolute;
  right: 0;
  top: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  gap: 1px;
  color: var(--resize-icon-color);
}
.fdbMenuLst > .rszIndc > span {
  line-height: 1;
}
.fdbMenuLst > .hdr {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--generic-border-color);
}
.fdbMenuLst > .hdr > div {
  cursor: default;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.fdbMenuLst > .hdr > div.lck > span > i {
  color: var(--gte-primary-color);
}
.fdbMenuLst > .mnuLst {
  display: flex;
  flex-direction: column;
}
.fdbMenuLst > .mnuLst > a {
  padding: 15px 20px;
  color: var(--primary-text-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 0px solid var(--generic-border-color);
}
.fdbMenuLst > .mnuLst > a.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
.fdbMenuLst > .mnuLst > a:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.fdbMenuLst > .mnuLst > a > .lt {
  width: 30px;
  display: flex;
  align-items: center;
}
.fdbMenuLst > .mnuLst > a > .rt > p.title {
  margin-bottom: 2px;
}
.fdbMenuLst > .mnuLst > a > .rt > p.desc {
  color: var(--primary-text-color-light);
}

.fdbMenuLst > .mnuLst > a > .lt > .mDot {
  height: 10px;
  width: 10px;
  background-color: #FCE5E5;
  border-radius: 10px;
}
.fdbMenuLst > .mnuLst > a > .lt > .mDot.active {
  background-color: #08D58C;
}
