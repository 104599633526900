.cModal.imgPrvMdl > .ctnt {
  width: 600px;
}

.cModal.imgPrvMdl > .ctnt > .imgPrv {
  margin: 0!important;
  padding: 20px!important;
  border-radius: 5px;
}

.cModal.imgPrvMdl > .ctnt > .imgPrv {
  padding: 40px 20px!important;
}
.imgPrv > .gPTle > span {
  font-size: 18px;
}
.imgPrv > .contentRow {
  display: flex;
  flex-direction: column!important;
  margin-bottom: 10px;
}
.imgPrv > .contentRow > .itemBox {
  text-align: center;
}
.imgPrv > .contentRow > .itemBox > img {
  max-height: 400px;
  max-width: 400px;
}

.imgPrv > .contentRow > .itemBox > i {
  position: absolute;
}
.imgPrv > .contentRow > .itemBox > img {
  padding: 0px;
  z-index: 2;
  background-color: #fafbfd;
}

.imgPrv > .byTwoRow.buttonsRow {
  justify-content: flex-end;
}
.imgPrv > .byTwoRow.buttonsRow > .replace {
  margin-right: 10px;
}

.imgPrv > .attachStatus {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.imgPrv > .attachStatus > p {
  margin-bottom: 10px;
}
.imgPrv > .attachStatus .fa {
  margin-left: 5px;
}
.imgPrv > .attachStatus.uploadError {
  flex-direction: column;
}

.imgPrv > .attachStatus.uploadError > .btnRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.imgPrv > .attachStatus.uploadError button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--gte-primary-color);
  color: var(--gt-form-submit-text-color);
  border-radius: var(--gt-form-submit-border-radius);
  font-size: var(--gt-form-submit-font-size);
  padding: var(--gt-form-submit-padding);
  cursor: pointer;
}

.imgPrv > .attachStatus.uploadError span.replace {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--gt-form-submit-text-color);
  color: var(--gte-primary-color);
  border-width: 1px;
  border-style: solid;
  border-radius: var(--gt-form-submit-border-radius);
  border-color: var(--gte-primary-color);
  font-size: var(--gt-form-submit-font-size);
  padding: var(--gt-form-submit-padding);
  margin-right: 10px;
  cursor: pointer;
}
