.gLocationListCard {
  display: flex;
  flex-direction: column;
  padding: 0px!important;
}
.gLocationListCard > .top {
  margin-bottom: 5px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gLocationListCard > .top > p {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gLocationListCard > .top > p > i {
  margin-left: 5px;
}

.gLocationListCard > .top > .dwnldCsv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  padding: 0 10px;
}
.gLocationListCard > .top > .dwnldCsv:hover {
  background-color: var(--generic-hover-bg-color);
}
.gLocationListCard > .top > .dwnldCsv > img {
  height: 20px;
  margin-right: 5px;
}
.gLocationListCard > .top > .dwnldCsv > p {
  margin-bottom: 0;
}
.gLocationListCard > .top > .dwnldCsv > span {
  margin-left: 10px;
}

.gLocationListCard > .searchBox {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.gLocationListCard > .searchBox > .inputBox {
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(79, 89, 105, 0.2);
  background-color: rgba(242, 242, 245, 0.4);
  padding: 10px;
  width: 100%;
}
.gLocationListCard > .searchBox > .inputBox > img {
  height: 20px;
  margin-right: 5px;
}
.gLocationListCard > .searchBox > .inputBox > input {
  width: calc(100% - 26px);
  background-color: rgb(250 250 251);
}

.gLocationListCard > .middle > .entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0px;
  background-color: #FFFFFF;
  padding: 20px;
}
.gLocationListCard > .middle > .entry:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
.gLocationListCard > .middle > .entry.header > .name,
.gLocationListCard > .middle > .entry.header > .value {
  cursor: default;
}
.gLocationListCard > .middle > .entry > .openNewTab {
  width: 30px;
}
.gLocationListCard > .middle > .entry > .openNewTab > a > span > i {
  color: var(--gte-primary-color);
}
.gLocationListCard > .middle > .entry > .name {
  flex: 2;
  display: flex;
  flex-direction: row;
}
.gLocationListCard > .middle > .entry > .value {
  flex: 1;
  text-align: center;
}
.gLocationListCard > .middle > .entry > .name,
.gLocationListCard > .middle > .entry > .value {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.gLocationListCard > .middle > .entry > .value {
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.gLocationListCard > .middle > .entry.header > .value {
  flex-direction: row!important;
}
.gLocationListCard > .middle > .entry > .value.totalReviews,
.gLocationListCard > .middle > .entry > .value.averageRating,
.gLocationListCard > .middle > .entry > .value.sentimentScore {
  /* font-size: 16px; */
  flex-direction: column;
  align-items: flex-start;
}
.gLocationListCard > .middle > .entry > .value > p.cmpVal{

}
.gLocationListCard > .middle > .entry > .name > .sortIndicator,
.gLocationListCard > .middle > .entry > .value > .sortIndicator {
  margin-left: 5px;
}
.gLocationListCard > .middle > .entry > .name > .sortIndicator > .arrow-up,
.gLocationListCard > .middle > .entry > .value > .sortIndicator > .arrow-up {
  margin-bottom: 2px;
}

.gLocationListCard > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gLocationListCard > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
.gLocationListCard > .footer > a {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}

.sortIndicator {
  display: flex;
  flex-direction: column;
}
.sortIndicator > .arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #838e9f;
}
.sortIndicator > .arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #838e9f;
}
.sortIndicator > .arrow-up.active {
  border-bottom: 8px solid #0d43aa;
}
.sortIndicator > .arrow-down.active {
  border-top: 8px solid #0d43aa;
}
