.gPWrp.fttSingle > .gBCtnt > .gBCtRw.titleRow {
  margin-bottom: 0;
}
.gBCtRw.respTplRw {
  display: flex;
  flex-direction: column!important;
  padding: 10px;
}
.gBCtRw.respTplRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.respTplRw > .tp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.gBCtRw.respTplRw > .tp > .lt {
  font-weight: var(--font-weight-bold);
}
.gBCtRw.respTplRw > .tp > .rt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.gBCtRw.respTplRw > .tp > .rt > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: var(--generic-border-radius);
}
.gBCtRw.respTplRw > .tp > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.respTplRw > .tp > .rt > span > img.def {
  height: 16px;
  padding: 2px;
}
.gBCtRw.respTplRw > .tp > .rt > span.is_def {
  background-color: #e6e2fb;
}

.gBCtRw.respTmplCatListRw {
  background-color: transparent!important;
}
.gBCtRw.respTmplCatListRw > .lt {
  display: flex;
  flex-direction: row!important;
  flex-wrap: wrap;
}
.gBCtRw.respTmplCatListRw > .lt > .entry {
  background-color: #FFFFFF;
  margin-right: 10px;
  font-size: var(--font-size-primary);
  padding: 6px 12px;
  color: var(--primary-text-color);
  border-radius: var(--generic-border-radius);
  cursor: pointer;
  text-transform: capitalize;
}
.gBCtRw.respTmplCatListRw > .lt > .entry.active {
  background-color: var(--sidebar-main-menu-active-bg);
}

.gBCtRw.categoryRow {
  margin-top: 20px;
  gap: 20px;
}
.gBCtRw.categoryRow > .gRwBxLt,
.gBCtRw.categoryRow > .gRwBxRt {
  flex: 1;
}
.gBCtRw.categoryRow > .gRwBxRt {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.gBCtRw.categoryRow > .gRwBxRt > .addCtgry {
  padding: 10px;
  cursor: default;
}
.gBCtRw.categoryRow > .gRwBxRt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.categoryRow > .gRwBxRt > .addCategoryBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  m1argin-top: -8px;
  gap: 16px;
}
.gBCtRw.categoryRow > .gRwBxRt> .addCategoryBox > span {
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gBCtRw.categoryRow > .gRwBxRt> .addCategoryBox > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.categoryRow > .gRwBxRt> .addCategoryBox > input {
  color: var(--primary-text-color);
  border: var(--gt-form-input-border);
  border-radius: var(--gt-form-input-border-radius);
  padding: 9px 12px 8px 12px;
  margin-right: 20px;
  color: var(--primary-text-color);
  width: 200px;
}
.gBCtRw.categoryRow > .gRwBxRt> .addCategoryBox > img {
  cursor: pointer;
}


.pac-container {
  background-color: #151515;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
   Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
 }
 .pac-item,
 .pac-item-query {
  color: #f7f7f7;
 }
 .pac-item:hover {
  background: #6666;
 }