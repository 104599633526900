/* ******************* Modal ******************* */

.templateSelectorModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.templateSelectorModal > .contentWrap {
  max-width: 80%;
  width: 80%;
  max-height: 80%;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
  1overflow-y: scroll;
  1overflow-x: hidden;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.templateSelectorModal > .contentWrap > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
}
.templateSelectorModal > .contentWrap > .close > .fas {
  font-size: 20px;
}

.templateSelectorModal > .contentWrap > .content {
  padding: 30px;
}
.templateSelectorModal > .contentWrap > .content > .title {
  font-size: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.templateSelectorModal > .contentWrap > .content > .body {
  display: flex;
  flex-direction: column;
}
.templateSelectorModal > .contentWrap > .content > .body > .rowOne {
  font-size: 16px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList {
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px 10px;
  padding-bottom: 50px;
  max-height: 400px;
  overflow-y: scroll;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate {
  padding: 10px;
  border: 2px solid var(--generic-border-color);
  border-radius: var(--generic-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  m1in-height: 172px;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate.selected {
  border-color: orange;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > span.default {
  color: var(--gte-primary-color);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .innerBox {
  display: flex;
  flex-direction: column;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .innerBox.selected {

}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .innerBox > .previewBox {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .innerBox > .previewBox > span {
  padding: 8px 24px;
  border-radius: 5px;
  border: solid 1px #0d43aa;
  background-color: #fff;
  cursor: default;
  font-size: 13px;
  font-weight: var(--font-weight-normal);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .innerBox > .name {
  font-size: 14px;
  margin-top: 20px;
  font-weight: var(--font-weight-normal);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .innerBox > .desc {
  font-size: 13px;
  margin-top: 12px;
  font-weight: var(--font-weight-normal);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}

.templateSelectorModal > .contentWrap > .content > .body > .rowNote {
  font-size: 16px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  background-color: var(--alert-note-bg-color);
  padding: 12px;
  border-radius: var(--generic-border-radius);
  color: var(--alert-note-text-color);
}
.templateSelectorModal > .contentWrap > .content > .body > .currentStatus {
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 30px;
  text-align: center;
}
.templateSelectorModal > .contentWrap > .content > .body > .actions {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
}
.templateSelectorModal > .contentWrap > .content > .body > .actions > button {
  padding: 8px 32px;
  border-radius: var(--generic-border-radius);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
}
.templateSelectorModal > .contentWrap > .content > .body > .actions > button.cancel {
  border: 1px solid #DDD;
  background-color: #FFFFFF;
  margin-right: 16px;
}
.templateSelectorModal > .contentWrap > .content > .body > .actions > button.ok {
  border: 1px solid var(--form-error-color);
  background-color: var(--form-error-color);
  color: #FFFFFF;
}
.templateSelectorModal > .contentWrap > .content > .body > .actions > button.ok > .fa {
  margin-left: 10px;
}
.templateSelectorModal > .contentWrap > .content > .body > .actions > button.ok.secondary {
  margin-left: 16px;
}


/* ************** dropdown menu ********************** */

.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown > .dropbtn {
  1background-color: #4CAF50;
  1background-color: #FFFFFF;
  color: #AAA;
  1padding: 2px;
  font-size: 16px;
  border: none;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown > .dropbtn > img {
  height: 16px;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown > .dropbtn {
  padding: 8px 10px;
  background-color: #FFFFFF;
  border-radius: var(--generic-border-radius);
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown > .dropbtn:hover {
  background-color: #f7f7f9;
}

.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: -5px;
}

.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  -webkit-backdrop-filter: blur(19.6px);
  backdrop-filter: blur(19.6px);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown > .dropdown-content {
  left: -140px;
  top: 25px;
}
.teHistoryWrap > .transactionEmailHistory > .allWrap > .singleBox > .partThree > .top > .oMenu > .dropdown > .dropdown-content {
  left: -140px;
  top: 25px;
}

.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown > .dropdown-content p {
  color: var(--primary-text-color);
  padding: 14px 20px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid var(--generic-border-color);
  cursor: pointer;
  margin: 0;
}

.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: pointer;}

.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .top > .oMenu > .dropdown .dropdown-content.open {display: block;}

.templateSelectorModal > .contentWrap > .content > .body > .templateList > .singleTemplate > .dropdo1wn:hover .dropdown-content {display: block;}


/* ************************************ */
