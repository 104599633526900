.emailTaskDetail > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-title);
  border-bottom: 1px solid var(--generic-border-color);
  padding-bottom: 10px;
}
.emailTaskDetail > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw {
  justify-content: flex-start;
}
.emailTaskDetail > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw > .gRwBxLt {
  width: 160px!important;
  flex: none;
  color: var(--primary-text-color-light);
  font-weight: var(--font-weight-bold);
}
.emailTaskDetail > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw > .gRwBxRt {
  flex: 1;
  color: var(--primary-text-color);
}
.emailTaskDetail > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw > .gRwBxRt > .opnMdl {
  cursor: default;
}
.emailTaskDetail > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw > .gRwBxRt > p.flexRow > .ipCountry {
  margin-right: 8px;
}
