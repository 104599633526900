.gPg.fdbUplPg > .fbdUplBd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.gPg.fdbUplPg > .fbdUplBd > .lt {
  flex: 1;
  margin-right: 20px;
}
.gPg.fdbUplPg > .fbdUplBd > .rt {
  width: 245px;
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox {
  display: flex;
  flex-direction: column;
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .dndBox {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .dndBox > .title  {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .dndBox > .innerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--generic-border-radius);
  border: 1px dotted grey;
  padding: 20px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .dndBox > .innerBox > img {
  height: 160px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .dndBox > .sampleCsv  {
  margin-top: 5px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .dndBox > .sampleCsv > a {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  text-decoration: none;
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .orBox {
  padding: 60px 0 50px 0;
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox {
  display: flex;
  flex-direction: row;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .left {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .right {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 20px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .left > .title  {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .left > textarea  {
  border: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 20px;
  height: 160px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .left > textarea:focus {
  border-color: var(--gte-primary-color);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .left > .buttonBox  {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .left > .buttonBox > .gBtn.cancel > img {
  margin-right: 5px;
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .right > .tipColumn {
  width: 30px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .right > .dataColumn {
  flex: 1;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .right > .dataColumn > p {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .right > .dataColumn > p.dark {
  font-weight: var(--font-weight-bold);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .right > .dataColumn > p.light {
  color: var(--primary-text-color-light);
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  width: 70%;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .manualBox > .left > .processBox {
  width: calc(100% - 20px);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .title  {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 10px;
  margin-bottom: 5px;
  1border-bottom: 1px solid #e6e6e7;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow.header {
  margin-bottom: 2px;
  border-bottom: 0;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow.error {

}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow > .name {
  flex: 1;
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow > .contact {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
  margin-right: 10px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow > .email {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
  margin-right: 10px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow > .email.error > p {
  margin: 0;
  margin-top: 2px;
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--generic-error-color);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow.header > .name,
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow.header > .contact,
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow.header > .email {
  color: var(--primary-text-color-light);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow > .name > input,
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow > .contact > input,
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow > .email > input {
  width: 100%;
  padding: 9px 12px 8px 12px;
  border: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .emailRow > .remove {
  width: 40px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .addRow {
  margin-bottom: 30px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .addRow > span {
  cursor: pointer;
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .sendWhenBox {
  display: flex;
  flex-direction: column;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .sendWhenBox > .entry {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .sendWhenBox > .sendNow > input {
  transform: scale(1.2);
  margin-right: 10px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .gErr {
  margin-top: 20px;
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .buttonBox  {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox .processBox > .buttonBox > .gBtn.cancel > img {
  margin-right: 5px;
}



.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .inpFrm > .bcRow {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 20px;
}

.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .inpFrm > .bcRow.tmplsRow > .tmplTypes {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .inpFrm > .bcRow.tmplsRow > .tmplTypes > .entry {
  background-color: var(--sidebar-main-menu-active-bg);
  padding: 8px 12px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  font-weight: var(--font-weight-normal);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .inpFrm > .bcRow.tmplsRow > .tmplTypes > .entry.selected {
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
}


.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .inpFrm > .bcRow.prvRow {
  margin-top: 30px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .inpFrm > .bcRow.prvRow > .prv > .msg {
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .inpFrm > .bcRow.prvRow > .prv > .hlp {
  margin-top: 10px;
}
.gPg.fdbUplPg > .fbdUplBd > .lt > .innerBox > .contentBox > .inpFrm > .bcRow > .gErr {
  margin-top: 5px;
}
