.gPg.gLOvrPg > .gLOvrBd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.gPg.gLOvrPg > .gLOvrBd > .lt {
  flex: 1;
  margin-right: 20px;
}
.gPg.gLOvrPg > .gLOvrBd > .rt {
  width: 245px;
}

.guestListOverviewBoxWrap p {
  margin: 0;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .topBox {
  margin-bottom: 20px;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .statBox {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .statBox > .gNumberCard {
  1width: 200px;
  margin-right: 10px;
  flex: 1;
  max-width: 300px;
}

.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsTitle > .left > p {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsTitle > .left > p > span {
  color: var(--primary-text-color-light);
  margin-left: 5px;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsTitle > .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: default;
  padding: 3px 8px;
  border-radius: var(--generic-border-radius);
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsTitle > .right:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsTitle > .right > img {
  margin-right: 5px;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsTitle > .right > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin-bottom: 0;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsTitle > .right > span {
  margin-left: 10px;
}

.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  padding: 10px;
  margin-bottom: 20px;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .left {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-right: 1px solid #e6e6e7;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .right {
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .left > .searchInput {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .left > .searchBtn {
  width: 120px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .left > .searchBtn > img {
  margin-right: 20px;
  cursor: pointer;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .left > .searchBtn > span {
  cursor: pointer;
}

.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .left > .searchInput > img {
  margin-right: 5px;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .left > .searchInput > input {
  width: 100%;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .right > img {
  margin-right: 10px;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .guestsSearch > .right > .guestListSort {
  width: 160px;
}

.glSingleCard {
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  margin-bottom: 20px;
}

.glSingleCard > .innerBox {
  display: flex;
  flex-direction: row;
}

.glSingleCard > .innerBox > .nameBox {
  width: 300px;
  border-right: 1px solid #e6e6e7;
  padding-right: 20px;
}
.glSingleCard > .innerBox > .detailsBox {
  flex: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.glSingleCard > .innerBox > .nameBox > .name,
.glSingleCard > .innerBox > .nameBox > .email,
.glSingleCard > .innerBox > .nameBox > .contact {
  word-break: break-all;
  margin: 0 0 5px 0;
  color: var(--primary-text-color-light);
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.glSingleCard > .innerBox > .nameBox > .name {
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color);
}

.glSingleCard > .innerBox > .detailsBox > .row {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.glSingleCard > .innerBox > .detailsBox > .row > .col {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.glSingleCard > .innerBox > .detailsBox > .row .label {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.glSingleCard > .innerBox > .detailsBox > .row .value {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}

.glSingleCard > .innerBox > .detailsBox > .row .value > span.repeats {
  text-decoration: underline;
  cursor: pointer;
}

.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .pageOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .pageOptions > .paginationBox {
  flex: 1;
}
.gPg.gLOvrPg > .gLOvrBd > .lt > .innerBox > .contentBox > .dataBox > .pageOptions > .pageSizeBox {
  width: 90px;
}
