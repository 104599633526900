.wrp {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.mb {
  width: 100%;
  height: calc(100% - 50px);
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  o1verflow-y: scroll;
}
.mb.eg {
  overflow-y: hidden;
}

.mb > .mc {
  flex: 1;
  padding-left: 200px;
  padding-left: 0px;
  width: calc(100% - 196px);
  position: relative;
}
.wrp.sbClsd > .mb > .mc {
  padding-left: 80px;
  padding-left: 0px;
  width: calc(100% - 80px);
}

.wrp > .mb.iframeBx,
.wrp > .mb > .mc > .mcIframeWrp {
  background-color: #DBDEE6;
}
.wrp > .mb > .mc > .mcIframeWrp {
  height: 100%;
}
.wrp > .mb.iframeBx > iframe,
.wrp > .mb > .mc > .mcIframeWrp > iframe {
  padding: 0px;
  width: 100%;
  overflow-y: scroll;
  border: 0px solid grey;
  margin: 20px;
}
.wrp > .mb > .mc > .mcIframeWrp > iframe {
  height: calc(100% - 40px);
  width: calc(100% - 40px);
}
.wrp > .mb.iframeBx > iframe::-webkit-scrollbar-track,
.wrp > .mb > .mc > .mcIframeWrp > iframe::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.wrp > .mb.iframeBx > iframe::-webkit-scrollbar,
.wrp > .mb > .mc > .mcIframeWrp > iframe::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.wrp > .mb.iframeBx > iframe::-webkit-scrollbar-thumb,
.wrp > .mb > .mc > .mcIframeWrp > iframe::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}





.mb > .rsb {
  width: 50px;
  background-color: var(--gte-neutral-100-color);
}


/* ******************* Generic Modal ******************* */
#modal-notifications {
  position: relative;
  z-index: 999;
}
.gModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gModal > .content {
  max-width: 800px;
  width: 60%;
  max-height: 600px;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
  1overflow-y: scroll;
  1overflow-x: hidden;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.gModal > .content {
  max-height: 90%;
  overflow-y: scroll;
}
.gModal > .content::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gModal > .content::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gModal > .content::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.gModal > .content > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
}
.gModal > .content > .close > .fas {
  font-size: 20px;
}

/***************************************************/

/********************** Notifications Modal **************************/

.gModal.ntModal {
  background-color: rgb(0 0 0 / 15%);
}

/***************************************************/

/********************** Side Modal **************************/

#modal-root {
  position: relative;
  z-index: 999;
}
.sideModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0!important;

}
.sideModal > .content {
  max-width: 900px;
  width: 80%;
  height: calc(100% - 40px);
  background-color: #FFFFFF;
  transition: all 0.5s;
  animation: slideInRight 0.5s;
  overflow-y: scroll;
  padding: 20px;
}
.sideModal > .content > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sideModal > .content > .title > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sideModal > .content > .title > .close > img {
  margin-right: 5px;
}
.sideModal.knowMoreModal > .content {
  overflow-y: hidden;
}

/* ************** side modal *************** */
