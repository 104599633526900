/* *************************************************** */

.gPg.insIvt > .title {
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}

.gPg.insIvt > .insIvt {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.gPg.insIvt > .insIvt > .lt {
  flex: 1;
  padding-right: 100px;
  display: flex;
  flex-direction: column;
}
.gPg.insIvt > .insIvt > .rt {
  min-width: 300px;
  width: 30%;
  display: flex;
  flex-direction: column;
}

.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 20px;
}

.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.contactRow > .gIpt input {
  width: 300px;
}
.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.nameRow > .gIpt  {
  width: 100%;
  max-width: 333px;
}
.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.tmplsRow > .tmplTypes {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.tmplsRow > .tmplTypes > .entry {
  background-color: var(--sidebar-main-menu-active-bg);
  padding: 8px 12px;
  border-radius: var(--generic-border-radius);
  cursor: default;
  font-weight: var(--font-weight-normal);
}
.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.tmplsRow > .tmplTypes > .entry.selected {
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
}

.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.prvRow {
  margin-top: 50px;
}
.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.prvRow > .prv > .msg {
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.prvRow > .prv > .hlp {
  margin-top: 10px;
}

.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.buttonsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;
}

.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow > .gErr {
  margin-top: 10px;
}

.gPg.insIvt > .insIvt > .rt > .prvSnt {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.gPg.insIvt > .insIvt > .rt > .prvSnt > .entry {
  padding: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
.gPg.insIvt > .insIvt > .rt > .prvSnt > .entry > .rw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg.insIvt > .insIvt > .rt > .prvSnt > .entry > .rw.tp {
  margin-bottom: 20px;
}

.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.curSts {
  font-weight: var(--font-weight-bold);
  margin-top: 30px;
  margin-bottom: 0;
}
.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.curSts > .success {
  color: var(--form-success-color);
}
.gPg.insIvt > .insIvt > .lt > .inpFrm > .bcRow.curSts > .error {
  color: var(--form-error-color);
}
