.gBCtRw.msgRw > .lt {
  width: 100%;
}
.gBCtRw.msgRw > .lt > .emojiRw {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gBCtRw.msgRw > .lt > .emojiRw > .emojiBox {
  margin-top: -30px;
  margin-right: 10px;
}
.gBCtRw.msgRw > .lt > .emojiRw > .emojiBox > .emojiIcon > .fa {
  font-size: 18px;
}
