.gPScrlWrp.hDGen {
  background-color: var(--generic-bg-color);
}
.gPWrp.hDGen {
  background-color: var(--generic-bg-color);
}
.gPWrp.hDGen > .gBCtnt > .gBCtRw {
  padding: 20px;
}
.gBCtRw.hasTgl {
  justify-content: space-between;
}
.gBCtRw.timeValues {
  gap: 30px;
}
.gBCtRw.timeTitle {
  margin-bottom: 0!important;
}
.gBCtRw.timeValues > .lt,
.gBCtRw.timeValues > .rt {
  flex: 1;
}
.gBCtRw.timeValues .gSLbl {
  color: var(--primary-text-color);
}
.gBCtRw.hasBreakfast.hasTimeValues {
  margin-bottom: 0!important;
}
.gBCtRw.wifiInfo > .lt,
.gBCtRw.hotelName > .lt,
.gBCtRw.smsNumber > .lt,
.gBCtRw.hotelAddress > .lt,
.gBCtRw.logoBoxImg > .lt {
  flex: 1;
}
.gBCtRw.wifiInfo > .lt > .gIpt,
.gBCtRw.hotelName > .lt > .gIpt,
.gBCtRw.smsNumber > .lt > .gIpt {
  margin-top: 16px;
  width: 50%;
}
.gBCtRw.hotelAddress {
  margin-bottom: 0!important;
}
.gBCtRw.hotelAddress > .lt > .gIpt {
  margin-top: 16px;
}
.gBCtRw.addressParts {
  gap: 10px;
  margin-bottom: 0!important;
}
.gBCtRw.addressParts.bt {
  margin-bottom: 20px!important;
}
.gBCtRw.addressParts > .lt,
.gBCtRw.addressParts > .rt {
  flex: 1;
}
.gBCtRw.addressParts > .rt {
  display: flex;
  flex-direction: column;
}
.gBCtRw.addressParts .gLbl {
  color: var(--primary-text-color-light);
}

.gBCtRw.logoBox {
  margin-bottom: 0!important;
  padding-bottom: 0!important;
}
.gBCtRw.logoBoxImg > .lt > .dndBox {
  padding: 20px;
  border: 1px dashed #d3d3d6;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.gBCtRw.logoBoxImg > .lt > .dndBox > .profileImgBox {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.gBCtRw.logoBoxImg > .lt > .dndBox > .profileImgBox > img {
  max-height: 200px;
  height: auto;
  min-width: 40px;
  border-radius: 0px;
  padding: 10px 11px;
  background-color: transparent;
  w1idth: 100%;
}
.gBCtRw.logoBoxImg > .lt > .dndBox > .info > p {
  font-size: 14px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.gBCtRw.logoBoxImg > .lt > .dndBox > .info > div.top {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
.gBCtRw.logoBoxImg > .lt > .dndBox > .info > div.top > .gButton {
  margin-left: 10px;
}
.gBCtRw.logoBoxImg > .lt > .dndBox > .info > div.top > .gButton > span {
  color: var(--gte-primary-color);
}

.gBCtRw.logoBoxImg > .lt > .dndBox > .info > p.supportInfo {
  color: var(--primary-text-color-light);
  font-weight: 400;
}
