.wcqRow {
  display: flex;
  flex-direction: column!important;
  padding: 20px 20px 30px 20px;
  border-bottom: 1px solid var(--generic-border-color);
}
.wcqRow.noBorder {
  border: 0;
}
.wcqRow:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.wcqRow > .title {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wcqRow > .title > .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.wcqRow > .title > .actions > span {
  padding: 3px;
}
.wcqRow > .title > .actions > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
  border-radius: var(--generic-border-radius);
}
.wcqRow > .title > .actions > span > img {
  cursor: default;
}
.wcqRow > .msg {
  color: var(--primary-text-color-light);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.wcqRow > .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.wcqRow > .actions > button {
  padding: 5px 10px;
  background-color: blue;
  color: #FFFFFF;
  margin-top: 10px;
  border-radius: 3px;
}
.wcqRow > .actions > button.edit {
  margin-right: 10px;
}
.wcqRow > .actions > button.remove {
  background-color: var(--form-error-color);
}
