.gTETypeStatusCard {
  display: flex;
  flex-direction: column;
}
.gTETypeStatusCard > .status {
  margin-bottom: 10px;
}
.gTETypeStatusCard > .status > .indicator {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: grey;
  border: 5px solid #ffffffcc;
}
.gTETypeStatusCard.active > .status > .indicator {
  background-color: orange;
  background-color: lightgreen;
}
.gTETypeStatusCard > .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
.gTETypeStatusCard > .top > .left {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gTETypeStatusCard > .top > .left > .line1 {
  color: var(--primary-text-color);
  font-size: 18px;
}
.gTETypeStatusCard > .top > .left > .line2 {
  color: var(--primary-text-color-light);
  font-size: 16px;
}
.gTETypeStatusCard > .top > .right > img {
  height: 20px;
}





.gTETypeStatusCard > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
}
.gTETypeStatusCard > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
.gTETypeStatusCard > .footer > a {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
  text-decoration: none;
}
