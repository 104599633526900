.gPScrlWrp.engSetGen {
  background-color: var(--generic-bg-color);
}
.gPScrlWrp.engSetGen > .gPWrp {
  background-color: var(--generic-bg-color);
}

.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw > .gRwBxLt {
  flex: 1;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.messageRow,
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.emojiRow {
  margin-bottom: 0;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.emojiRow > .gRwBxLt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.emojiRow > .gRwBxLt > .emojiIcon {
  margin-top: -36px;
  margin-right: 10px;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.emojiRow > .gRwBxLt > .emojiIcon > img {
  height: 18px;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.emojiRow > .gRwBxLt > .emojiIcon > img.closeIcon {
  height: 14px;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.emojiRow > .gRwBxLt.messageError > .emojiIcon {
  margin-top: -56px;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.personalizeRow {
  margin-top: 20px;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.personalizeRow > .gRwBxLt {
  width: 50%;
  flex: none;
}
.mtGen > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.messageRow.error {

}

.gPScrlWrp.engSetGen > .gPWrp > .gBCtnt > .gBCtRw.srchRw > .lt {
  flex: 1;
}
.gPScrlWrp.engSetGen > .gPWrp > .gBCtnt > .gBCtRw.srchRw > .lt > input {
  width: 100%;
}
.gBCtRw.msgTplRw {
  display: flex;
  flex-direction: column!important;
  padding: 10px;
}
.gBCtRw.msgTplRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.msgTplRw > .tp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.gBCtRw.msgTplRw > .tp > .lt {
  font-weight: var(--font-weight-bold);
}
.gBCtRw.msgTplRw > .tp > .rt {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.gBCtRw.msgTplRw > .tp > .rt > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: var(--generic-border-radius);
}
.gBCtRw.msgTplRw > .tp > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.msgTplRw > .tp > .rt > .aprvPndg {
  color: var(--primary-text-color-light);
  font-weight: var(--font-weight-bold);
}
