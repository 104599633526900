.bc > .bcRow.logRow {
  padding: 20px;
  margin: 0 0;
  border-radius: var(--generic-border-radius);
  align-items: flex-start;
  border-bottom: 1px solid var(--generic-border-color);
  cursor: pointer;
}
.bc > .bcRow.logRow:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.bc > .bcRow.logRow > .bxLt {
  width: calc(100% - 50px);
}
.bc > .bcRow.logRow > .bxRt {
  width: 50px;
  text-align: right;
}
.bc > .bcRow.logRow > .bxRt > img {
  width: 30px;
  margin-top: -2px;
}
.bc > .bcRow.logRow > .bxLt > p {
  margin-bottom: 2px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
.bc > .bcRow.logRow > .bxLt > .tpRw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-normal);
  color: var(--primary-text-color);
}
.bc > .bcRow.logRow > .bxLt > .tpRw > .rt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.bc > .bcRow.logRow > .bxLt > .tpRw > .rt > span.unread {
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
  padding: 4px 6px;
  border-radius: 5px;
}
.bc > .bcRow.logRow > .bxLt > p.name {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-normal);
  color: var(--primary-text-color-light);
  margin-bottom: 5px;
}
.bc > .bcRow.logRow > .bxLt > p.msg {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-normal);
  width: 100%;
  word-break: break-word;
  margin-top: 10px;
}
