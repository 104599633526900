.gBCtRw.sndrEm > .lt,
.gBCtRw.sndrNm > .lt,
.gBCtRw.rplTo > .lt {
  flex: 1;
}
.gBCtRw.sndrEm > .lt > .desc,
.gBCtRw.sndrNm > .lt > .desc,
.gBCtRw.rplTo > .lt > .desc {
  margin-bottom: 10px;
}
