.gActionCard {
  display: flex;
  flex-direction: column;
  b1ackground-color: #0ca2aa;
}
.gActionCard > .top {
  margin-bottom: 5px;
}
.gActionCard > .top > p {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gActionCard > .top > p > i {
  margin-left: 5px;
}
.gActionCard > .middle > p,
.gActionCard > .middle > span {
  font-size: 40px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.gActionCard > .middle > span {
  font-size: 30px;
  color: var(--primary-text-color-light);
}

.gActionCard > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gActionCard > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
.gActionCard > .footer > a {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
