.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-title);
  border-bottom: 1px solid var(--generic-border-color);
  padding-bottom: 10px;
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.title {
  font-size: var(--font-size-primary);
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.title > .gRwBxLt.web {
  margin-right: 50px;
  width: 100px;
  flex: none;
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.title > .gRwBxRt {
  font-weight: var(--font-weight-normal);
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.title > .gRwBxRt.web {
  font-size: var(--font-size-primary);
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.title.seq {
  border: 0;
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.title.seq > .gRwBxLt {
  margin-bottom: 10px;
  margin-top: 40px;
  font-size: var(--font-size-title);
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw {
  justify-content: flex-start;
  margin-bottom: 40px;
  flex-direction: column;
  gap: 20px;
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw > .gRwBxLt {
  width: 160px!important;
  flex: none;
  color: var(--primary-text-color-light);
  font-weight: var(--font-weight-bold);
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw > .gRwBxRt {
  flex: 1;
  color: var(--primary-text-color);
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw > .gRwBxRt > .opnMdl {
  cursor: default;
}
.rgHstDtl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.kVRw > .gRwBxRt > p.flexRow > .ipCountry {
  margin-right: 8px;
}
