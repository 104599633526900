.sdMdl.bdcstSnglMdl > .ctnt {
  max-width: 1200px;
  width: 90%;
}
.sdMdl.bdcstSnglMdl > .ctnt > .gPWrp {
  max-width: 100%;
}

.gPageWrapOuterTitle.addEditBroadcast {
  justify-content: flex-start;
}
.gPageWrapOuterTitle.addEditBroadcast > a {
  margin-right: 10px;
  text-decoration: none;
}

.addEditBroadcast {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.gPageWrap.newBroadcast {
  w1idth: calc(100% - 330px);
  flex: 1;
  margin-bottom: 0;
}
.addEditBroadcast > .allBroadcastsWrap {
  width: 300px;
  padding: 0 0px 0 0px;
  margin-bottom: 80px;
  background-color: #F6F7FB;
  1overflow-y: scroll;
  overflow-x: hidden;
  1position: fixed;
  1right: 0;
  f1lex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #F6F7FB;
}
@media (max-width: 1023px) {
  .gPageWrap.newBroadcast {
    width: calc(100% - 230px);
  }
  .addEditBroadcast > .allBroadcastsWrap {
    width: 200px;
  }
}

.addEditBroadcast > .allBroadcastsWrap > .title {
  p1osition: fixed;
  w1idth: 260px;
  padding: 20px;
  background-color: #FFFFFF;
  height: 20px;
  font-size: 16px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.addEditBroadcast > .allBroadcastsWrap > .list {
  margin-top: 0px;
  overflow-y: scroll;
}
.addEditBroadcast > .allBroadcastsWrap > .list::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}

.addEditBroadcast > .allBroadcastsWrap > .list::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}

.addEditBroadcast > .allBroadcastsWrap > .list::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast {
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: var(--generic-border-radius);
  width: calc(100% - 60px);
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1023px) {
  .addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast {
    margin: 10px;
    width: calc(100% - 40px);
  }
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > .type > span {
  background-color: #ecf0f8;
  padding: 5px 12px;
  font-weight: var(--font-weight-bold);
  border-radius: var(--generic-border-radius);
  color: var(--gte-primary-color);
  font-size: 14px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > .type > span > img {
  margin-right: 2px;
}
@media (max-width: 1023px) {
  .addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top {
    flex-direction: column;
  }
}
/* .addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > span.type {
  background-color: #4F5969;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  color: #FFFFFF;
  font-size: 12px;
  margin-right: 5px;
} */
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > span.target {
  font-size: 14px;
  word-break: break-all;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast.showLess > .top > span.target {
  font-size: 14px;
  width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > .when {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
}
@media (max-width: 1023px) {
  .addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > .when {
    align-items: flex-start;
  }
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > .when > .day,
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > .when > .time {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .top > .when > .day {
  margin-bottom: 3px;
}

.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .name {
  margin-bottom: 0px;
  flex: 1;
  1white-space: nowrap;
  1text-overflow: ellipsis;
  1display: block;
  1overflow: hidden;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast.showLess > .name {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 100%;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .lineBreak {
  width: 100%;
  height: 1px;
  background-color: var(--gt-form-line-sep-color);
  margin: 20px 0;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .target {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .message {
  margin-bottom: 10px;
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--primary-text-color-light);
  word-break: break-all;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast.showLess > .message {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 100%;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .more {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}
.addEditBroadcast > .allBroadcastsWrap > .list > .singleBroadcast > .more > span > .fas {
  font-size: 18px;
  cursor: pointer;
}


.gPageWrap.newBroadcast > .gPageTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  1padding: 0 20px 38px 20px;
}
.gPageWrap.newBroadcast > .gPageTitle > a.all {
  margin-right: 10px;
}
.gPageWrap.newBroadcast > .gPageTitle > span > .fa {
  font-size: 18px;
}

.gPageWrap.newBroadcast > .lineBreak {
  height: 1px;
  margin: 20px 0;
  background-color: #e6e6e7;
}

.gPageWrap.newBroadcast > .statusRow {
  margin-bottom: 15px;
}

.gPageWrap > .cardWrap {
  1background-color: #F6F7FB;
  padding: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
}
.gPageWrap > .cardWrap.noBorder {
  border-bottom: 0;
}
.gPageWrap > .cardWrap > .titleRow {
  margin-bottom: 15px;
}
.gPageWrap > .cardWrap > .titleRow > p.title {
  color: var(--primary-text-color);
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}
.gPageWrap > .cardWrap > .titleRow > p.info {
  color: var(--primary-text-color-light);
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}

.gPageWrap > .cardWrap > .errorRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.gPageWrap > .cardWrap > .tabRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}
.gPageWrap > .cardWrap > .tabRow > .itemBox {
  margin-right: 10px;
  background-color: #f5f6f7;
  background-color: #E6E6E7;
  1font-weight: var(--font-weight-bold);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: var(--generic-border-radius);
  cursor: pointer;
}
.gPageWrap > .cardWrap > .tabRow > .itemBox.selected {
  background-color: #ecf0f8;
  color: var(--gte-primary-color);
  background-color: #4F5969;
  color: #FFFFFF;
}
.gPageWrap > .cardWrap > .tabRow > .itemBox > img {
  margin-right: 5px;
}

.gPageWrap > .cardWrap > .tabRow.daysRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gPageWrap > .cardWrap > .tabRow.daysRow.whenAllDays {

}
.gPageWrap > .cardWrap > .tabRow > .singleDay {
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #E6E6E7;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
}
.gPageWrap > .cardWrap > .tabRow > .singleDay.selected {
  background-color: #4F5969;
  color: #FFFFFF;
}
.gPageWrap > .cardWrap > .tabRow.daysRow.whenAllDays > .singleDay {
  1background-color: grey;
}


.gPageWrap > .cardWrap > .targetRow {
  max-width: 300px;
  margin-bottom: 0px;
}
.gPageWrap > .cardWrap > .targetRow > .targetBox {
}
.gPageWrap > .cardWrap > .targetRow > .targetBox > label {
  color: var(--primary-text-color);
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
}

.gPageWrap > .cardWrap > .targetDateRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.gPageWrap > .cardWrap > .targetDateRow > .itemBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  1max-width: 300px;
}
.gPageWrap > .cardWrap > .targetDateRow > .itemBox > .gLabelInfo {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--primary-text-color-light);
}
.gPageWrap > .cardWrap > .targetDateRow > .itemBox > .gInput {
  max-width: 300px;
}

.gPageWrap > .cardWrap > .targetTagRow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.gPageWrap > .cardWrap > .targetTagRow > .itemBox {
  flex: 1;
  display: flex;
  flex-direction: column;

}
.gPageWrap > .cardWrap > .targetTagRow > .itemBox > .gLabelInfo {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--primary-text-color-light);
}
.gPageWrap > .cardWrap > .targetTagRow > .itemBox > .gInput {
  max-width: 300px;
}
.gPageWrap > .cardWrap > .targetSelectedTagsRow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.gPageWrap > .cardWrap > .targetSelectedTagsRow > .itemBox {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  1max-width: 300px;
}
.gPageWrap > .cardWrap > .targetSelectedTagsRow > .itemBox > .singleTag {
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  background-color: #f5f6f7;
  color: var(--primary-text-color);
  1border: 1px solid var(--generic-border-color);
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-bottom: 10px;
}
.gPageWrap > .cardWrap > .targetSelectedTagsRow > .itemBox > .singleTag > span {
  margin-left: 10px;
  cursor: pointer;
}
.gPageWrap > .cardWrap > .targetSelectedTagsRow > .itemBox > .singleTag > span > .fa {
  color: var(--primary-text-color);
}

.gPageWrap > .cardWrap > .scheduleRow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.gPageWrap > .cardWrap > .scheduleRow > .itemBox {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.gPageWrap > .cardWrap > .scheduleRow > .itemBox.scheduleDateBox {
  padding-right: 50px;
}
.gPageWrap > .cardWrap > .scheduleRow > .itemBox.timeBox {
  1max-width: 300px;
}
.gPageWrap > .cardWrap > .scheduleRow > .itemBox.timeBox > .gLabelInfo {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--primary-text-color-light);
}
.gPageWrap > .cardWrap > .scheduleRow > .itemBox.timeBox > .gInput {
  max-width: 300px;
}

.gPageWrap > .cardWrap > .messageRow > .itemBox > .gTextarea > textarea {
  width: calc(100% - 26px);
  resize: vertical;
}
.gPageWrap > .cardWrap > .messageRow > .itemBox > .gError {
  margin-bottom: 10px;
}

.gPageWrap > .cardWrap > .personalizeRow > .personalizeBox {
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.gPageWrap > .cardWrap > .personalizeRow > .personalizeBox > label {
  padding-bottom: 15px;
}
.gPageWrap > .cardWrap > .personalizeRow > .itemBox.personalizeBox > .gLabelInfo {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--primary-text-color-light);
}
.gPageWrap > .cardWrap > .personalizeRow > .itemBox.personalizeBox > .gInput {
  max-width: 300px;
}

.gPageWrap > .cardWrap > .nameRow {
}
.gPageWrap > .cardWrap > .nameRow > .itemBox > .gError {
  margin-top: 10px;
}

.gPageWrap > .buttonsRow > button {
  padding: 8px 32px;
  text-align: center;
  background-color: var(--gte-primary-color);
  color: #FFFFFF;
  cursor: default;
  border-radius: var(--generic-border-radius);
}
.gPageWrap > .buttonsRow > button.disabled {
  background-color: grey;
  cursor: not-allowed;
}
.gPageWrap > .buttonsRow > button > .fa {
  margin-left: 5px;
}


.gPageWrap > .cardWrap > .templatesRow {
  display: flex;
  flex-wrap: wrap;
}
.gPageWrap > .cardWrap > .templatesRow > .lEntry {
  border: 1px solid #c1cdff;
  border-radius: 5px;
  background-color: #f6f7fb;
  color: #3152d5;
  font-size: 14px;
  padding: 7px 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  cursor: pointer;
}
.gPageWrap > .cardWrap > .templatesRow > .lEntry .messageTemplateTT {
  max-width: 300px;
}
.gPageWrap > .cardWrap > .templatesRow > .lEntry .messageTemplateTT span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  width: 100%;
}
