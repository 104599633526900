
.cTModal {
  padding: 30px;
}
.cTModal > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.cTModal > .title > span.close {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.cTModal > .title > span.close > img {
  margin-right: 5px;
  height: 14px;
}

.cModal.cpEtModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cTModal.copyTemplate {
  padding: 20px;
}
.cTModal.copyTemplate > .title {
  font-size: 20px;
  margin-bottom: 20px;
}
.cTModal.copyTemplate > .content {
}
.cTModal.copyTemplate > .content > .titleRow {
  margin-bottom: 10px;
}
.cTModal.copyTemplate > .content > .titleRow > .itemBox > .gLabel {
  font-size: 16px;
}
.cTModal.copyTemplate > .content > .nameRow {
  margin-bottom: 0px;
}
.gModal.copyTemplate > .content > .nameRow > .itemBox > .gInput {
  width: 100%;
}
.cTModal.copyTemplate > .content > .nameRow > .itemBox > .gInput > input {
  1font-size: 16px;
}


.cTModal.copyTemplate > .content > .saveRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: var(--font-size-primary);
}
.cTModal.copyTemplate > .content > .saveRow > button, .cTModal.addTemplate > .content > .saveRow > span.cancel {
  background-color: #FFFFFF;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid grey;
}
.cTModal.copyTemplate > .content > .saveRow > button.cancel:focus {
  background-color: var(--btn-cancel-focus-color);
}
.cTModal.copyTemplate > .content > .saveRow > span.cancel {
  background-color: #FFFFFF;
  border: 1px solid grey;
  width: 120px;
  text-align: center;
}
.cTModal.copyTemplate > .content > .saveRow > button.ok {
  background-color: var(--btn-color);
  color: #FFFFFF;
  width: 160px;
  margin-left: 10px;
}
.cTModal.copyTemplate > .content > .saveRow > button.ok:focus {
  b1ackground-color: var(--btn-focus-color);
}
.cTModal.copyTemplate > .content > .saveRow > button.ok > .fa {
  margin-left: 10px;
}
.cTModal.copyTemplate > .content > .saveRow  > button.ok.disabled {
  background-color: var(--btn-disabled-color);
  cursor: not-allowed;
}
