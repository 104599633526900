.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.hasTgl {
  margin-bottom: 0!important;
}
.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.hasTgl > .lt > p.title {
  font-weight: bold;
}
.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.frequency {
  margin-bottom: 0!important;
}
.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.medium {
  margin-bottom: 0!important;
}
.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.rating {
  margin-bottom: 0!important;
}

.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.sndNw {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid var(--generic-border-color);
  gap: 20px;
  border-bottom: 1px solid var(--generic-border-color);
}
.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.sndNw > .rt {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}
.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.sndNw > .rt > .selGrp {
  min-width: 200px;
}
.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.sndNw > .rt > .selGrp.weekly {
  min-width: 300px;
}

.gBCtRw.alrtOpts.inactive {
  pointer-events: none;
  opacity: 0.5;
}
.gBCtRw.alrtOpts > .lt > .alertOptions {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry > input {
  margin-right: 20px;
  font-size: 20px;
  height: 18px;
  width: 18px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry > .label {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-bottom: 20px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry.active > .label {
  color: var(--primary-text-color);
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry > .label > span.name {
  cursor: pointer;
  margin-right: 20px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry.disabled {
  pointer-events: none;
}

.gPWrp > .gBCtnt > .gBCtRw.alrtOpts > .lt > .gErr {
  font-weight: bold;
}
