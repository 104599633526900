.ntWrap {
  width: 400px;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}
.ntWrap > .hdr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--generic-border-color);
}
.ntWrap > .hdr > img {
  height: 20px;
}
