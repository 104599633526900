
.gPg.fdbPosPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.gPg.fdbPosPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .negativeTitle {
  margin-bottom: 20px;
  font-size: 14px;
}
.gPg.fdbPosPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .negativeTitle.highlight {
  background-color: yellow;
}
.gPg.fdbPosPg > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > textarea {
  width: 260px;
  height: 100px;
  padding: 10px;
  resize: vertical;
}
