.allRespTmplsMdl > .ctnt {
  padding: 0;
}

.selfResponseReviewModal > .content {
  max-width: 90%!important;
  width: 1100px!important;
  max-height: 95%!important;
}
.rtsBoxWrap {
  overflow-y: scroll;
  padding: 0!important;
}
.rtsBoxWrap::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.rtsBoxWrap::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.rtsBoxWrap::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.selfResponseReviewModal > .content > .rtsBoxWrap {
  min-height: 600px;
}
.reviewCard > .innerBox > .rtsBoxWrap {
  overflow: auto;
}
.rtsBoxWrap > .innerBox {
  1max-height: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.rtsBoxWrap > .innerBox > .reviewInfo {
  margin-bottom: 20px;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .ota {
  margin-right: 20px;
  width: 30px;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .ota > img {
  height: 30px;
}

.rtsBoxWrap > .innerBox > .reviewInfo > .header > .middle {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .middle > .authorBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .middle > .authorBox > .author {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary-text-color);
  margin-right: 20px;
  margin-top: 5px;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .middle > .authorBox > .rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .middle > .authorBox > .rating > .stars {
  margin-right: 10px;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .middle > .authorBox > .rating > .ratingValue {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .middle > .title {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}

.rtsBoxWrap > .innerBox > .reviewInfo > .header > .right {
  width: 160px;
  text-align: right;
}
.rtsBoxWrap > .innerBox > .reviewInfo > .header > .right > .reviewDate {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-right: 10px;
}

.rtsBoxWrap > .innerBox > .reviewInfo > .body {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-left: 50px;
}

.rtsBoxWrap > .innerBox > .lineBreak {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #e6e6e7;
}

.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .header > .title {
  font-size: var(--font-size-large);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.respTmplsBx > .gBCtnt > .innerBox> .responseTemplates > .header > .title > a {
  color: var(--gte-primary-color);
  margin-left: 20px;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .header > .close {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .header > .close > img {
  margin-right: 10px;
}

.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .centerBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .centerBody > a {
  color: var(--gte-primary-color);
}

.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body {
  display: flex;
  flex-direction: row;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .left {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .left > .categoryList {
  margin-top: 70px;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .left > .categoryList > .categoryName {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-bottom: 20px;
  cursor: pointer;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .left > .categoryList > .categoryName.active {
  color: var(--primary-text-color);
}

.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right > .search {
  margin-bottom: 20px;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right > .search > input {
  width: calc(100% - 40px);
  border-radius: var(--generic-border-radius);
  border: solid 1px #e6e6e7;
  padding: 8px 20px;
}
.respTmplsBx > .gBCtnt > .innerBox> .responseTemplates > .body > .right > .templateList {
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right > .templateList > .singleTemplate {
  padding: 12px;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right > .templateList > .singleTemplate:hover {
  background-color: rgba(242, 242, 245, 0.6);
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right > .templateList > .singleTemplate > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right > .templateList > .singleTemplate > .title > p {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right > .templateList > .singleTemplate > .title > span {
  cursor: pointer;
}
.respTmplsBx > .gBCtnt > .innerBox > .responseTemplates > .body > .right > .templateList > .singleTemplate > .msg {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}

.rtsBoxWrap > .innerBox > .responseBox {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
}
.rtsBoxWrap > .innerBox > .responseBox > .left {
  width: 160px;
  font-size: var(--font-size-large);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.rtsBoxWrap > .innerBox > .responseBox > .right {
  flex: 1;
}
.rtsBoxWrap > .innerBox > .responseBox > .right > textarea {
  width: calc(100% - 24px);
  padding: 12px;
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(37, 39, 52, 0.1);
  resize: vertical;
}

.rtsBoxWrap > .innerBox > .submitBox {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 20px;
}
.rtsBoxWrap > .innerBox > .submitBox > .buttonWrap {
  margin-left: 160px;
}
.rtsBoxWrap > .innerBox > .submitBox > .buttonWrap > .gButton {
}
