.gPg.fdbUplHstPg > .fdbUplHstBd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.gPg.fdbUplHstPg > .fdbUplHstBd > .lt {
  flex: 1;
  margin-right: 20px;
}
.gPg.fdbUplHstPg > .fdbUplHstBd > .rt {
  width: 245px;
}

.gPg.fdbUplHstPg > .fdbUplHstBd > .lt > .innerBox {
  display: flex;
  flex-direction: column;
}

.gPg.fdbUplHstPg > .fdbUplHstBd > .lt > .innerBox > .contentBox {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.gPg.fdbUplHstPg > .fdbUplHstBd > .lt > .innerBox > .contentBox > .listBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.uhRowWrap {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 14px 0;
  border-bottom: 1px solid #e6e6e7;
}
.uhRowWrap.more {
  padding-bottom: 0;
}
.uhRow {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
}
.uhRowWrap.more > .uhRow {
  padding-bottom: 24px;
}
.uhRow > .dateCreated,
.uhRow > .total,
.uhRow > .added,
.uhRow > .updated,
.uhRow > .errorCount,
.uhRow > .sent,
.uhRow > .sentBy,
.uhRow > .more {
  flex: 10;
  t1ext-align: center;
  margin-right: 5px;
}
.uhRow > .dateCreated {
  w1idth: 100px;
  flex: 4;
}
.uhRow > .total,
.uhRow > .added,
.uhRow > .updated,
.uhRow > .errorCount,
.uhRow > .sent {
  w1idth: 60px;
  flex: 4;
}
.uhRow > .more {
  width: 100px;
  flex: none;
  cursor: pointer;
  color: var(--gte-primary-color);
}
.uhRow > .more > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.uhRow > .more > span > i {
  margin-right: 5px;
  font-size: 10px;
}
.uhRow {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.uhRow.header {
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color-light);
}

.uhRowMore {
  display: flex;
  flex-direction: column;
  background-color: rgba(244, 244, 244, 0.52);
  padding: 20px;
}
.uhRowMore > .header,
.uhRowMore > .entry {
  display: flex;
  flex-direction: row;
}
.uhRowMore > .header {
  margin-bottom: 10px;
}
.uhRowMore > .entry {
  margin-bottom: 5px;
}
.uhRowMore > .header >  .name,
.uhRowMore > .header >  .email,
.uhRowMore > .header >  .sentAt {
  flex: 1;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
}
.uhRowMore > .entry >  .name,
.uhRowMore > .entry >  .email,
.uhRowMore > .entry >  .sent {
  flex: 1;
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
