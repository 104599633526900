.usrRstPas > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.byTwRw {
  gap: 10px;
  margin-top: 30px;
}
.usrRstPas > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.byTwRw > .gRwBxLt,
.usrRstPas > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.byTwRw > .gRwBxRt {
  flex: 1;
}
.usrRstPas > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.chkBxRw > .gRwBxLt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.usrRstPas > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.chkBxRw > .gRwBxLt > .gLbl {
  margin-bottom: 0;
  cursor: default;
}
