.gPg > .dtFltBx {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.gPg.mkOvr > .dtFltBx {
  margin-top: 0;
}
@media (max-width: 900px) {
  .gPg > .dtFltBx {
    flex-direction: column;
    align-items: center;
  }
}
.gPg > .dtFltBx > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 900px) {
  .gPg > .dtFltBx .left {
    margin-bottom: 20px;
  }
}
.gPg > .dtFltBx > .left > .title {
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gPg > .dtFltBx > .left > .typeSelector {
  width: 150px;
  margin-left: 20px;
}
.gPg > .dtFltBx > .dateFilter {
  width: 480px;
  display: flex;
  flex-shrink: row;
  justify-content: flex-end;
}
.gPg > .dtFltBx > .dateFilter > .dateFilterLastMonth {
  width: 200px;
  margin-right: 10px;
}
.mkOvr > .dtFltBx > .dateFilter > .dateFilterRange {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.gPg > .dtFltBx > .dateFilter > .dateFilterRange > .dateInput {
  width: 140px;
  border: solid 1px rgba(37, 39, 52, 0.1);
  border-radius: var(--generic-border-radius);
  padding: 7px 0;
}
.gPg > .dtFltBx > .dateFilter > .dateFilterRange > .dateInput.startDate {
  margin-right: 10px;
}
.gPg > .dtFltBx > .dateFilter > .dateFilterRange > .dateInput.endDate {
}


.gPg > .ovrChrtBx {
  margin-top: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  padding: 20px;
}
.gPg > .ovrChrtBx > .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg > .ovrChrtBx > .title > .left {
}
.gPg > .ovrChrtBx > .title > .left > i {
  margin-left: 5px;
}
.gPg > .ovrChrtBx > .title > .right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg > .ovrChrtBx > .title > .right > .entry {
  padding: 6px 8px;
  margin-left: 10px;
  border-radius: var(--generic-border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-text-color);
}
.gPg > .ovrChrtBx > .title > .right > .entry > input {
  margin-right: 8px;
  transform: scale(1.3);
}
.gPg > .ovrChrtBx > .title > .right > .entry.sent {
  background-color: #52ced5;
}
.gPg > .ovrChrtBx > .title > .right > .entry.delivered {
  background-color: #ffe662;
}
.gPg > .ovrChrtBx > .title > .right > .entry.open {
  background-color: #6295ff;
}
.gPg > .ovrChrtBx > .emailTypeSelect {
  width: 240px;
  margin-bottom: 20px;
}
.gPg > .ovrChrtBx > .total {
  font-size: 32px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gPg > .ovrChrtBx > .total > .entry {
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-right: 20px;
}
.gPg > .ovrChrtBx > .total > .entry.sent {
  border-bottom: 5px solid #52ced5;
}
.gPg > .ovrChrtBx > .total > .entry.delivered {
  border-bottom: 5px solid #ffe662;
}
.gPg > .ovrChrtBx > .total > .entry.open {
  border-bottom: 5px solid #6295ff;
}

.gPg > .ovrChrtBx > .noData {
  margin-top: 20px;
}


.gPg > .ovrMnthlyData {
  padding: 0 0px 0px 0px;
  margin-top: 50px;
}
.gPg > .ovrMnthlyData > .allWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.gPg > .ovrMnthlyData > .allWrap > .singleBox {
  background-color: #FFFFFF;
  padding: 12px;
  border-radius: var(--generic-border-radius);
  margin-bottom: 0px;
  width: calc(100%);
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #e6e6e7;
  flex-wrap: wrap;
  font-size: 14px;
  width: calc(100%);
}
.gPg > .ovrMnthlyData > .allWrap > .singleBox.header {
  background-color: #f6f7fb;
  box-shadow: none;
  padding: 12px 12px;
  /* color: #838e9f; */
}
.gPg > .ovrMnthlyData > .allWrap > .singleBox > .mdCol {
  flex: 1;
}