.wrp > .mb.rp > .mc > .gPnlLdng {
  left: 0;
  z-index: 1000;
  background-color: #05000038;
}
.wrp.sbClsd > .mb.rp > .mc > .gPnlLdng {
  padding-right: 80px;
}
.mb.rp > .mc > .gCw {
  display: flex;
  flex-direction: row;
}
