.gPScrlWrp.allHme {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100% - 40px);
}
.gPWrp.homeAllLocsPg {
  min-width: 700px;
}
.gPWrp.homeAllLocsPg > .gBCtnt {
  padding-bottom: 50px;
}
.gPWrp.homeAllLocsPg > .gBCtnt > .lRow {
  padding: 16px 12px;
  border: 1px solid #e6e6e7;
  border-bottom: 0px solid #e6e6e7;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.gPWrp.homeAllLocsPg > .gBCtnt > .lRow:last-child {
  border-bottom: 1px solid #e6e6e7;
}

.gPWrp.homeAllLocsPg > .gBCtnt > .lRow > .left {
  width: 200px;
}
.gPWrp.homeAllLocsPg > .gBCtnt > .lRow > .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}
.gPWrp.homeAllLocsPg > .gBCtnt > .lRow > .left > img {
  width: 100%;
}
.gPWrp.homeAllLocsPg > .gBCtnt > .lRow > .right > .name {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0 0 5px 0;
}
.gPWrp.homeAllLocsPg > .gBCtnt > .lRow > .right > .address {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gPWrp.homeAllLocsPg > .gBCtnt > .lRow > .right > .cntRw {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.gPWrp.homeAllLocsPg > .gBCtnt > .lRow > .right > .cntRw > span {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-primary);
}
