.gEmailSendingStatDetailCard {
  display: flex;
  flex-direction: column;
}
.gEmailSendingStatDetailCard > .top {
  margin-bottom: 5px;
}
.gEmailSendingStatDetailCard > .top > p {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gEmailSendingStatDetailCard > .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gEmailSendingStatDetailCard > .top > .left {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gEmailSendingStatDetailCard > .top > .left > i {
  margin-left: 5px;
}
.gEmailSendingStatDetailCard > .top > .right > img {
  height: 20px;
}

.gEmailSendingStatDetailCard > .middle {
  b1order-bottom: 1px solid #e6e6e7;
  padding-bottom: 10px;
}
.gEmailSendingStatDetailCard > .middle.noBorder {
  border-bottom: 0;
  padding-bottom: 10px;
}
.gEmailSendingStatDetailCard > .middle > .value {
  font-size: 40px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
  margin-bottom: 10px;
}
.gEmailSendingStatDetailCard > .middle > .bar {
  width: 100%;
  height: 54px;
  background-color: #eff5ff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: var(--generic-border-radius);
  margin: 20px 0;
}
.gEmailSendingStatDetailCard > .middle > .bar > .filled {
  background-color: #6295ff;
  border-top-left-radius: var(--generic-border-radius);
  border-bottom-left-radius: var(--generic-border-radius);
}
.gEmailSendingStatDetailCard > .middle > .labelValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.gEmailSendingStatDetailCard.small > .middle > .labelValue {
  margin-bottom: 0;
}
.gEmailSendingStatDetailCard > .middle > .labelValue > .label {
  color: var(--primary-text-color-light);
}
.gEmailSendingStatDetailCard > .middle > .labelValue > .value {
  color: var(--primary-text-color);
}



.gEmailSendingStatDetailCard > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
}
.gEmailSendingStatDetailCard > .footer > .labelValue {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  w1idth: 120px;
  margin-left: 50px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.gEmailSendingStatDetailCard > .footer > .labelValue > .label {
  color: var(--primary-text-color-light);
}
.gEmailSendingStatDetailCard > .footer > .labelValue > .value {
  color: var(--primary-text-color);
  margin-left: 10px;
}

.gEmailSendingStatDetailCard > .separator {
  background-color: #e6e6e7;
  width: 100%;
  height: 1px;
}
