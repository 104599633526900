.mb.rg > .mc > .gCw {
  display: flex;
  flex-direction: row;
}

.gPg.rgGnrt.onbrd {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gPg.rgGnrt.onbrd > .onbrdBx {
  font-size: var(--font-size-title);
}
.gPg.rgGnrt.onbrd > .onbrdBx > div > span {
  color: var(--gte-primary-color);
  cursor: pointer;
  font-weight: var(--font-weight-bold);
}
