.wOutWinMsg {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  background-color: #cfd0d4;
  background-color: var(--generic-border-color);
}
.wOutWinMsg > span > i {
  margin-right: 10px;
}
.wOutWinMsg > span.whatsappKnwMor {
  margin-left: 5px;
  color: var(--gte-primary-color);
  cursor: default;
  font-weight: var(--font-weight-bold);
}

.smB {
  display: flex!important;
  flex-direction: column;
  position: relative!important;
  transform: none!important;
}
.smB.inputExpanded {
  height: 300px!important;
}
.smB > .mtOp {
  /* height: 90px; */
  padding: 10px;
}
.smB > .mcAr {
  flex: 1;
  background-color: #FFFFFF;
  margin: 10px;
  border: 1px solid var(--generic-border-color);
  border-color: #BDBEC1;
  border-radius: 2px;
  position: relative;
}
.smB > .mcOp {
  padding: 0 10px 10px 0px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}
.smB > .mcOp > .lt {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  gap: 6px;
}
.smB > .mcOp > .lt > .opIt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 8px;
}
.smB > .mcOp > .lt > .opIt.img > div > span {
  padding: 4px 6px;
  border-radius: 3px;
}
.smB > .mcOp > .lt > .opIt.img > div > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.smB > .mcOp > .lt > .opIt.imgthb {
  border: 1px solid #d1c0c0;
  padding: 4px 16px;
  border-radius: var(--generic-border-radius);
  position: relative;
}
.smB > .mcOp > .lt > .opIt.imgthb > img {
  max-height: 20px;
  cursor: zoom-in;
}
.smB > .mcOp > .lt > .opIt.imgthb > .close {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
}

.smB > .mcOp > .rt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  gap: 6px;
}
.smB > .mcOp > .rt > .opIt {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.smB > .mcOp > .rt > .opIt.snd  > p {
  background-color: var(--gte-primary-color);
  padding: 5px 15px;
  border-radius: 5px;
  color: #FFFFFF;
  cursor: pointer;
  margin-bottom: 0;
}
.smB > .mcOp > .rt > .opIt.snd.inactive  > p {
  background-color: var(--btn-disabled-color);
}


.smB > .mcAr > textarea {
  width: calc(100% - 50px);
  width: 100%;
  height: 30px;
  height: 100%;
  resize: none;
  font-size: 14px;
  1border: 1px solid #CCC;
  1border-radius: 5px;
  padding: 10px 40px 10px 10px;
}
.smB > .mcAr > textarea {
  h1eight: 50px; /* 50px; */
}
.smB > .mcAr > textarea::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.smB > .mcAr > textarea::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.smB > .mcAr > textarea::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.smB > .mcAr > span.expInp {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
.smB > .mcAr > span.expInp > i {
  font-size: 20px;
  color: grey;
}

.smB > .rszIndc {
  position: absolute;
  right: calc(50% - 20px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1px;
  color: var(--resize-icon-color);
  margin-top: -4px;
}

.smB > .mtOp {
  display: flex;
  flex-direction: column;
}
.smB > .mtOp > .line1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.smB > .mtOp > .line2 {
  display: flex;
  flex-direction: row;
}
.smB > .mtOp > .line1 > .lt {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.smB > .mtOp > .line1 > .lt > span.icon {
  cursor: pointer;
}

.mtOpIt {
  border: 1px solid #c1cdff;
  border-radius: 5px;
  background-color: #f6f7fb;
  color: #3152d5;
  font-size: 14px;
  padding: 7px 12px;
  margin-right: 10px;
  margin-bottom: 0px;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mtOpIt > img {
  margin-right: 5px;
}
.sendMessageBoxWrap > .sendMessageBoxTop > .messageTemplateRowAction > .messageTemplateList > .lEntry img {
  height: 16px;
  margin-right: 5px;
}


/* ************** dropdown menu - Emoji Selector ********************** */

.opIt > .dd {
  position: relative;
  display: inline-block;
  margin-top: -1px;
}

.opIt > .dd > .dbtn {
  background-color: #FFFFFF;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.opIt > .dd > .dbtn > img {
  padding: 3px;
  border-radius: 3px;
}
.opIt > .dd > .dbtn > img.gbm {
  height: 23px;
}
.opIt > .dd > .dbtn > img.expedia {
  height: 23px;
}
.opIt > .dd > .dbtn > img:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.opIt > .dd > .dbtn > img.gbm:hover {
  background-color: #FFFFFF;
}
.opIt > .dd > .dbtn > i {
  padding: 3px 4px;
  border-radius: 3px;
}
.opIt > .dd > .dbtn.multi > i:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.opIt > .dd > .ddc {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.opIt > .dd > .ddc {
  left: -352px;
  top: -420px;
}

.opIt > .ddc p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.opIt > .ddc p:hover {background-color: #ddd; cursor: default;}

.opIt > .dd.open > .ddc {display: block;}

.opIt .emojiBox .emoji-mart {
  overflow-y: hidden;
  width: 320px!important;
  height: 300px!important;
}

/* ************************************ */


/* ************** dropdown menu - language Selector ********************** */

.opIt.trn > .dd {
  position: relative;
  display: inline-block;
  margin-top: -1px;
}

.opIt.trn > .dd > .dbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  padding: 0px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.opIt.trn > .dd > .ddc {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.opIt.trn > .dd > .ddc {
  left: -168px;
  top: -311px;
}

.opIt.trn > .dd > .ddc p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.opIt.trn > .dd > .ddc p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.opIt.trn > .dd.open .ddc {display: block;}


.lngSel > .search > input {
  padding: 10px;
  width: calc(100% - 20px);
  max-height: 40px;
}
.lngSel > .llWr {
  max-height: 260px;
  overflow-y: scroll;
}
.lngSel > .llWr::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.lngSel > .llWr::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.lngSel > .llWr::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.lngSel > .llWr > .recentlyUsedTitle {
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 800;
}
.lngSel > .llWr > .allTitle {
  padding: 10px 10px;
  font-size: 12px;
  font-weight: 800;
}
.lngSel > .llWr > .recentlyUsedList > p,
.lngSel > .llWr > .allList > p {
  font-weight: 300;
}

/* ************************************ */

/* ************** dropdown menu - Messape Templates Menu ********************** */

.mtMnWr > .dd {
  position: relative;
  display: inline-block;
  margin-top: -1px;
}

.mtMnWr > .dd > .dbtn {
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mtMnWr > .dd > .dbtn > span {
  padding: 2px 6px;
  border-radius: 3px;
  margin-top: 1px;
}
.mtMnWr > .dd > .dbtn > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.mtMnWr > .dd > .ddc {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 300px;
  max-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.mtMnWr > .dd > .ddc {
  left: 20px;
  top: -300px;
}

.mtMnWr > .ddc p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.mtMnWr > .ddc p:hover {background-color: #ddd; cursor: default;}

.mtMnWr > .dd.open > .ddc {display: block;}

.mtSel > .hdr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}
.mtSel > .hdr > .search > input {
  width: calc(100% - 20px);
  width: 160px;
  max-height: 40px;
}
.mtSel > .hdr > span {
  cursor: pointer;
}
.mtSel > .llWr {
  max-height: 260px;
  overflow-y: scroll;
  border-top: 1px solid var(--generic-border-color);
}
.mtSel > .llWr::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.mtSel > .llWr::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.mtSel > .llWr::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.mtMnRw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--generic-border-color);
}
.mtMnRw > .lt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}
.mtMnRw > .lt > img {
  height: 20px;
}
.mtMnRw > .lt > p {
  font-weight: var(--font-weight-bold);
}
.mtMnRw > .lt > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 220px;
}
.mtMnRw > .rt {
  display: none;
  color: var(--primary-text-color-light);
  flex-direction: row;
  gap: 5px;
}
.mtMnRw > .rt > span {
  padding: 5px;
  border-radius: 3px;
}
.mtMnRw > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.mtMnRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.mtMnRw:hover > .rt {
  display: flex;
}
/* ************************************ */


/* ************** dropdown menu - medium Selector ********************** */
.opIt.mdm > .dd {
  position: relative;
  display: inline-block;
  margin-top: -1px;
}

.opIt.mdm > .dd > .dbtn {
  background-color: #FFFFFF;
  color: #AAA;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.opIt.mdm > .dd > .ddc {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: auto;
}
.opIt.mdm > .dd > .ddc {
  left: -168px;
  top: -110px;
}
.opIt.mdm > .dd > .ddc.c2 {
  left: -178px;
  top: -90px;
}
.opIt.mdm > .dd > .ddc.c3 {
  left: -178px;
  top: -134px;
}
.opIt.mdm > .dd > .ddc.c4 {
  left: -178px;
  top: -234px;
}
.opIt.mdm > .dd > .ddc.c5 {
  left: -178px;
  top: -304px;
}

.opIt.mdm > .dd > .ddc p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.opIt.mdm > .dd > .ddc p > i {
  color: #AAA;
}
.opIt.mdm > .dd > .ddc p > img.expedia {
  height: 20px;
}

.opIt.mdm > .dd > .ddc p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.opIt.mdm > .dd.open .ddc {display: block;}


/* ************************************ */

.optOutBoxWrap {
  height: 60px;
  background-color: var(--generic-hover-bg-color);
  background-color: #fafbfd;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
