.cModal.imgPrv {
background-color: rgba(0,0,0,0.8);
}
.cModal.imgPrv > .ctnt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0!important;
}

.cModal.imgPrv > .ctnt > .imgPrv {
  margin: 0!important;
  padding: 20px!important;
  border-radius: 5px;
  height: calc(100% - 20px);
  width: 100%;
}

.cModal.imgPrv > .ctnt > .imgPrv > .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  height: 30px;
  color: #FFFFFF;
}
.cModal.imgPrv > .ctnt > .imgPrv > .title > span.close {
  color: #FFFFFF;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 8px;
  border-radius: var(--generic-border-radius);
}
.cModal.imgPrv > .ctnt > .imgPrv > .title > span.close:hover {
  background-color: grey;
  opacity: 0.9;
}
.cModal.imgPrv > .ctnt > .imgPrv > .imgBx {
  display: flex;
  flex-direction: column!important;
  justify-content: center;
  align-content: center;
  margin-bottom: 10px;
  height: calc(100% - 30px);
  width: 100%;
}
.cModal.imgPrv > .ctnt > .imgPrv > .imgBx > img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}
