.gPWrp.rgGen {
  max-width: none!important;
}
.gPWrp.rgGen > .gPTle > .rt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
  width: 90px;
}
.gPWrp > .gBCtnt > .gBCtRw.noBtmMrgn {
  margin-bottom: 0;
}
.gPWrp > .gBCtnt > .gBCtRw.noTpMrgn {
  margin-top: 0;
}
.gPWrp > .gBCtnt > .gBCtRw.usTpMrgn {
  margin-top: 20px;
}

.gPWrp > .gBCtnt > .gBCtRw.toneSel {
  justify-content: space-between;
}
.gPWrp > .gBCtnt > .gBCtRw.toneSel > .lt {
  width: 50%;
}
.gPWrp > .gBCtnt > .gBCtRw.toneSel > .rt {
  m1in-width: 300px;
  flex: 1;
}
.gPWrp > .gBCtnt > .gBCtRw.toneSel > .rt > .gErr {
  margin-top: 5px;
}

.gPWrp.rgGen > .gPTle > input {
  width: 80%;
  padding: 3px 3px;
  border-bottom: dashed;
  border-color: var(--generic-border-color);
}

.gPWrp > .gBCtnt > .prmptBx {
  background-color: #FFFFFF;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
}
.gPWrp > .gBCtnt > .prmptBx > .ttl {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
}
.gPWrp > .gBCtnt > .prmptBx > .ttl > .title {
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
}
.gPWrp > .gBCtnt > .prmptBx > .pmptList {
  display: flex;
  flex-direction: column;
}
.gPWrp > .gBCtnt > .prmptBx > .pmptList > .gBCtRw {
  background-color: var(--generic-bg-color);
  margin-bottom: 10px;
}
.gPWrp > .gBCtnt > .prmptBx > .pmptList > .gBCtRw:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.pmptList > .gBCtRw.respTplRw > .tp > .rt {
  gap: 10px;
}
