.gPg.fdbCustEmlPg > .innerBox > .topBox {
  margin-bottom: 20px;
}
.gPg.fdbCustEmlPg > .innerBox > .contentBox {
  display: flex;
  flex-direction: row;
}
.gPg.fdbCustEmlPg > .innerBox > .contentBox > .left {
  flex: 1;
  border-right: 1px solid #e6e6e7;
  padding: 0px;
}
.gPg.fdbCustEmlPg > .innerBox > .contentBox > .right {
  flex: 1;
  padding: 20px;
}
