.recentReviewCard {
  b1ox-shadow: var(--generic-card-shadow);
  border: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
  max-width: calc(50% - 10px);
}
.recentReviewCard > .innerBox {
  display: flex;
  flex-direction: column;
}

.recentReviewCard > .innerBox > .headerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.recentReviewCard > .innerBox > .headerBox > .left {
  width: 220px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.recentReviewCard > .innerBox > .headerBox > .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.recentReviewCard > .innerBox > .headerBox > .left > .ota {
  margin-right: 20px;
}
.recentReviewCard > .innerBox > .headerBox > .left > .ota > img {
  height: 30px;
}
.recentReviewCard > .innerBox > .headerBox > .left > .ratingBox {
  display: flex;
  flex-direction: column;
}
.recentReviewCard > .innerBox > .headerBox > .left > .ratingBox > .author {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.recentReviewCard > .innerBox > .headerBox > .left > .ratingBox > .rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.recentReviewCard > .innerBox > .headerBox > .left > .ratingBox > .rating > .stars {
  margin-right: 10px;
}
.recentReviewCard > .innerBox > .headerBox > .left > .ratingBox > .rating > .ratingValue {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.recentReviewCard > .innerBox > .headerBox > .left > .ratingBox > .reviewDate {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-top: 5px;
}

.recentReviewCard > .innerBox > .headerBox > .right > .reviewState {
  margin-right: 20px;
}
.recentReviewCard > .innerBox > .headerBox > .right > .reviewState > span {
  padding: 6px 15px 5px 15px;
  border-radius: 20px;
  border: solid 1px #e27b24;
  font-weight: 600;
}
@media (max-width: 1360px) {
  .recentReviewCard > .innerBox > .headerBox > .right > .reviewState {
    margin-right: 2px;
  }
  .recentReviewCard > .innerBox > .headerBox > .right > .reviewState > span {
    font-size: 12px;
    padding: 6px 4px 5px 4px;
  }
}
.recentReviewCard > .innerBox > .headerBox > .right > .reviewState.response_required > span {
  color: #e27b24;
}
.recentReviewCard > .innerBox > .headerBox > .right > .share {
  margin-right: 10px;
}
.recentReviewCard > .innerBox > .headerBox > .right > .share > span > i {
  font-size: 18px;
  color: var(--primary-text-color);
}

.recentReviewCard > .innerBox > .commentBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  padding: 0 20px 0 50px;
  1height: 50px;
}
.recentReviewCard > .innerBox > .commentBox > .title {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 5px;
}
.recentReviewCard > .innerBox > .commentBox > .comment {
  font-size: var(--font-size-primary);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 96%;
}

.recentReviewCard > .innerBox > .actionBox {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0px;
  padding-bottom: 0px;
}
.recentReviewCard > .innerBox > .actionBox > .buttonWrap {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}
.recentReviewCard > .innerBox > .actionBox > .buttonWrap > .gButton {
  display: flex;
  flex-direction: row;
}
.recentReviewCard > .innerBox > .actionBox > .buttonWrap > .gButton > a {
  text-decoration: none;
  color: #FFFFFF;
}
.recentReviewCard > .innerBox > .actionBox > .buttonWrap > .gButton > span {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.recentReviewCard > .innerBox > .actionBox > .buttonWrap > .gButton > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.recentReviewCard > .innerBox > .moreBox {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0px;
  padding-bottom: 0px;
}
.recentReviewCard > .innerBox > .moreBox > span > a {
  color: var(--gte-primary-color);
}
.recentReviewCard > .innerBox > .moreBox > span {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.recentReviewCard > .innerBox > .moreBox > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}



/* ************** more menu - dropdown menu ********************** */

.recentReviewCard .cMenu > .dropdown > .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 10px;
  font-size: 16px;
  border: none;
}

.recentReviewCard .cMenu > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.recentReviewCard .cMenu > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.recentReviewCard .cMenu > .dropdown > .dropdown-content {
  left: -155px;
}

.recentReviewCard .cMenu > .dropdown > .dropdown-content > .dcRow {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.recentReviewCard .cMenu > .dropdown > .dropdown-content > .dcRow:hover {
  background-color: var(--generic-hover-bg-color);
}
.recentReviewCard .cMenu > .dropdown > .dropdown-content > .dcRow > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.recentReviewCard .cMenu > .dropdown > .dropdown-content > .dcRow > span > img {
  height: 30px;
  margin-right: 16px;
}
.recentReviewCard .cMenu > .dropdown > .dropdown-content > .dcRow.gmail > span {
  padding: 4px 4px;
}
.recentReviewCard .cMenu > .dropdown > .dropdown-content > .dcRow.gmail > span > img {
  height: 16px;
}
.recentReviewCard .cMenu > .dropdown.moreMenu > .dropdown-content > .dcRow > span {
  padding: 4px 4px;
}

.recentReviewCard .cMenu > .dropdown > .dropdown-content a:hover {background-color: var(--generic-hover-bg-color);}

.recentReviewCard .cMenu > .dropdown.menuBox.open .dropdown-content {display: block;}

.recentReviewCard .cMenu > .dropdown.menuBox > .dropbtn {
  padding: 0px;
  background-color: #FFFFFF;
  cursor: pointer;
  1border-radius: 20px 16px;
}
.recentReviewCard .cMenu > .dropdown.menuBox > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);
  border-radius: var(--generic-border-radius);
}
.recentReviewCard .cMenu > .dropdown.shareMenu > .dropbtn {
  margin-right: 20px;
}
.recentReviewCard .cMenu > .dropdown.moreMenu > .dropbtn > span > img {
  width: 28px;
  border: 1px solid #e6e6e7;
  border-radius: var(--generic-border-radius);
}

/* ************************************ */
