.gcB {
  width: 100%;
  height: 100%;
  background-color: #F5F6FA;
  background-color: #FFFFFF;
  overflow-y: scroll;
}
.gcB::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.gcB::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.gcB::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.gcB > .inrB {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-direction: column-reverse;
  justify-content: flex-start;
  position: relative;
}

.gcB > .inrB > .isTypingWrap {
  padding-left: 70px;
}
.gcB > .inrB > .isTypingWrap > img {
  height: 8px;
}

.gcB > .inrB > .getMessagesInProgress {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: calc(50% - 20px);
  padding: 15px 20px;
  background-color: #f7f4f4c9;
  margin: 0;
}
.gcB > .inrB > .rcMB {
  display: flex;
  -webkit-flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  /* display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex; */
}
.gcB > .inrB > .rcMB > .avtB {
  margin-right: 10px;
  padding: 6px 12px;
  height: 40px;
  width: 40px;
  border: 1px solid var(--generic-border-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color-light);
}
.gcB > .inrB > .rcMB > .msB {
  1flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 66%;
}
.gcB > .inrB > .rcMB > .msB > .msg {
  background-color: cyan;
  padding: 12px;
  border-radius: 0 10px 10px 10px;
  margin-bottom: 5px;
  background-color: var(--message-received-bg-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gcB > .inrB > .rcMB > .msB > .msg > .inner {
  display: flex;
  flex-direction: column;
}
.gcB > .inrB > .rcMB > .msB > .msg .inner > .showMoreLess {
  text-align: right;
  color: var(--gte-primary-color);
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  cursor: default;
}
.gcB > .inrB > .rcMB > .msB > .msg > .inner > .orig.showLess {
  max-height: 200px;
  overflow-y: hidden;
}
.gcB > .inrB > .rcMB > .msB > .msg > .inner > .orig {
  word-break: break-all;
}
.gcB > .inrB > .rcMB > .msB > .msg > .inner > .orig > img {
  max-width: 100%;
}
.gcB > .inrB > .rcMB > .msB > .msg > .inner > .orig.email {
  display: flex;
  flex-direction: column;
}
.gcB > .inrB > .rcMB > .msB > .msg > .inner > .orig.email > span.subject {
  border-bottom: 1px solid var(--primary-text-color);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.gcB > .inrB > .rcMB > .msB > .msg > .inner > .by {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: grey;
}
.gcB > .inrB > .rcMB > .msB > .msg > .inner > .imgEntry {
  margin: 10px 0;
}

.gcB > .inrB > .rcMB > .msB > .msg > span.msAI {
  font-size: 14px;
  margin-left: 10px;
}
.gcB > .inrB > .rcMB > .msB > .mstB {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-content: center;
}
.gcB > .inrB > .rcMB > .msB > .mstB > .dateMessage {
  color: var(--primary-text-color-light);
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  1line-height: 1.82;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gcB > .inrB > .rcMB > .msB > .mstB > .dateMessage > img {
  height: 16px;
  margin-left: 5px;
}
.gcB > .inrB > .rcMB > .msB > .mstB > .dateMessage > img.gbm {
  height: 14px;
}
.gcB > .inrB > .rcMB > .msB > .mstB > .dateMessage > span {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gcB > .inrB > .rcMB > .msB > .mstB > .dateMessage > .mdm {
  margin-left: 10px;
}
.gcB > .inrB > .rcMB > .msB > .mstB > .dateMessage > .mdm > i {
  margin-right: 3px;
}

.gcB > .inrB > .rcMB > .loB {
  width: 500px;
  display: flex;
  height: 1px;
}
@media (max-width: 1366px) {
  .gcB > .inrB > .rcMB > .loB {
    width: 30%;
  }
}


.gcB > .inrB > .stMB {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 30px;
}
.gcB > .inrB > .stMB > .msB {
  1flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 200px;
}
.gcB > .inrB > .stMB > .msB > .msg {
  background-color: cyan;
  padding: 12px;
  border-radius: 10px 0 10px 10px;
  margin-bottom: 0px;
  background-color: var(--message-sent-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.gcB > .inrB > .stMB > .msB > .msg > .trnsEm {
}
.gcB > .inrB > .stMB > .msB > .msg > .top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gcB > .inrB > .stMB > .msB > .msg > .top > p {
  word-break: break-word;
  margin-right: 5px;
}
.gcB > .inrB > .stMB > .msB > .msg > .top > p.email {
  display: flex;
  flex-direction: column;
}
.gcB > .inrB > .stMB > .msB > .msg > .top > p.email > span.subject {
  border-bottom: 1px solid var(--primary-text-color);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.gcB > .inrB > .stMB > .msB > .msg > .top > p.email > .trnsEmPrv {
  cursor: default;
  color: grey;
}
.gcB > .inrB > .stMB > .msB > .msg > span.msAI {
  font-size: 14px;
  margin-left: 10px;
}
.gcB > .inrB > .stMB > .msB > .mstB {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}
.gcB > .inrB > .stMB > .msB > .mstB > .dateMessage {
  color: var(--primary-text-color-light);
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  1line-height: 1.82;
  letter-spacing: normal;
}
.gcB > .inrB > .stMB > .msB > .mstB > .msgStatusIcon {
  height: 12px;
  margin-left: 14px;
}
.gcB > .inrB > .stMB > .msB > .mstB > .msgStatusText {
  font-size: 11px;
  margin-left: 10px;
  margin-top: 1px;
  color: var(--primary-text-color-light);
}
.gcB > .inrB > .stMB > .msB > .mstB > span {
  margin-left: 10px;
}
.gcB > .inrB > .stMB > .msB > .mstB > span.retry {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  background-color: #e4dcdc;
  border-radius: 5px;
  cursor: default;
  font-size: 12px;
}
.gcB > .inrB > .stMB > .msB > .mstB > span.retry:hover {
  opacity: 0.9;
}
.gcB > .inrB > .stMB > .msB > .mstB > span > .fas {
  margin-left: 5px;
}
.gcB > .inrB > .stMB > .msB > .mstB > span.retry > .fas {
  margin-left: 5px;
  font-size: 12px;
}
.gcB > .inrB > .stMB > .msB > .mSntB {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-top: 2px;
  margin-right: 4px;
  color: var(--primary-text-color-light);
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
}
.gcB > .inrB > .stMB > .msB > .mSntB > img {
  height: 16px;
  margin-left: 15px;
  margin-right: 0px;
}
.gcB > .inrB > .stMB > .msB > .mSntB > img.gbm {
  height: 14px;
}
.gcB > .inrB > .stMB > .msB > .mSntB > i {
  height: 16px;
  margin-left: 15px;
  margin-right: 0px;
}
.gcB > .inrB > .stMB > .loB {
  width: 200px;
  display: flex;
  height: 1px;
}
@media (max-width: 1366px) {
  .gcB > .inrB > .stMB > .loB {
    width: 30%;
  }
}


/* ************** dropdown menu ********************** */

.gcB .msB > .msg .dd {
  position: relative;
  display: inline-block;
  margin-top: -5px;

  font-size: 16px;
  border: none;
}
.gcB .stMB > .msB > .msg .dbtn {
  background-color: var(--message-sent-bg-color);
  b1ackground-color: var(--generic-border-color);
}
.gcB .rcMB > .msB > .msg .dbtn {
  background-color: var(--message-received-bg-color);
}


.gcB .msB > .msg .ddc {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  -webkit-backdrop-filter: blur(19.6px);
  backdrop-filter: blur(19.6px);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.gcB > .inrB > .rcMB > .msB > .msg .ddc {
  left: -2px;
  top: 3px;
}
.gcB > .inrB > .rcMB.latest > .msB > .msg .ddc {
  left: 10px;
  top: 12px;
}
.gcB > .inrB > .rcMB.latest.menuSingleOption > .msB > .msg .ddc {
  left: -2px;
  top: -36px;
}
.gcB > .inrB > .stMB > .msB > .msg .ddc {
  left: -185px;
  top: 13px;
}
.gcB > .inrB > .stMB.latest > .msB > .msg .ddc {
  left: -185px;
  top: -36px;
}

.gcB .msB > .msg .ddc p {
  color: var(--primary-text-color);
  padding: 14px 20px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid var(--generic-border-color);
  font-size: var(--font-size-sub-mid-primary);
  margin-bottom: 0;
}

.gcB .msB > .msg .ddc p:hover {background-color: var(--generic-hover-bg-color); cursor: default;}

.gcB .msB > .msg .dd:hover .ddc {display: block;}


/* ************************************ */

.dtCh {
  margin: 10px 0 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dtCh > .line {
  height: 1px;
  background-color: var(--generic-border-color);
  flex: 1;
  margin: 0 10px;
}
.dtCh > p {
  color: var(--primary-text-color-light);
  font-size: 11px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
}

img.incomingMedia {
  max-width: 200px;
  cursor: zoom-in;
  margin-top: 10px;
}


.mdmBx {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
.mdmBx > img {
  height: 16px;
}
