.gPScrlWrp.jMsg {
  background-color: #fafafa;
}
.gPWrp.jMSms {
  background-color: #fafafa;
}
.gPWrp > .gBCtnt > .gBCtRw.jmEntRw {
  margin-bottom: 30px;
}
.pDefMsg {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--generic-border-color);
}
.pDefMsg > .pDRw {
  margin: 0 0 20px 0;
}
.pDefMsg > .pDRw.hdr {
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-semi-bold);
}
.pDefMsg > .pDRw.hasTgl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pDefMsg > .pDRw.hasTgl > .lt {
  font-size: var(--font-size-primary);
  color: var(--primary-text-color);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.pDefMsg > .pDRw.hasTgl > .lt > .title {
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
}
.pDefMsg > .pDRw.hasTgl > .lt > .desc {
  color: var(--primary-text-color-light);
}

.pDefMsg > .pDRw.msgRw {
  display: flex;
  flex-direction: column;
}
.pDefMsg > .pDRw.msgRw > .emojiRw {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.pDefMsg > .pDRw.msgRw > .emojiRw > .emojiBox {
  margin-top: -30px;
  margin-right: 20px;
}
.pDefMsg > .pDRw.msgRw > .emojiRw > .emojiBox > .emojiIcon > .fa {
  font-size: 18px;
}
.pDefMsg > .pDRw.msgRw > .segInfRw {
  margin-top: 10px;
}
.pDefMsg > .pDRw.msgRw > .segInfRw > span {
  margin-right: 5px;
}

.pDefMsg > .pDRw.msgRw > .atchRw {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}
.pDefMsg > .pDRw.msgRw > .atchRw> .uplThmb {
  border: 1px solid #d1c0c0;
  padding: 4px 16px;
  border-radius: var(--generic-border-radius);
  position: relative;
}
.pDefMsg > .pDRw.msgRw > .atchRw> .uplThmb > img {
  max-height: 20px;
  cursor: zoom-in;
}
.pDefMsg > .pDRw.msgRw > .atchRw> .uplThmb > .close {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
}

.pDefMsg > .pDRw.prslRw {
  display: flex;
  flex-direction: column;
}
.pDefMsg > .pDRw.prslRw > .tp {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.pDefMsg > .pDRw.prslRw > .hlpMsg {
  color: var(--primary-text-color-light);
}

.pDefMsg > .pDRw.sndAtRw > .tp {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 10px;
}
.pDefMsg > .pDRw.sndAtRw > .tp > .lt,
.pDefMsg > .pDRw.sndAtRw > .tp > .rt {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.pDefMsg > .pDRw.sndAtRw > .hlpMsg {
  color: var(--primary-text-color-light);
}

.pDefMsg > .pDRw.sndAftRw > .tp {
  margin-bottom: 10px;
}
.pDefMsg > .pDRw.sndAftRw > .tp > .lt {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.pDefMsg > .pDRw.sndAftRw > .tp > .lt > .hasError,
.pDefMsg > .pDRw.sndAtRw > .tp > .lt > .hasError {
  border: 1px solid var(--form-error-color);
  border-radius: 5px;
}
.pDefMsg > .pDRw.sndAftRw > .hlpMsg {
  color: var(--primary-text-color-light);
}

.pDefMsg > .pDRw.smsIsd > .tp {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/* Whatsapp */

.pDefMsg.pDefWhatsapp > .pDRw.msgRw > .selctBx {
  width: 50%;
  margin-bottom: 5px;
}



/* Rule Based */

.sdMdl.jMRS > .ctnt {
  max-width: 800px;
}

.gPWrp > .gBCtnt > .gBCtRw.jmRlBsdRw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}
.gPWrp > .gBCtnt > .gBCtRw.jmRlBsdRw > .lt > .title {
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
}
.gPWrp > .gBCtnt > .gBCtRw.jmRlBsdRw > .lt > .desc {
  color: var(--primary-text-color-light);
}
.gPWrp > .gBCtnt > .gBCtRw.jmRlBsdRw > .rt {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}
.gPWrp > .gBCtnt > .gBCtRw.jmRlBsdRw > .rt > span {
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: var(--generic-border-radius);
}
.gPWrp > .gBCtnt > .gBCtRw.jmRlBsdRw > .rt > span:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPWrp > .gBCtnt > .gBCtRw.jmRlBsdRw > .rt > span > img {
  height: 20px;
}
.sdMdl.jMRS > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.curSts {
  margin-top: 0;
}

/* Rule Based Sms */

.sdMdl.jMRSms > .ctnt,
.sdMdl.jMRWhatsapp > .ctnt,
.sdMdl.jMREmail > .ctnt {
  background-color: #fafafa;
}
.sdMdl.jMRSms > .ctnt > .gPScrlWrp,
.sdMdl.jMRWhatsapp > .ctnt > .gPScrlWrp,
.sdMdl.jMREmail > .ctnt > .gPScrlWrp {
  overflow-y: auto;
}
.sdMdl.jMRSms > .ctnt > .gPScrlWrp > .gPWrp  > .gPSubTle,
.sdMdl.jMRWhatsapp > .ctnt > .gPScrlWrp > .gPWrp  > .gPSubTle,
.sdMdl.jMREmail > .ctnt > .gPScrlWrp > .gPWrp  > .gPSubTle {
  flex-direction: column;
  gap: 10px;
}
.sdMdl.jMRSms > .ctnt > .gPScrlWrp > .gPWrp > .gBCtnt > .gBCtRw.curSts,
.sdMdl.jMRWhatsapp > .ctnt > .gPScrlWrp > .gPWrp > .gBCtnt > .gBCtRw.curSts,
.sdMdl.jMREmail > .ctnt > .gPScrlWrp > .gPWrp > .gBCtnt > .gBCtRw.curSts {
  margin-top: 0;
  margin-bottom: 10px;
}
