.gBCtRw.rvOtaChlRw {
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.gBCtRw.rvOtaChlRw > .lt > .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.gBCtRw.rvOtaChlRw > .lt > .title > img {
  height: 40px;
}
.gBCtRw.rvOtaChlRw > .rt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gBCtRw.rvOtaChlRw > .rt > .cta {
  cursor: default;
  font-weight: var(--font-weight-bold);
  padding: 5px;
  border-radius: var(--generic-border-radius);
}
.gBCtRw.rvOtaChlRw > .rt > .cta:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gBCtRw.rvOtaChlRw > .rt > .disable {
  color: var(--form-error-color);
}
.gBCtRw.rvOtaChlRw > .rt > i.fa-spin {
  margin-left: 10px;
}
.gBCtRw.rvOtaChlRw > .rt > .requested {
  text-align: right;
}
