.gPg > .dtFltBx {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 900px) {
  .gPg > .dtFltBx {
    flex-direction: column;
    align-items: center;
  }
}
.gPg > .dtFltBx > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 900px) {
  .gPg > .dtFltBx .left {
    margin-bottom: 20px;
  }
}
.gPg > .dtFltBx > .left > .title {
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
}
.gPg > .dtFltBx > .left > .typeSelector {
  width: 150px;
  margin-left: 20px;
}
.gPg > .dtFltBx > .dateFilter {
  width: 480px;
  display: flex;
  flex-shrink: row;
  justify-content: flex-end;
}
.gPg > .dtFltBx > .dateFilter > .dateFilterLastMonth {
  width: 200px;
  margin-right: 10px;
}
.mkOvr > .dtFltBx > .dateFilter > .dateFilterRange {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.gPg > .dtFltBx > .dateFilter > .dateFilterRange > .dateInput {
  width: 140px;
  border: solid 1px rgba(37, 39, 52, 0.1);
  border-radius: var(--generic-border-radius);
  padding: 7px 0;
}
.gPg > .dtFltBx > .dateFilter > .dateFilterRange > .dateInput.startDate {
  margin-right: 10px;
}
.gPg > .dtFltBx > .dateFilter > .dateFilterRange > .dateInput.endDate {
}


.gPg > .ovrChrtBx {
  margin-top: 20px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  padding: 20px;
}
.gPg > .ovrChrtBx > .title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg > .ovrChrtBx > .title > .left {
}
.gPg > .ovrChrtBx > .title > .left > i {
  margin-left: 5px;
}
.gPg > .ovrChrtBx > .title > .right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.gPg > .ovrChrtBx > .title > .right > .entry {
  padding: 6px 8px;
  margin-left: 10px;
  border-radius: var(--generic-border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-text-color);
}
.gPg > .ovrChrtBx > .title > .right > .entry > input {
  margin-right: 8px;
  transform: scale(1.3);
}
.gPg > .ovrChrtBx > .title > .right > .entry.sent {
  background-color: #52ced5;
}
.gPg > .ovrChrtBx > .title > .right > .entry.delivered {
  background-color: #ffe662;
}
.gPg > .ovrChrtBx > .title > .right > .entry.open {
  background-color: #6295ff;
}
.gPg > .ovrChrtBx > .emailTypeSelect {
  width: 240px;
  margin-bottom: 20px;
}
.gPg > .ovrChrtBx > .total {
  font-size: 32px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gPg > .ovrChrtBx > .total > .entry {
  font-size: 24px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-right: 20px;
}
.gPg > .ovrChrtBx > .total > .entry.sent {
  border-bottom: 5px solid #52ced5;
}
.gPg > .ovrChrtBx > .total > .entry.delivered {
  border-bottom: 5px solid #ffe662;
}
.gPg > .ovrChrtBx > .total > .entry.open {
  border-bottom: 5px solid #6295ff;
}

.gPg > .ovrChrtBx > .noData {
  margin-top: 20px;
}

.gPg > .emTypStBx {
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.gPg > .emTypStBx > .gTETypeStatusCard {
  margin-right: 0px;
  flex: 1;
}

.gPg > .emDetsBx {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.gPg > .emDetsBx > .gEmailSendingStatDetailCard {
  margin-right: 0px;
  flex: 1;
}



.gPg.trscEmHstyPg > .dtFltBx > .left {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.gPg.trscEmHstyPg > .dtFltBx > .left > p.count {
  color: var(--primary-text-color-light);
}

.gPg > .resGenHsty {
  padding: 0 20px 20px 20px;
}
.gPg > .resGenHsty > .actionsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 20px 0
}
.gPg > .resGenHsty > .actionsBox > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}
.gPg > .resGenHsty > .actionsBox > .right {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.gPg > .resGenHsty > .actionsBox > .left > .emailTypeSelect{
  width: 240px;
  margin-right: 20px;
}
.gPg > .resGenHsty > .actionsBox > .left > .searchBox {
  margin-bottom: 0px;
  flex: 1;
}
.gPg > .resGenHsty > .actionsBox > .left > .searchBox > input {
  width: calc(100% - 24px);
  padding: 9px 12px 8px 12px;
  border: var(--gt-form-input-border);
  border-radius: var(--gt-form-input-border-radius);
  margin-bottom: 1px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
}




.gPg > .resGenHsty > .allWrap {
  1padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.gPg > .resGenHsty > .allWrap > .singleBox {
  background-color: #FFFFFF;
  padding: 12px;
  border-radius: var(--generic-border-radius);
  margin-bottom: 0px;
  width: calc(100%);
  1margin: 10px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  b1ox-shadow: inset 0 0 2px #666;
  border: 1px solid #e6e6e7;
  flex-wrap: wrap;
  font-size: 14px;
  width: calc(100% - 24px);
}
.break {
  flex-basis: 100%;
  height: 0;
  display: none;
}
.gPg > .resGenHsty > .allWrap > .singleBox.header {
  background-color: #f6f7fb;
  box-shadow: none;
  padding: 12px 12px;
  color: #838e9f;
}
.gPg > .resGenHsty > .allWrap > .singleBox.header:hover {
  box-shadow: none;
}


.gPg > .resGenHsty > .allWrap > .singleBox:hover {
  b1ox-shadow: inset 0 0 3px #000;
  border: 1px solid #AAA;
}

.gPg > .resGenHsty > .allWrap > .singleBox > .partOne {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo {
  width: 20%;
  display: flex;
  flex-direction: row;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .type {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  f1lex: 1;
  width: 100px;
  min-width: 80px;
}

.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .type > span {
  background-color: #ecf0f8;
  padding: 5px 12px;
  font-weight: var(--font-weight-bold);
  border-radius: var(--generic-border-radius);
  color: var(--gte-primary-color);
  font-size: 14px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .type > span > img {
  margin-right: 2px;
}

.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .rvLoc {
  width: 100px;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .rvOta {
  width: 60px;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .rvOta > span > img {
  width: 40px;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .rvOta > span > img.web {
  width: 32px;
  margin-left: 5px;
  opacity: 0.6;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .rvRtng {
  width: 60px;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .rvTxt,
.gPg > .resGenHsty > .allWrap > .singleBox > .partOne > .genTxt {
  flex: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin-right: 30px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .when > .far {
  margin-right: 10px;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .when {
  flex: 2;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .when > span.md {
  margin-left: 5px;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu {
  width: 20px;
  display: flex;
  justify-content: center;
  margin-right: 2px;
  margin-top: -4px;
}

/* ************** dropdown menu ********************** */

.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .dropdown > .dropbtn,
.gPg > .resGenHsty > .allWrap > .singleBox > .dropdown > .dropbtn {
  1background-color: #4CAF50;
  1background-color: #FFFFFF;
  color: #AAA;
  1padding: 2px;
  font-size: 16px;
  border: none;
}
.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown > .dropbtn > img {
  height: 16px;
}
.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown > .dropbtn,
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu > .dropdown > .dropbtn {
  padding: 8px 10px;
  background-color: #FFFFFF;
  border-radius: var(--generic-border-radius);
}
.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown > .dropbtn:hover,
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu > .dropdown > .dropbtn:hover {
  background-color: #f7f7f9;
}

.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown,
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: -5px;
}

.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown > .dropdown-content,
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  -webkit-backdrop-filter: blur(19.6px);
  backdrop-filter: blur(19.6px);
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown > .dropdown-content {
  left: -60px;
  top: 25px;
}
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu > .dropdown > .dropdown-content {
  left: -140px;
  top: 25px;
}

.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown > .dropdown-content p,
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu > .dropdown > .dropdown-content p {
  color: var(--primary-text-color);
  padding: 14px 20px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid var(--generic-border-color);
  cursor: pointer;
  margin: 0;
}

.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: pointer;}
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu > .dropdown > .dropdown-content p:hover {background-color: var(--generic-hover-bg-color); cursor: pointer;}

.gPg > .resGenHsty > .allWrap.grid > .singleBox > .top > .oMenu > .dropdown .dropdown-content.open {display: block;}
.gPg > .resGenHsty > .allWrap > .singleBox > .partTwo > .oMenu > .dropdown .dropdown-content.open {display: block;}

.gPg > .resGenHsty > .allWrap > .singleBox > .top > .dropdo1wn:hover .dropdown-content {display: block;}

.gPg > .footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.gPg > .footer > .rpPagination {
}
.gPg > .footer > .perPageCtrl {
  width: 100px;
}
