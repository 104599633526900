.gPg.fdbPosPg  > .innerBox > .contentBox {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left {
  flex: 1;
  border-right: 1px solid #e6e6e7;
  padding: 0px;
  padding-right: 20px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .right {
  flex: 1;
  padding: 20px;
}

.gPageWrap.feedbackOtas {

}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .gSettingsRow.positiveTitle {
  margin-bottom: 20px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .gSettingsRow.positivePrimaryMessage {
  margin-bottom: 30px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .otasTitle {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: var(--font-weight-bold);
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .otaRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .otaRow > .left > p {
  margin-bottom: 5px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .otaRow > .left > p.title {
  font-size: 16px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .otaRow > .left > p.title > img {
  height: 30px;
  margin-right: 10px;
}

.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .gSettingsRow.gSaveButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .gSettingsRow.gSaveButton > p.error {
  color: red;
}


.gPg.fdbPosPg  > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .positiveTitle {
  margin-bottom: 20px;
  font-size: 16px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .positiveTitle.highlight {
  background-color: yellow;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .positivePrimaryMessage {
  margin-bottom: 20px;
  font-size: 14px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .positivePrimaryMessage.highlight {
  background-color: yellow;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .otaBox {
  padding: 10px;
  background-color: #e8e1e0;
  border-radius: var(--generic-border-radius);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.gPg.fdbPosPg  > .innerBox > .contentBox > .right > .emailPreviewWrap > .emailPreviewBox > .emailBodyBox > .middleBox > .otaBox > img {
  height: 40px;
  margin: 0 10px;
}

.gPg.fdbPosPg  > .innerBox > .contentBox > .left > form > .enabledRow {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 20px);
  margin-bottom: 30px;
}
