.gPScrlWrp.helpGuide > .gPWrp > .gPTle.lvCta > span {
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  background-color: var(--sidebar-main-menu-active-bg);
  cursor: default;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
}
