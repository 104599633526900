/* ******************* Modal ******************* */
.sdMdl.aiMdlLv > .ctnt {
  max-width: 650px;
}
.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt {
  align-items: flex-start;
}

.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .dndBox::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .dndBox::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .dndBox::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}


.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.convRw {
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--generic-border-color);
}
.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.convRw.convIpt {
  border-bottom: 0;
  margin-top: 20px;
}

.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gPSubTle.clrConv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gPSubTle.clrConv > span {
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
  background-color: var(--sidebar-main-menu-active-bg);
  cursor: default;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
}

.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.convRw > .gRwBxLt {
  width: 100px;
  flex: none;
}
.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.convRw > .gRwBxRt {
  flex: 1;
}
.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.convRw > .gRwBxLt > .gLbl > span {
  font-weight: var(--font-weight-semi-bold);
}
.sdMdl.aiMdlLv > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.convIpt > .gRwBxLt {
  flex: 1;
}
