.gPWrp.allGrpsPg {
  max-width: 1100px;
}
.gPWrp.allGrpsPg > .gBCtnt > .gBCtRw > .gRwBxLt > .gIpt {
  position: relative;
}
.gPWrp.allGrpsPg > .gBCtnt > .gBCtRw > .gRwBxLt > .gIpt > img.clear {
  right: 7px;
  position: absolute;
  top: 11px;
}

.gPWrp.allGrpsPg > .gBCtnt > .lRow {
  padding: 16px 12px;
  border-bottom: 1px solid #e6e6e7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow:hover {
  background-color: #EEE;
}

.gPWrp.allGrpsPg > .gBCtnt > .lRow > .shrGrp {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  font-weight: var(--font-weight-semi-bold); 
}

.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .left {
  width: 200px;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .right {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .left > .name > i {
  margin-left: 5px;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .right > .entry {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .right > .entry > .name {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0 0 5px 0;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .right > .entry > .title {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .right > .entry > .title > i {
  margin-left: 5px;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .right > .entry > .value > span > i {
  margin-right: 5px;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .share {
  width: 240px;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .share > .tp {
  display: flex;
  flex-direction: row;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .share > .tp > span {
  padding: 3px;
  border-radius: var(--generic-border-radius);
  display: flex;
  flex-direction: row;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .share > .tp > span:hover {
  background-color: #FFFFFF;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .share > .bt {
  display: flex;
  flex-direction: column;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .share > .bt > span {
  word-break: break-all;
}

.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .share > .sharedBy {
  display: flex;
  flex-direction: column;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .share > .sharedBy > span {
  word-break: break-all;
}

.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .edit {
  padding: 3px;
  border-radius: var(--generic-border-radius);
  display: flex;
  flex-direction: row;
}
.gPWrp.allGrpsPg > .gBCtnt > .lRow > .tp > .edit:hover {
  background-color: #FFFFFF;
}
