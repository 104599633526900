.businessHours {
  margin-top: 20px;
}
.bhRow {
  display: flex;
  flex-direction: column;
  h1eight: 38px;
  margin-bottom: 10px;
}
.bhRow > .top {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
}
.bhRow > .top > .dayBox {
  width: 130px;
  display: flex;
  flex-direction: row;
}
.bhRow > .top > .startTimeBox {
  width: 200px;
  margin-right: 10px;
}
.bhRow > .top > .endTimeBox {
  width: 200px;
}

.bhRow > .top > .dayBox > input {
  margin-right: 10px;
  font-size: 20px;
  height: 18px;
  width: 18px;
}
.bhRow > .top > .dayBox > .gLabel {
  margin-bottom: 0;
  font-size: 14px;
  cursor: pointer;
}

.bhRow > .gErr {
  margin-left: 130px;
}
