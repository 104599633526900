.dtTimeCtm {
  display: flex;
  flex-direction: row;
}
.dtTimeCtm > .dtTimePrt {
  width: 120px;
}
.dtTimeCtm > .dtTimeSepPrt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  line-height: 1;
}
