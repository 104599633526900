.gPScrlWrp.rWBasic {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}
.gPScrlWrp.rWBasic > .gPWrp {
  flex: 1;
  overflow-y: scroll;
}
.gPScrlWrp.rWBasic > .gPWrp::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #ffffff;
}
.gPScrlWrp.rWBasic > .gPWrp::-webkit-scrollbar
{
  height: 6px;
	width: 5px;
	background-color: #ffffff;
}
.gPScrlWrp.rWBasic > .gPWrp::-webkit-scrollbar-thumb
{
  o1utline: 1px solid slategrey;
	border-radius: 10px;
	background-color: #BDBEC1;
}

.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt {
  width: 100%;
}

.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .maxLen {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: var(--font-size-sub-primary);
}
.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .gIpt,
.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .rt > .gIpt {
  margin-top: 4px;
}



/* Checkbox */

.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .chkBxRw {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .chkBxRw.nested {
  margin-left: 26px;
}
.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .chkBxRw > input.checkbox {
  margin-right: 10px;
  font-size: 20px;
  height: 18px;
  width: 18px;
}
.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .chkBxRw > .gLabel {
  color: var(--primary-text-color-light);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .chkBxRw > .gLabel > .fa-info-circle {
  margin-left: 5px;
}
.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .chkBxRw > .gLabel > .gIpt > .inputValue {
  width: 50px;
  font-size: 14px;
  border: var(--gt-input-border);
  border-radius: var(--generic-border-radius);
  padding: 5px 2px 6px 6px;
  margin-bottom: 1px;
  color: var(--primary-text-color-light);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-left: 5px;
  margin-top: -5px;
}
.gPScrlWrp.rWBasic > .gPWrp > .gBCtnt > .gBCtRw > .lt > .chkBxRw.selected > .gLabel > input.inputValue {
  color: var(--primary-text-color);
}
