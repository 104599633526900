.wrp > .mb.eg > .mc > .gPnlLdng {
  left: 0;
  z-index: 1000;
  background-color: #05000038;
}
.mc > .gCw {
  height: 100%;
}
.mb.eg > .mc > .gCw {
  display: flex;
  flex-direction: row;
}

.mb > .rsb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: scroll;
  height: 100%;
}
.mb > .rsb::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.mb > .rsb::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.mb > .rsb::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.mb > .rsb > .lcUrLst {
  display: flex;
  flex-direction: column;
  padding-top: 7px;
  gap: 5px;
}
.mb > .rsb > .lcUrLst > a {
  padding: 5px;
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--generic-border-color);
  background-color: #FFFFFF;
  position: relative;
  text-align: center;
  width: 36px;
  height: 36px;
}
.mb > .rsb > .lcUrLst > a > .uc {
  background-color: var(--gte-primary-color);
  background-color: #FF802B;
  color: #FFFFFF;
  border-radius: 10px;
  font-size: 10px;
  position: relative;
  top: -27px;
  right: -22px;
  height: 9px;
  width: 9px;
  color: #FFFFFF;
  border-radius: 10px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
