.sdMdl.mtGen.dgPg > .ctnt {
  background-color: var(--generic-bg-color);
}
.sdMdl.mtGen.dgPg > .ctnt > .gPWrp {
  max-width: 100%;
  background-color: var(--generic-bg-color);
}

.gPWrp.dgRmPgSingle > .gBCtnt {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt {
  flex: 1;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .rt {
  min-width: 500px;
  max-width: 500px;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .gBCtRw {
  margin-bottom: 0;
  background-color: var(--generic-bg-color);
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .gBCtRw.titleRow {
  margin-bottom: 20px;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .gBCtRw.chsSectionRw {
  margin-top: 30px;
  margin-bottom: 30px;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .gBCtRw.chsSectionRw > .gRwBxLt {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.dgPgSecs > div[data-rbd-droppable-id=droppable] {
  width: auto!important;
}

.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .dgPgSecs .gBCtRw.imgRw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  background-color: #FFFFFF;
  padding: 20px;
  width: 100%;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .dgPgSecs .gBCtRw.imgRw > .lt {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .dgPgSecs .gBCtRw.imgRw > .lt > img {
  cursor: zoom-in;
  max-width: 100%;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .dgPgSecs .gBCtRw.imgRw > .lt > .close {
  position: relative;
  right: 0px;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .dgPgSecs .gBCtRw.imgRw > .rt {
  width: 400px;
}
.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .dgPgSecs .gBCtRw.imgRw > .rt > .dndBox {
  border: 1px dashed #d3d3d6;
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.gPWrp.dgRmPgSingle > .gBCtnt > .lt > .dgPgSecs .gBCtRw.imgRw > .delCtrl {
  position: relative;
}


.gPWrp.dg1RmPgSingle > .gBCtnt > .rt > .dgPgPrvBx {
  padding: 20px;
}
.gPWrp.dg1RmPgSingle > .gBCtnt > .rt > .dgPgPrvBx > .ttl {
  font-size: 20px;
  margin-bottom: 20px;
}
.gPWrp.dg1RmPgSingle > .gBCtnt > .rt > .dgPgPrvBx > .txtBx {
  margin-bottom: 20px;
}
.gPWrp.dg1RmPgSingle > .gBCtnt > .rt > .dgPgPrvBx > .imgBx {
  text-align: center;
  margin-bottom: 20px;
}
.gPWrp.dg1RmPgSingle > .gBCtnt > .rt > .dgPgPrvBx > .imgBx > img {
  max-width: 100%;
}


.dgRmPgSingle > .gBCtnt > .lt > .gBCtRw.curSts {
  font-weight: var(--font-weight-bold);
  margin-top: 2px;
  margin-bottom: 10px;
}
.dgRmPgSingle > .gBCtnt > .lt > .gBCtRw.curSts > .success {
  color: var(--form-success-color);
}
.dgRmPgSingle > .gBCtnt > .lt > .gBCtRw.curSts > .error {
  color: var(--form-error-color);
}


.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}
