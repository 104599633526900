.gNumberCard {
  display: flex;
  flex-direction: column;
}
.gNumberCard > .top {
  margin-bottom: 5px;
}
.gNumberCard > .top > p {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gNumberCard > .top > p > i {
  margin-left: 5px;
}
.gNumberCard > .middle > p,
.gNumberCard > .middle > span {
  font-size: 40px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.gNumberCard > .middle > span {
  color: var(--primary-text-color-light);
}
.gNumberCard > .middle > p.cmpVal {
  font-size: 18px;
}
.gNumberCard > .middle > p.cmpChng {
  font-size: 14px;
}

.gNumberCard > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gNumberCard > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
.gNumberCard > .footer > a {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
