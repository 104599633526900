.wrp > .mb.mk > .mc > .gPnlLdng {
  left: 0;
  z-index: 1000;
  background-color: #05000038;
}
.mb.mk > .mc > .gCw {
  display: flex;
  flex-direction: row;
}

.enblPrdWrap > p > .cstFdbkContact {
  color: var(--gte-primary-color);
  cursor: default;
}
