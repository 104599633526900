.sdMdl.searchBx > .ctnt > .gPWrp > .bc {
  display: flex;
  flex-direction: column;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.srchIptRw {
  margin-bottom: 0;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.srchIptRw > .bxLt {
  flex: 1;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.srchIptRw > .bxLt > .srchInPrg {
  position: absolute;
  right: 10px;
  top: 28px;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.advActRw {
  margin-top: 0;
  margin-bottom: 0;
  justify-content: flex-end;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.advActRw > .bxLt {
  flex: 1;
  text-align: right;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.advActRw > .bxLt > span {
  cursor: default;
  color: var(--gte-primary-color);
  font-size: var(--font-size-sub-mid-primary);
  font-weight: var(--font-weight-bold);
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.advActRw > .bxLt > span > i {
  margin-left: 5px;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.advRw {
  margin-bottom: 0;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .srchRWrp {
  position: relative;
  margin-top: 0;
  box-shadow: none;
  max-height: none;
  z-index: auto;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .srchRWrp > .srchRInfo {
  border-bottom: 1px solid var(--generic-border-color);
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .srchRWrp > .srchRInfo > input {
  width: 100%;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .srchRWrp > .rslRw {
  border-bottom: 1px solid var(--generic-border-color);
}

.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.rslTypRw {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.rslTypRw > .entry {
  border: 1px solid var(--generic-border-color);
  border-radius: var(--generic-border-radius);
  padding: 5px 7px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  cursor: default;
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.rslTypRw > .entry:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.sdMdl.searchBx > .ctnt > .gPWrp > .bc > .bcRow.rslTypRw > .entry.active {
  background-color: var(--sidebar-main-menu-active-bg);
}
