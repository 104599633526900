.reset-msg-logging-in {
  margin-bottom: 25px;
  font-weight:100;
  font-size:18px;
  color:orange;
  width:100%;
  float: left;
}
.reset-msg-error {
  margin-bottom: 25px;
  font-weight:100;
  font-size:18px;
  color: var(--form-error-color);
  width:100%;
  float: left;
}
.close-all-snack {
    color: blue;
    width: 100px;
    display: inline;
    float: right;
    cursor: pointer;
    outline: none;
}
.snack-clear-all {
  position: absolute;
  background-color: #333333;
  left: 30px;
  padding: 2px 7px;
  border-radius: 3px;
  color: #FFFFFF;
  cursor: pointer;
}
