.wrp > .mb.st > .mc > .gPnlLdng {
  left: 0;
  z-index: 1000;
  background-color: #05000038;
}
.mb.st > .mc {
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: flex-start;
}
.mb.st > .mc > .sbi {
  transition: all 100ms linear;
  width: 300px;
  height: 100%;
  background-color: var(--sidebar-main-menu-bg);
  border-right: 1px solid var(--generic-border-color);
  display: flex;
  flex-direction: column;
}
.mb.st > .mc > .sc {
  flex: 1;
  position: relative;
}

.mb.st > .mc > .sCw {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mb.st > .mc > .sCw.sh {
  align-items: center;
}
.sCw > .hdr {
  padding: 0 0 30px 0;
}
.sCw.en > .hdr > h4 {
  margin-bottom: 20px;
}
.sCw.sh > .bd {
  max-width: 1000px;
}

.hdr > h4 {
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
}
