.gOtaSplitCard {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}
.gOtaSplitCard > .top {
  margin-bottom: 5px;
}
.gOtaSplitCard > .top > p {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin: 0;
}
.gOtaSplitCard > .top > p > i {
  margin-left: 5px;
}
.gOtaSplitCard > .total {
  margin-bottom: 30px;
}
.gOtaSplitCard > .total > p,
.gOtaSplitCard > .total > span {
  font-size: 40px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.gOtaSplitCard > .total > span {
  color: var(--primary-text-color-light);
}
.gOtaSplitCard > .total > p.cmpVal {
  font-size: 18px;
}
.gOtaSplitCard > .total > p.cmpChng {
  font-size: 14px;
}

.gOtaSplitCard > .middle > .entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.gOtaSplitCard > .middle > .entry > .logo {
  width: 50px;
}
.gOtaSplitCard > .middle > .entry > .logo > img {
  height: 30px;
}
.gOtaSplitCard > .middle > .entry > .label {
  flex: 2;
}
.gOtaSplitCard > .middle > .entry > .value {
  flex: 1;
  text-align: right;
}
.gOtaSplitCard > .middle > .entry > .label,
.gOtaSplitCard > .middle > .entry > .value {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin: 0;
}
.gOtaSplitCard > .middle > .entry > .value {
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-size: 16px;
}
.gOtaSplitCard > .middle > .entry > .value > p.cmpVal {
  font-size: 14px;
}
.gOtaSplitCard > .middle > .entry > .value > p.cmpChng {
  font-size: 12px;
}

.gOtaSplitCard > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.gOtaSplitCard > .footer > p {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
.gOtaSplitCard > .footer > a {
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gte-primary-color);
  margin: 0;
  text-align: right;
  cursor: pointer;
}
