/* ******************* Modal ******************* */

.emailBuilderTipsModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emailBuilderTipsModal > .contentWrap {
  max-width: 80%;
  width: 80%;
  max-height: 80%;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
  1overflow-y: scroll;
  1overflow-x: hidden;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.emailBuilderTipsModal > .contentWrap > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
}
.emailBuilderTipsModal > .contentWrap > .close > .fas {
  font-size: 20px;
}

.emailBuilderTipsModal > .contentWrap > .content {
  padding: 30px;
}
.emailBuilderTipsModal > .contentWrap > .content > .title {
  font-size: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.emailBuilderTipsModal > .contentWrap > .content > .body {
  display: flex;
  flex-direction: column;
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .rowOne {
  font-size: 16px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .rowInfo {
  font-size: 16px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .rowNote {
  font-size: 16px;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  background-color: var(--alert-note-bg-color);
  padding: 12px;
  border-radius: var(--generic-border-radius);
  color: var(--alert-note-text-color);
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .actions {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .actions > button {
  padding: 8px 32px;
  border-radius: var(--generic-border-radius);
  font-size: 13px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .actions > button.cancel {
  border: 1px solid #DDD;
  background-color: #FFFFFF;
  margin-right: 16px;
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .actions > button.ok {
  border: 1px solid var(--form-error-color);
  background-color: var(--form-error-color);
  color: #FFFFFF;
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .actions > button.ok > .fa {
  margin-left: 10px;
}
.emailBuilderTipsModal > .contentWrap > .content > .body > .actions > button.ok.secondary {
  margin-left: 16px;
}
