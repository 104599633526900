.gPWrp > .gBCtnt > .gBCtRw.revLnk > .lt {
  flex: 1;
}
.gBCtRw.revLnk > .lt > .gIpt {
  margin-top: 16px;
  width: 50%;
}
.pDefMsg.automatedNonBusinessHoursMessage {
  border: 0;
  background-color: #FFFFFF;
  padding-top: 0;
}
.pDefMsg.automatedNonBusinessHoursMessage > .pDRw >.gLbl {
  color: var(--primary-text-color-light);
}
.gPWrp > .gBCtnt > .gBCtRw.whtsapMsgTtl {
  margin-bottom: 0px!important;
  padding-bottom: 0;
}
.gBCtRw.esclAlrt.withValue,
.gBCtRw.esclAlrtTime {
  margin-bottom: 0!important;
}
.gBCtRw.esclAlrtTime > .lt,
.gBCtRw.esclAlrtEmail > .lt {
  flex: 1;
}
.gBCtRw.esclAlrtTime > .lt > .esclAlrtSelect,
.gBCtRw.esclAlrtEmail > .lt > .gIpt {
  width: 50%;
}
