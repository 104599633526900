.gPWrp.setRepAlrt > .gBCtnt > .gBCtRw.hasTgl {
  margin-bottom: 0!important;
}

.gBCtRw.alrtOpts.inactive {
  pointer-events: none;
  opacity: 0.5;
}
.gBCtRw.alrtOpts > .lt > .alertOptions {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry > input {
  margin-right: 20px;
  font-size: 20px;
  height: 18px;
  width: 18px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry > .label {
  font-size: var(--font-size-primary);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color-light);
  margin-bottom: 20px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry.active > .label {
  color: var(--primary-text-color);
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry > .label > span.name {
  cursor: pointer;
  margin-right: 20px;
}
.gBCtRw.alrtOpts > .lt > .alertOptions > .entry.disabled {
  pointer-events: none;
}
