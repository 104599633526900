/* ******************* Modal ******************* */
.sdMdl.wCTpcDnd > .ctnt {
  max-width: 450px;
}
.sdMdl.wCTpcDnd > .ctnt > .gPWrp > .gBCtnt {
  align-items: flex-start;
}
.sdMdl.wCTpcDnd > .ctnt > .gPWrp > .gBCtnt > .dndBox {
  position: relative;
  overflow-y: scroll;
}

.sdMdl.wCTpcDnd > .ctnt > .gPWrp > .gBCtnt > .dndBox::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.sdMdl.wCTpcDnd > .ctnt > .gPWrp > .gBCtnt > .dndBox::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.sdMdl.wCTpcDnd > .ctnt > .gPWrp > .gBCtnt > .dndBox::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}


.roEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 10px;
  cursor: pointer;
}
.roEntry.selected {
  background-color: #f6f7fb;
}
.roEntry:hover {
  background-color: #f6f7fb;
}
.roEntry > .left {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.roEntry > .right {
  width: 40px;
  text-align: right;
}
.roEntry > .left > img {
  height: 16px;
  margin-right: 5px;
}
.roEntry > .left > img.dragIcon {
  margin-right: 20px;
}
