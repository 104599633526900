.sCw.sh > .bd {
  font-size: 16px;
}
.sCw.sh > .bd > .grd {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.sCw.sh > .bd > .grd > .gi {
  padding: 20px;
  border: 1px solid var(--generic-border-color);
  border-radius: 5px;
}

.sCw.sh > .bd > .sbx > ul {
  list-style: none;
}
.sCw.sh > .bd > .sbx > ul > li {
  padding: 20px;
  border: 1px solid var(--generic-border-color);
  border-radius: 5px;
  margin-bottom: 10px;
}
