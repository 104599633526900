.sdMdl.addStf > .ctnt {
  max-width: 800px;
  padding: 0 10px;
}
.sdMdl.addStf > .ctnt > .gPWrp {
  max-width: 100%;
}
.sdMdl.addStf > .ctnt > .gPWrp > .gPTle {
  padding-bottom: 0;
}
.sdMdl.addStf > .ctnt > .gPWrp > .gPTle.subTitle {
  padding-bottom: 20px;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc {
  position: relative;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.bc > .bcRow > .bxLt,
.bc > .bcRow > .bxRt {
  width: 48%;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow.contactRow > .bxLt {
  width: 80%;
  position: relative;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow.contactRow > .bxLt > .srchInPrg {
  position: absolute;
  right: 10px;
  top: 40px;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow.contactRow > .bxLt > .gErr {
  margin: 6px 0;
}

.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow.nameRow > .bxLt {
  width: 80%;
  position: relative;
}

.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow.buttonsRow {
  justify-content: flex-start;
  gap: 10px;
  margin-top: 60px;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow.curSts {
  font-weight: var(--font-weight-bold);
  margin-top: 20px;
  margin-bottom: 0;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow.curSts > .success {
  color: var(--form-success-color);
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .bcRow.curSts > .error {
  color: var(--form-error-color);
}


.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRCls {
  text-align: right;
  margin: 0 5px 13px 0;
  right: 1px;
  margin-top: -72px;
  position: absolute;
  z-index: 9999;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRCls > span {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px;
  cursor: default;
  background-color: #FFFFFF;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp {
  position: absolute;
  margin-top: -58px;
  max-height: 400px;
  overflow-y: scroll;
  width: calc(100%);
  background-color: #FFFFFF;
  z-index: 1;
  border: 1px solid #DDD;
  d1isplay: none;
  box-shadow: 0px 4px 10px #cec7c7, -4px 4px 10px #cec7c7, 4px 4px 10px #cec7c7;
  box-shadow: 0px 4px 10px 4px #CCC;
}
@media (max-width: 1366px) {
  .sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp {
    width: calc(100% - 40px);
  }
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp > .srchRInfo {
  padding: 10px;
  color: var(--primary-text-color-light);
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp > .rslRw {
  padding: 20px 10px;
  border-bottom: 1px solid #DDD;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp > .rslRw:hover {
  background-color: var(--generic-hover-bg-color);
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp > .rslRw > .rwIt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1px;
  cursor: default;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp > .rslRw > .rwIt.typRw > .rt > img {
  height: 20px;
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp > .rslRw > .rwIt.topRow > .lt {
  font-weight: var(--font-weight-bold);
}
.sdMdl.addStf > .ctnt > .gPWrp > .bc > .srchRWrp > .rslRw > .rwIt.stayInfo {
  font-size: var(--font-size-sub-mid-primary);
}
