.sentEmailPreviewModal > .content > .sdMdl > .ctnt > .gPWrp > .gBCtnt > .gBCtRw.btnRw > .gBtn.ok {
  border: 1px solid var(--form-error-color);
  background-color: var(--form-error-color);
}

.gPWrp.selTrEml > .gBCtnt > .trEntry {
  padding: 10px 10px 10px 0;
  cursor: default;
  display: flex;
  flex-direction: column;
}
.gPWrp.selTrEml > .gBCtnt > .trEntry.selected {
  background-color: var(--sidebar-main-menu-active-bg);
  border-radius: var(--generic-border-radius);
}
.gPWrp.selTrEml > .gBCtnt > .gBCtRw.btnRw > .gBtn.ok > span {
  margin-left: 5px;
}
.gPWrp.selTrEml > .gBCtnt > .gBCtRw.selErr {
  margin: 10px 0 0 0px;
}

.gPWrp.selTrEml > .gPTle {
  padding-bottom: 10px;
}
.gPWrp.selTrEml > .gPTle.subTitle {
  padding-bottom: 30px;
}
