.gPageWrap.broadcastRunDetail {
  max-width: 100%;
}
.gPageWrap.broadcastRunDetail > .gPageTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  1padding: 0 20px 38px 20px;
}
.gPageWrap.broadcastRunDetail > .gPageTitle > a.all {
  margin-right: 10px;
}
.gPageWrap.broadcastRunDetail > .gPageTitle > span > .fa {
  font-size: 18px;
}


.gPageWrap.broadcastRunDetail > .basicInfo {
}
.gPageWrap.broadcastRunDetail > .basicInfo > .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.gPageWrap.broadcastRunDetail > .basicInfo > .row > .left {
  width: 120px;
  font-weight: var(--font-weight-bold);
}
.gPageWrap.broadcastRunDetail > .basicInfo > .row > .right {
  flex: 1;
}

.gPageWrap.broadcastRunDetail > .recipientsInfo {
  margin-top: 20px;
}
.gPageWrap.broadcastRunDetail > .recipientsInfo > .title {
  font-weight: var(--font-weight-bold);
  margin-bottom: 10px;
}
.gPageWrap.broadcastRunDetail > .recipientsInfo > .row.header {
  background-color: #f6f6f6;
  box-shadow: none;
  padding: 10px 20px;
}

.gPageWrap.broadcastRunDetail > .recipientsInfo > .row {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: var(--generic-border-radius);
  margin-bottom: 20px;
  width: calc(100% - 40px);
  1margin: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: inset 0 0 2px #666;
  flex-wrap: wrap;
}
.gPageWrap.broadcastRunDetail > .recipientsInfo > .row > div {
  margin-right: 10px;
}
.gPageWrap.broadcastRunDetail > .recipientsInfo > .row > .name {
  flex: 1;
}
.gPageWrap.broadcastRunDetail > .recipientsInfo > .row > .contact {
  flex: 2;
}
.gPageWrap.broadcastRunDetail > .recipientsInfo > .row > .message {
  flex: 3;
}
.gPageWrap.broadcastRunDetail > .recipientsInfo > .row > .sentAt {
  flex: 2;
}
.gPageWrap.broadcastRunDetail > .recipientsInfo > .row > .status {
  flex: 1;
}
